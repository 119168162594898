/* eslint-disable react/prop-types */
import * as React from 'react';

import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Row,
  TreeSelect,
  Typography,
} from 'antd';

import {
  CheckInputbox,
  DeleteOutlinedIcon,
  DocumentViewer,
  DraggerUpload,
} from 'components';
import { useBrandAction, useBrandValue } from 'context';
import { getCategories } from 'services';
import type { BrandFileInfo as BrandFileInfoConfig } from 'types';
import {
  FILE,
  generateFileUrl,
  generateRequestUrl,
  generateTreeSelectData,
  inputRule,
  normalizeToString,
  validateMediaFile,
} from 'utils';

const {
  FORMAT: { PICTURES: PICTURES_FORMAT, VIDEOS: VIDEOS_FORMAT },
} = FILE;

type FieldType = Partial<Record<string, string>>;

type BrandFileInfoProps = BrandFileInfoConfig & {
  handleDeleteFile: () => void;
};

const BrandFileInfo = React.memo<BrandFileInfoProps>(
  ({ id, title, watermark, handleDeleteFile }) => {
    const { data: categoriesData, loading: categoriesLoading } = useRequest(
      getCategories,
      {
        cacheKey: 'categories',
      },
    );

    const totalCategories = categoriesData?.categories ?? [];
    const categoriesTreeData = generateTreeSelectData(
      totalCategories,
      'id',
      'title',
      'slug',
    );

    return (
      <List.Item className='my-5 rounded border'>
        <List.Item.Meta
          className='items-center'
          title={
            <Row>
              <Col span={24} lg={4} className='lg:pe-3'>
                <DocumentViewer
                  prefetchMethod='GET'
                  documents={[
                    {
                      uri: generateFileUrl('Brand', id, 210, 210),
                    },
                  ]}
                />
              </Col>

              <Col span={24} lg={20} className='lg:ps-3'>
                <Row>
                  <Col span={24} lg={12} className='lg:pe-3'>
                    <Form.Item<FieldType>
                      label='عنوان'
                      name={`file-title-${id}`}
                      rules={[inputRule.string]}
                      initialValue={title}
                    >
                      <Input placeholder='عنوان مستند را وارد کنید' />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} className='lg:ps-3'>
                    <Form.Item<FieldType>
                      label='دسته‌بندی'
                      name={`file-category-${id}`}
                    >
                      <TreeSelect
                        allowClear
                        showSearch
                        placeholder='دسته‌بندی مستند را انتخاب کنید'
                        loading={categoriesLoading}
                        treeData={categoriesTreeData}
                        filterTreeNode={(inputValue, treeNode) =>
                          normalizeToString(treeNode.label)
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} className='lg:pe-3'>
                    <Form.Item<FieldType>
                      label='نهان‌نگاری'
                      name={`file-watermark-${id}`}
                      valuePropName='checked'
                      initialValue={watermark}
                    >
                      <CheckInputbox>
                        مستند نیاز به نمایش نهان‌نگاری دارد
                      </CheckInputbox>
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} className='lg:ps-3'>
                    <Form.Item<FieldType> label=' '>
                      <Button
                        danger
                        type='primary'
                        className='w-full'
                        icon={<DeleteOutlinedIcon />}
                        onClick={handleDeleteFile}
                      >
                        حذف مستند
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        />
      </List.Item>
    );
  },
);
BrandFileInfo.displayName = 'BrandFileInfo';

const BrandFilesInfo = () => {
  const [picturesLoading, { set: setPicturesLoading }] = useBoolean(false);
  const [videosLoading, { set: setVideosLoading }] = useBoolean(false);
  const { pictures = [], videos = [] } = useBrandValue();
  const action = useBrandAction();

  const handleDeletePicture = useMemoizedFn((pictureId: string) => {
    const updatedPictures = pictures.filter(
      picture => picture.id !== pictureId,
    );

    action({
      type: 'UPDATE_BRAND_PICTURES',
      payload: {
        pictures: updatedPictures,
      },
    });
  });

  const handleDeleteVideo = useMemoizedFn((videoId: string) => {
    const updatedVideos = videos.filter(document => document.id !== videoId);

    action({
      type: 'UPDATE_BRAND_VIDEOS',
      payload: {
        videos: updatedVideos,
      },
    });
  });

  return (
    <Row>
      <Col span={24}>
        <Form.Item>
          <DraggerUpload
            withDocuments={false}
            action={generateRequestUrl('adminBrand/uploadBrandFile')}
            disabled={picturesLoading || videosLoading}
            onChange={({ file: { type, status, response } }) => {
              if (type) {
                const isPictureFile = validateMediaFile(type, PICTURES_FORMAT);
                const isVideoFile = validateMediaFile(type, VIDEOS_FORMAT);

                if (isPictureFile) {
                  setPicturesLoading(true);
                }
                if (isVideoFile) {
                  setVideosLoading(true);
                }

                if (status === 'done' && response) {
                  if (isPictureFile) {
                    action({
                      type: 'UPDATE_BRAND_PICTURES',
                      payload: {
                        pictures: [...pictures, response],
                      },
                    });
                  }
                  if (isVideoFile) {
                    action({
                      type: 'UPDATE_BRAND_VIDEOS',
                      payload: {
                        videos: [...videos, response],
                      },
                    });
                  }
                }

                if (status !== 'uploading') {
                  if (isPictureFile) {
                    setPicturesLoading(false);
                  }
                  if (isVideoFile) {
                    setVideosLoading(false);
                  }
                }
              }
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Typography.Title level={3} className='text-base'>
          تصویرهای برند
        </Typography.Title>

        <List
          size='large'
          className='[&_li]:border-solid [&_li]:border-[inherit]'
          loading={picturesLoading}
          dataSource={pictures}
          renderItem={item => (
            <BrandFileInfo
              handleDeleteFile={() => {
                handleDeletePicture(item.id);
              }}
              {...item}
            />
          )}
        />
      </Col>

      <Col span={24}>
        <Divider dashed className='mb-5 mt-2' />
      </Col>

      <Col span={24}>
        <Typography.Title level={3} className='text-base'>
          ویدیو‌های برند
        </Typography.Title>

        <List
          size='large'
          className='[&_li]:border-solid [&_li]:border-[inherit]'
          loading={videosLoading}
          dataSource={videos}
          renderItem={item => (
            <BrandFileInfo
              handleDeleteFile={() => {
                handleDeleteVideo(item.id);
              }}
              {...item}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export { BrandFileInfo, BrandFilesInfo };
