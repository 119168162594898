import * as React from 'react';

import { useDebounceFn, useRequest } from 'ahooks';
import { Col, Divider, Form, Input, Row, Select, TreeSelect } from 'antd';

import { CheckInputbox, RichTextEditor } from 'components';
import { useProductAction, useProductValue } from 'context';
import { getBrands, getCategories } from 'services';
import type { FileOwnerType, SelectOption, TreeSelectSource } from 'types';
import {
  findElementByKey,
  findTreeElementByKey,
  generateRequestUrl,
  generateSelectOptions,
  generateTreeSelectData,
  inputRule,
  normalizeToString,
} from 'utils';

type FieldType = Pick<
  ReturnType<typeof useProductValue>,
  | 'name'
  | 'identifier'
  | 'slug'
  | 'brand'
  | 'mainCategory'
  | 'otherCategories'
  | 'callForPrice'
  | 'showPostingStatus'
  | 'description'
>;

const ProductBaseInfo = () => {
  const { data: categoriesData, loading: categoriesLoading } = useRequest(
    getCategories,
    {
      cacheKey: 'categories',
    },
  );
  const { data: brandsData, loading: brandsLoading } = useRequest(getBrands, {
    cacheKey: 'brands',
  });
  const {
    name = '',
    identifier = '',
    slug = '',
    callForPrice = false,
    showPostingStatus = false,
    description = '',
  } = useProductValue();
  const action = useProductAction();

  const { run: debounceUpdateName } = useDebounceFn(
    (debouncedName: typeof name) => {
      action({
        type: 'UPDATE_PRODUCT_NAME',
        payload: {
          name: debouncedName,
        },
      });
    },
  );
  const { run: debounceUpdateIdentifier } = useDebounceFn(
    (debouncedIdentifier: typeof identifier) => {
      action({
        type: 'UPDATE_PRODUCT_IDENTIFIER',
        payload: {
          identifier: debouncedIdentifier,
        },
      });
    },
  );
  const { run: debounceUpdateSlug } = useDebounceFn(
    (debouncedSlug: typeof slug) => {
      action({
        type: 'UPDATE_PRODUCT_SLUG',
        payload: {
          slug: debouncedSlug,
        },
      });
    },
  );
  const { run: debounceUpdateDescription } = useDebounceFn(
    (debouncedDescription: typeof description) => {
      action({
        type: 'UPDATE_PRODUCT_DESCRIPTION',
        payload: {
          description: debouncedDescription,
        },
      });
    },
  );

  const uploadOwnerType: FileOwnerType = 'Product';
  const uploadAction = generateRequestUrl('adminProduct/uploadProductFile');

  const totalCategories = categoriesData?.categories ?? [];
  const totalBrands = brandsData?.brands ?? [];

  const categoriesTreeData = generateTreeSelectData(
    totalCategories,
    'id',
    'title',
    'id',
  );
  const categoriesSelectOptions = generateSelectOptions(
    totalCategories,
    'id',
    'title',
    'id',
  );
  const brandsSelectOptions = generateSelectOptions(
    totalBrands,
    'id',
    'title',
    'id',
  );

  return (
    <Row>
      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='نام'
          name='name'
          rules={[inputRule.required, inputRule.string]}
        >
          <Input
            placeholder='نام محصول را وارد کنید'
            onChange={({ target: { value } }) => {
              debounceUpdateName(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='شناسه'
          name='identifier'
          rules={[inputRule.nonDot]}
        >
          <Input
            placeholder='شناسه محصول را وارد کنید'
            onChange={({ target: { value } }) => {
              debounceUpdateIdentifier(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType>
          label='پیوند یکتا'
          name='slug'
          rules={[inputRule.required, inputRule.nonDot]}
        >
          <Input
            placeholder='پیوند یکتا محصول را وارد کنید'
            onChange={({ target: { value } }) => {
              debounceUpdateSlug(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='برند'
          name='brand'
          rules={[inputRule.required]}
        >
          <Select<SelectOption | undefined>
            allowClear
            labelInValue
            showSearch
            maxTagCount='responsive'
            placeholder='برند محصول را انتخاب کنید'
            loading={brandsLoading}
            options={brandsSelectOptions}
            filterOption={(inputValue, option) =>
              normalizeToString(option?.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={value => {
              const brandId = normalizeToString(value?.value);
              const selectedBrand = findElementByKey(
                totalBrands,
                'id',
                brandId,
              );

              action({
                type: 'UPDATE_PRODUCT_BRAND',
                payload: {
                  brand: selectedBrand,
                },
              });
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='دسته‌بندی اصلی'
          name='mainCategory'
          rules={[inputRule.required]}
        >
          <Select<SelectOption | undefined>
            allowClear
            labelInValue
            showSearch
            maxTagCount='responsive'
            placeholder='دسته‌بندی اصلی را انتخاب کنید'
            loading={categoriesLoading}
            options={categoriesSelectOptions}
            filterOption={(inputValue, option) =>
              normalizeToString(option?.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={value => {
              const mainCategoryId = normalizeToString(value?.value);
              const selectedMainCategory = findElementByKey(
                totalCategories,
                'id',
                mainCategoryId,
              );

              action({
                type: 'UPDATE_PRODUCT_MAIN_CATEGORY',
                payload: {
                  mainCategory: selectedMainCategory,
                },
              });
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType> label='دسته‌بندی‌های فرعی' name='otherCategories'>
          <TreeSelect<TreeSelectSource[]>
            multiple
            treeCheckable
            treeCheckStrictly
            placeholder='دسته‌بندی‌های فرعی را انتخاب کنید'
            loading={categoriesLoading}
            treeData={categoriesTreeData}
            filterTreeNode={(inputValue, treeNode) =>
              normalizeToString(treeNode.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={value => {
              const selectedOtherCategories = value.map(({ value }) => {
                const otherCategoryId = normalizeToString(value);
                const selectedOtherCategory = findTreeElementByKey(
                  totalCategories,
                  'id',
                  otherCategoryId,
                )!;

                return selectedOtherCategory;
              });

              action({
                type: 'UPDATE_PRODUCT_OTHER_CATEGORIES',
                payload: {
                  otherCategories: selectedOtherCategories,
                },
              });
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='استعلام بها'
          name='callForPrice'
          valuePropName='checked'
          initialValue={callForPrice}
        >
          <CheckInputbox>نیاز به استعلام بها دارد</CheckInputbox>
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='وضعیت ارسال'
          name='showPostingStatus'
          valuePropName='checked'
          initialValue={showPostingStatus}
        >
          <CheckInputbox>نیاز به نمایش وضعیت ارسال دارد</CheckInputbox>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Divider dashed className='mb-5 mt-2' />
      </Col>

      <Col span={24}>
        <Form.Item<FieldType> label='توضیحات' name='description'>
          <RichTextEditor
            options={{
              content: description,
              onUpdate: ({ editor }) => {
                debounceUpdateDescription(editor.getHTML());
              },
            }}
            config={{
              placeholder: {
                placeholder: 'توضیحات را وارد کنید',
              },
            }}
            upload={{
              ownerType: uploadOwnerType,
              action: uploadAction,
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { ProductBaseInfo };
