import * as React from 'react';

import {
  Col,
  Collapse,
  Divider,
  Empty,
  Flex,
  Image,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';

import {
  AntdRouterExternalLink,
  CheckCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
} from 'components';
import { usePurchaseValue } from 'context';
import {
  deepFlatFilter,
  generateFileUrl,
  numberFormat,
  PATHNAME,
  randomId,
  UNIT,
} from 'utils';

const { PRICE } = UNIT;
const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    PRODUCTS: { BASE_URL: PRODUCTS_BASE_URL, UPDATE_URL: PRODUCTS_UPDATE_URL },
    DISCOUNT_CODES: {
      BASE_URL: DISCOUNT_CODES_BASE_URL,
      UPDATE_URL: DISCOUNT_CODES_UPDATE_URL,
    },
    USERS: { BASE_URL: USERS_BASE_URL, UPDATE_URL: USERS_UPDATE_URL },
  },
} = PATHNAME;

const PurchaseFormContent = () => {
  const {
    user,
    recipientAddressInfo,
    discountCode,
    paperInvoice,
    description,
    purchaseProducts,
    totalPrice,
  } = usePurchaseValue();

  const generateUserCollapse = () => {
    if (user && recipientAddressInfo) {
      const { id, userName, displayName, firstName, lastName = '' } = user;
      const {
        firstName: recipientFirstName,
        lastName: recipientLastName,
        phoneNumber: recipientPhoneNumber,
        telNumber: recipientTelNumber,
        title: recipientAddressTitle,
        city: {
          name: recipientAddressCity,
          province: { name: recipientAddressProvince },
        },
        address: {
          postalCode: recipientAddressPostalCode,
          content: recipientAddressContent,
          no: recipientAddressNO,
          unit: recipientAddressUnit,
        },
      } = recipientAddressInfo;

      return (
        <Collapse
          size='large'
          expandIconPosition='end'
          className='my-2.5 [&_.ant-collapse-header]:py-5'
          defaultActiveKey={[id]}
          items={[
            {
              key: id,
              label: <Typography.Text ellipsis>اطلاعات کابر</Typography.Text>,
              children: (
                <Row gutter={[16, 20]}>
                  <Col>
                    <Typography.Text ellipsis>
                      <span className='text-gray-400'>شناسه کاربر: </span>
                      <AntdRouterExternalLink
                        href={`${USERS_BASE_URL}${USERS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                      >
                        {userName ??
                          displayName ??
                          (firstName ? firstName.concat(` ${lastName}`) : id)}
                      </AntdRouterExternalLink>
                    </Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Divider dashed className='my-1' />
                  </Col>

                  <Col span={24} lg={12}>
                    <Typography.Text>
                      <span className='text-gray-400'>نام گیرنده: </span>
                      <span>
                        {recipientFirstName
                          ? recipientFirstName.concat(` ${recipientLastName}`)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col span={24} lg={12}>
                    <Typography.Text>
                      <span className='text-gray-400'>شماره‌های گیرنده: </span>

                      {recipientPhoneNumber ? (
                        <AntdRouterExternalLink
                          href={`tel:${recipientPhoneNumber}`}
                        >
                          <Tag bordered={false} color='blue'>
                            {recipientPhoneNumber}
                          </Tag>
                        </AntdRouterExternalLink>
                      ) : null}
                      {recipientTelNumber ? (
                        <AntdRouterExternalLink
                          href={`tel:${recipientTelNumber}`}
                        >
                          <Tag bordered={false} color='blue'>
                            {recipientTelNumber}
                          </Tag>
                        </AntdRouterExternalLink>
                      ) : null}

                      {deepFlatFilter([
                        recipientPhoneNumber,
                        recipientTelNumber,
                      ]).length === 0 ? (
                        <span>-</span>
                      ) : null}
                    </Typography.Text>
                  </Col>
                  <Col span={24} lg={12}>
                    <Typography.Text>
                      <span className='text-gray-400'>کد پستی: </span>
                      <span>{recipientAddressPostalCode ?? '-'}</span>
                    </Typography.Text>
                  </Col>
                  <Col span={24} lg={12}>
                    <Typography.Text>
                      <span className='text-gray-400'>آدرس: </span>

                      {recipientAddressTitle ? (
                        <Tag bordered={false} color='blue' className='me-0'>
                          {recipientAddressTitle}
                        </Tag>
                      ) : null}
                      <span>{` استان ${recipientAddressProvince ?? '-'}`}</span>
                      <span>{` شهر ${recipientAddressCity ?? '-'}`}</span>
                      <span>{` ${recipientAddressContent}`}</span>
                      <span>{` پلاک ${recipientAddressNO ?? '-'}`}</span>
                      <span>{` واحد ${recipientAddressUnit ?? '-'}`}</span>
                    </Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Divider dashed className='my-1' />
                  </Col>

                  <Col span={24} lg={12}>
                    <Typography.Text>
                      <span className='text-gray-400'>کد تخفیف: </span>
                      {discountCode ? (
                        <AntdRouterExternalLink
                          href={`${DISCOUNT_CODES_BASE_URL}${DISCOUNT_CODES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                        >
                          {discountCode.code}
                        </AntdRouterExternalLink>
                      ) : (
                        <span>-</span>
                      )}
                    </Typography.Text>
                  </Col>
                  <Col span={24} lg={12}>
                    <Typography.Text>
                      <span className='text-gray-400'>فاکتور کاغذی: </span>
                      {paperInvoice ? (
                        <CheckCircleOutlinedIcon className='text-green-500' />
                      ) : (
                        <CloseCircleOutlinedIcon className='text-red-500' />
                      )}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>
                      <span className='text-gray-400'>یادداشت: </span>
                      <span>{description ?? '-'}</span>
                    </Typography.Text>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      );
    }
  };

  const generateProductsCollapse = () => {
    if (purchaseProducts && totalPrice) {
      const id = randomId();

      return (
        <Collapse
          size='large'
          expandIconPosition='end'
          className='my-2.5 [&_.ant-collapse-header]:py-5'
          defaultActiveKey={[id]}
          items={[
            {
              key: id,
              label: (
                <Typography.Text ellipsis>اطلاعات محصول‌ها</Typography.Text>
              ),
              children:
                purchaseProducts.length > 0 ? (
                  <Space direction='vertical' size='middle' className='flex'>
                    {purchaseProducts.map(
                      (
                        {
                          product: {
                            id: productId,
                            name: productName,
                            bannerId: productBannerId,
                          },
                          subProduct: { identifier: subProductIdentifier },
                          size,
                          price,
                        },
                        index,
                      ) => (
                        <Row key={productId}>
                          {index > 0 ? (
                            <Col span={24}>
                              <Divider dashed className='mb-5 mt-0' />
                            </Col>
                          ) : null}

                          <Col span={24} lg={4} className='lg:pe-3'>
                            <Image
                              src={generateFileUrl(
                                'Product',
                                productBannerId,
                                600,
                                600,
                              )}
                            />
                          </Col>

                          <Col span={24} lg={20} className='lg:ps-3'>
                            <Row gutter={[10, 16.5]}>
                              <Col span={24}>
                                <Typography.Text ellipsis>
                                  <span className='text-gray-400'>
                                    نام محصول:{' '}
                                  </span>
                                  <AntdRouterExternalLink
                                    href={`${PRODUCTS_BASE_URL}${PRODUCTS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${productId}${DEFAULT_SLUG_SEPARATOR}`}
                                  >
                                    {productName}
                                  </AntdRouterExternalLink>
                                </Typography.Text>
                              </Col>

                              <Col span={24}>
                                <Typography.Text ellipsis>
                                  <span className='text-gray-400'>
                                    شناسه مدل:{' '}
                                  </span>
                                  <span>{subProductIdentifier}</span>
                                </Typography.Text>
                              </Col>

                              <Col span={24}>
                                <Typography.Text ellipsis>
                                  <span className='text-gray-400'>
                                    تعداد سفارش:{' '}
                                  </span>
                                  <span>{numberFormat(size)}</span>
                                </Typography.Text>
                              </Col>

                              <Col span={24}>
                                <Typography.Text ellipsis>
                                  <span className='text-gray-400'>
                                    قیمت نهایی:{' '}
                                  </span>
                                  <span>
                                    {numberFormat(
                                      price,
                                      undefined,
                                      ` ${PRICE}`,
                                    )}
                                  </span>
                                </Typography.Text>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ),
                    )}

                    <Divider dashed className='mb-0.5 mt-1' />

                    <Flex justify='space-between' align='center' gap='middle'>
                      <Typography.Text ellipsis className='text-gray-400'>
                        قیمت کل:
                      </Typography.Text>
                      <Typography.Text ellipsis>
                        {numberFormat(totalPrice, undefined, ` ${PRICE}`)}
                      </Typography.Text>
                    </Flex>
                  </Space>
                ) : (
                  <Empty
                    className='my-4'
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
            },
          ]}
        />
      );
    }
  };

  return (
    <Row className='rounded bg-white px-6 py-4 lg:basis-[70%]'>
      <Col span={24}>{generateUserCollapse()}</Col>
      <Col span={24}>{generateProductsCollapse()}</Col>
    </Row>
  );
};

export { PurchaseFormContent };
