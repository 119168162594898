import * as React from 'react';

import { Avatar, Badge, Button, Dropdown, Flex, Space } from 'antd';

import {
  BellOutlinedIcon,
  MenuFoldOutlinedIcon,
  MenuUnfoldOutlinedIcon,
  UserOutlinedIcon,
} from 'components';
import { COUNT } from 'utils';

const {
  VERSION: { TAG: VERSION_TAG },
} = COUNT;

type PrivateHeaderProps = {
  collapsed: boolean;
  handleCollapse: () => void;
};

const PrivateHeader = ({ collapsed, handleCollapse }: PrivateHeaderProps) => (
  <Flex justify='space-between' align='center'>
    <Space size={0} align='center'>
      <div>
        <Button
          type='text'
          size='large'
          icon={
            collapsed ? <MenuUnfoldOutlinedIcon /> : <MenuFoldOutlinedIcon />
          }
          onClick={handleCollapse}
        />
        <span className='sr-only'>آیکون منو</span>
      </div>

      <Badge
        count={`نسخه ${VERSION_TAG}`}
        color='var(--ant-color-primary)'
        className='mb-1'
      />
    </Space>

    <Space size='large'>
      <Dropdown
        menu={{
          items: [],
        }}
        trigger={['click']}
      >
        <Button type='text' style={{ paddingInline: 0 }}>
          <Badge dot status='processing'>
            <BellOutlinedIcon style={{ fontSize: 20 }} />
          </Badge>
          <span className='sr-only'>آیکون پیام</span>
        </Button>
      </Dropdown>

      <Dropdown
        arrow
        menu={{
          items: [],
        }}
        trigger={['click']}
      >
        <Button type='text' style={{ paddingInline: 0 }}>
          <Avatar
            icon={<UserOutlinedIcon style={{ fontSize: 20 }} />}
            style={{
              backgroundColor: 'transparent',
              color: 'var(--ant-color-text)',
            }}
          />
          <span className='sr-only'>آیکون کاربر</span>
        </Button>
      </Dropdown>
    </Space>
  </Flex>
);

export { PrivateHeader };
