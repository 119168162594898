import * as React from 'react';

import useUrlState from '@ahooksjs/use-url-state';

import { PATHNAME } from 'utils';

import { LoginDefaultForm } from './default';
import { LoginOTPForm } from './otp';

const {
  QUERY_PARAM: {
    DEFAULT: { LOGIN_TYPE: DEFAULT_LOGIN_TYPE },
    TYPE: { BASE_URL: TYPE_BASE_URL },
  },
} = PATHNAME;

const LoginForm = () => {
  const [urlState] = useUrlState({
    [TYPE_BASE_URL]: '',
  });

  if (urlState[TYPE_BASE_URL] === DEFAULT_LOGIN_TYPE) {
    return <LoginOTPForm />;
  }

  return <LoginDefaultForm />;
};

export { LoginForm };
