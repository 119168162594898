import { Fetch } from 'lib';
import type { ProductComment } from 'types';

type RequestResponses = ProductComment;

type RequestParameters = {
  id: string;
};

type RequestBody = Pick<ProductComment, 'content' | 'status'>;

const updateProductComment = async (
  params: RequestParameters,
  body: RequestBody,
) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminProductComment/updateProductComment',
    method: 'PUT',
    params,
    body,
  });

export { updateProductComment };
