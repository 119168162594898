import type { ComponentMode, Purchase, PurchaseStatus } from 'types';

type State = Partial<
  Purchase &
    Record<'mode', ComponentMode> &
    Record<'defaultStatus', PurchaseStatus>
>;
const initialState: State = {};

type Action = undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
