import { Fetch } from 'lib';
import { createUser } from 'services';

type RequestResponses = Awaited<ReturnType<typeof createUser>>;

type RequestParameters = {
  id: string;
};

type RequestBody = Parameters<typeof createUser>[number];

const updateUser = async (params: RequestParameters, body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminUser/updateUser',
    method: 'PUT',
    params,
    body,
  });

export { updateUser };
