import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { AttributeFormContext } from 'context';
import type { AttributeConfig, ComponentMode } from 'types';

import { AttributeForm, AttributeHeader } from './components';

type AttributeProps = {
  mode: ComponentMode;
};

export default function Attribute({ mode }: AttributeProps) {
  const data = useLoaderData() as AttributeConfig;

  const updateMode = mode === 'Update';
  const title = updateMode ? data.name : 'افزودن فیلد جدید';
  const defaultName = updateMode ? data.name : '';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <AttributeHeader>{title}</AttributeHeader>

      <AttributeFormContext
        mode={mode}
        defaultName={defaultName}
        {...(updateMode && data)}
      >
        <AttributeForm />
      </AttributeFormContext>
    </>
  );
}
