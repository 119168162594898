import * as React from 'react';

import { useDebounceFn, useRequest } from 'ahooks';
import {
  Col,
  Empty,
  Flex,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
  Typography,
} from 'antd';

import { ImgCropUpload, RichTextEditor, UploadOutlinedIcon } from 'components';
import { useCategoryAction, useCategoryValue } from 'context';
import { getCategories, getCategory } from 'services';
import type { SelectOption, TreeSelectSource } from 'types';
import type { FileOwnerType } from 'types';
import {
  findElementByKey,
  findTreeElementByKey,
  generateFileUrl,
  generateRequestUrl,
  generateSelectOptions,
  generateTreeSelectData,
  inputRule,
  normalizeToString,
} from 'utils';

type FieldType = Pick<
  ReturnType<typeof useCategoryValue>,
  'title' | 'iconId' | 'parent' | 'relatedAttribute' | 'subSlug' | 'description'
> &
  Record<'relatedAttributeValue', string>;

const CategoryBaseInfo = () => {
  const { data: categoriesData, loading: categoriesLoading } = useRequest(
    getCategories,
    {
      cacheKey: 'categories',
    },
  );
  const {
    data: parentData,
    loading: parentLoading,
    run,
  } = useRequest(getCategory, {
    manual: true,
  });
  const {
    title = '',
    parent,
    relatedAttribute,
    subSlug = '',
    description,
    iconId,
    iconStatus,
  } = useCategoryValue();
  const action = useCategoryAction();

  React.useEffect(() => {
    if (parent) {
      run({ id: parent.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent]);

  const { run: debounceUpdateTitle } = useDebounceFn(
    (debouncedTitle: typeof title) => {
      action({
        type: 'UPDATE_CATEGORY_TITLE',
        payload: {
          title: debouncedTitle,
        },
      });
    },
  );
  const { run: debounceUpdateSubSlug } = useDebounceFn(
    (debouncedSubSlug: typeof subSlug) => {
      action({
        type: 'UPDATE_CATEGORY_SUB_SLUG',
        payload: {
          subSlug: debouncedSubSlug,
        },
      });
    },
  );
  const { run: debounceUpdateDescription } = useDebounceFn(
    (debouncedDescription: typeof description) => {
      action({
        type: 'UPDATE_CATEGORY_DESCRIPTION',
        payload: {
          description: debouncedDescription,
        },
      });
    },
  );

  const uploadOwnerType: FileOwnerType = 'Category';
  const uploadAction = generateRequestUrl('adminCategory/uploadCategoryFile');

  const totalCategories = categoriesData?.categories ?? [];
  const selectedAttributes = parentData?.attributes ?? [];
  const relatedParentAttribute = parentData?.relatedAttribute;

  const categoriesTreeData = generateTreeSelectData(
    totalCategories,
    'id',
    'title',
    'id',
  );
  const attributesOptions = generateSelectOptions(
    selectedAttributes,
    'id',
    'name',
    'id',
  );

  return (
    <Row>
      <Col span={24} lg={6} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='نشان‌واره'
          name='iconId'
          valuePropName='file'
        >
          <ImgCropUpload
            action={uploadAction}
            fileList={
              iconId
                ? [
                    {
                      uid: iconId,
                      name: title,
                      status: 'done',
                      url: generateFileUrl(uploadOwnerType, iconId, 200, 200),
                    },
                  ]
                : undefined
            }
            onChange={({ file: { status, response } }) => {
              action({
                type: 'UPDATE_CATEGORY_ICON',
                payload: {
                  iconId: undefined,
                  iconStatus: true,
                },
              });

              if (status === 'done' && response) {
                const iconId = response.id;
                action({
                  type: 'UPDATE_CATEGORY_ICON',
                  payload: {
                    iconId,
                    iconStatus: true,
                  },
                });
              }

              if (status === 'error' || status === 'removed') {
                action({
                  type: 'UPDATE_CATEGORY_ICON',
                  payload: {
                    iconId: undefined,
                    iconStatus: false,
                  },
                });
              }
            }}
          >
            {!iconStatus ? (
              <Flex vertical justify='center' align='center' gap='small'>
                <UploadOutlinedIcon className='text-xl text-[var(--ant-color-link-hover)]' />
                <Typography.Paragraph>
                  نشان‌واره دسته‌بندی را بار‌گذاری کنید
                </Typography.Paragraph>
              </Flex>
            ) : null}
          </ImgCropUpload>
        </Form.Item>
      </Col>

      <Col span={24} lg={18} className='lg:ps-3'>
        <Row>
          <Col span={24} lg={12} className='lg:pe-3'>
            <Form.Item<FieldType>
              label='عنوان'
              name='title'
              rules={[inputRule.required, inputRule.string]}
            >
              <Input
                placeholder='عنوان دسته‌بندی را وارد کنید'
                onChange={({ target: { value } }) => {
                  debounceUpdateTitle(value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} className='lg:ps-3'>
            <Form.Item<FieldType> label='دسته‌بندی مادر' name='parent'>
              <TreeSelect<TreeSelectSource | undefined>
                allowClear
                showSearch
                labelInValue
                placeholder='دسته‌بندی مادر را انتخاب کنید'
                loading={categoriesLoading}
                treeData={categoriesTreeData}
                filterTreeNode={(inputValue, treeNode) =>
                  normalizeToString(treeNode.label)
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                onChange={value => {
                  const parentId = normalizeToString(value?.value);
                  const selectedParent = findTreeElementByKey(
                    totalCategories,
                    'id',
                    parentId,
                  );

                  action({
                    type: 'UPDATE_CATEGORY_PARENT',
                    payload: {
                      parent: selectedParent,
                    },
                  });
                  action({
                    type: 'UPDATE_CATEGORY_RELATED_ATTRIBUTE',
                    payload: {
                      relatedAttribute: selectedParent?.relatedAttribute,
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} className='lg:pe-3'>
            <Form.Item<FieldType> label='فیلد مورد نظر' name='relatedAttribute'>
              <Select<SelectOption | undefined>
                allowClear
                showSearch
                labelInValue
                placeholder='فیلد مورد نظر را انتخاب کنید'
                disabled={parentLoading || !!relatedParentAttribute}
                loading={parentLoading}
                options={
                  parent
                    ? relatedParentAttribute
                      ? [
                          {
                            label: relatedParentAttribute.name,
                            value: relatedParentAttribute.id,
                          },
                        ]
                      : attributesOptions
                    : undefined
                }
                filterOption={(inputValue, option) =>
                  normalizeToString(option?.label)
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                onChange={value => {
                  const attributeId = normalizeToString(value?.value);
                  const selectedAttribute = findElementByKey(
                    selectedAttributes,
                    'id',
                    attributeId,
                  );

                  action({
                    type: 'UPDATE_CATEGORY_RELATED_ATTRIBUTE',
                    payload: {
                      relatedAttribute: selectedAttribute && {
                        ...selectedAttribute,
                        selectedValues: [],
                      },
                    },
                  });
                }}
                {...(!parent && {
                  notFoundContent: (
                    <Empty
                      className='my-3'
                      description='ابتدا دسته‌بندی مادر را انتخاب کنید.'
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{
                        height: 'calc(var(--ant-control-height-lg) * 0.875)',
                      }}
                    />
                  ),
                })}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} className='lg:ps-3'>
            <Form.Item<FieldType>
              label='مقدار فیلد مورد نظر'
              name='relatedAttributeValue'
            >
              <Select<string | undefined>
                allowClear
                showSearch
                placeholder='مقدار فیلد مورد نظر را انتخاب کنید'
                disabled={parentLoading || !relatedParentAttribute}
                loading={parentLoading}
                options={
                  parent && relatedParentAttribute
                    ? relatedParentAttribute.values.map(value => ({
                        label: value,
                        value,
                      }))
                    : undefined
                }
                onChange={value => {
                  action({
                    type: 'UPDATE_CATEGORY_RELATED_ATTRIBUTE',
                    payload: {
                      relatedAttribute: relatedAttribute && {
                        ...relatedAttribute,
                        selectedValues: value ? [value] : [],
                      },
                    },
                  });
                }}
                {...(!relatedAttribute && {
                  notFoundContent: (
                    <Empty
                      className='my-3'
                      description='ابتدا فیلد مورد نظر را انتخاب کنید.'
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{
                        height: 'calc(var(--ant-control-height-lg) * 0.875)',
                      }}
                    />
                  ),
                })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType>
          label='پیوند یکتا'
          name='subSlug'
          rules={[inputRule.required, inputRule.nonDot]}
        >
          <Input
            placeholder='پیوند یکتا دسته‌بندی را وارد کنید'
            addonAfter={parent && `/${parent.slug}`}
            classNames={{
              input: '[&:not([value=""])]:[direction:ltr]',
            }}
            onChange={({ target: { value } }) => {
              debounceUpdateSubSlug(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType> label='توضیحات' name='description'>
          <RichTextEditor
            options={{
              content: description,
              onUpdate: ({ editor }) => {
                debounceUpdateDescription(editor.getHTML());
              },
            }}
            config={{
              placeholder: {
                placeholder: 'توضیحات را وارد کنید',
              },
            }}
            upload={{
              ownerType: uploadOwnerType,
              action: uploadAction,
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { CategoryBaseInfo };
