import type { ComponentMode, User, UserRoleName } from 'types';

type State = Partial<
  User &
    Record<'mode', ComponentMode> &
    Record<'avatarStatus', boolean> &
    Record<'defaultName', string> &
    Record<'defaultRoleName', UserRoleName>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_USER_AVATAR';
      payload: Pick<typeof initialState, 'avatarId' | 'avatarStatus'>;
    }
  | {
      type: 'UPDATE_USER_FIRST_NAME';
      payload: Pick<typeof initialState, 'firstName'>;
    }
  | {
      type: 'UPDATE_USER_DISPLAY_NAME';
      payload: Pick<typeof initialState, 'displayName'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
