import * as React from 'react';

import { useUpdateEffect } from 'ahooks';
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Flex,
  Modal,
  notification,
  Space,
  Table,
  Tooltip,
} from 'antd';

import {
  AntdRouterLink,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MoreOutlinedIcon,
  UserOutlinedIcon,
} from 'components';
import { deleteUser } from 'services';
import type { UserSummary } from 'types';
import {
  adjustPageNumber,
  generateFileUrl,
  generateTableDataSource,
  localizeUserRoleName,
  PATHNAME,
} from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    USERS: { UPDATE_URL: USERS_UPDATE_URL },
  },
  QUERY_PARAM: {
    DEFAULT: { AT_SIGN: DEFAULT_AT_SIGN },
  },
} = PATHNAME;

type UsersTableProps = {
  loading: boolean;
  total: number;
  users: UserSummary[];
  pageSize: number;
  pageNumber: number;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const UsersTable = React.memo<UsersTableProps>(
  ({
    loading,
    total,
    users,
    pageSize,
    pageNumber,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: UsersTableProps) => {
    const [modal, contextHolder] = Modal.useModal();

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteUser = async (id: string) => {
      deleteUser({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const usersDataSource = generateTableDataSource(users, 'id');

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: 700, y: 630 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            render: (_, { id, firstName, lastName = '', avatarId = '' }) => {
              const title = firstName ? firstName.concat(` ${lastName}`) : id;

              return (
                <Space>
                  <Avatar
                    shape='square'
                    src={generateFileUrl('User', avatarId, 600, 600)}
                    icon={<UserOutlinedIcon className='text-xl' />}
                  />

                  <Tooltip title={title}>
                    <AntdRouterLink
                      ellipsis
                      to={`${USERS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                      className='max-w-2xl'
                    >
                      {title}
                    </AntdRouterLink>
                  </Tooltip>
                </Space>
              );
            },
          },
          {
            title: 'نام کاربری',
            dataIndex: 'userName',
            key: 'userName',
            ellipsis: true,
            render: (_, { userName }) =>
              userName ? <span>{`${userName}${DEFAULT_AT_SIGN}`}</span> : null,
          },
          {
            title: 'نوع دسترسی',
            dataIndex: 'roleName',
            key: 'roleName',
            width: 110,
            ellipsis: true,
            render: (_, { roleName }) => (
              <Flex justify='center'>
                <Badge
                  count={localizeUserRoleName(roleName).label}
                  color={localizeUserRoleName(roleName).color}
                />
              </Flex>
            ),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, firstName, lastName }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-user',
                        label: (
                          <AntdRouterLink
                            to={`${USERS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>ویرایش کاربر</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-user',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='my-0.5 flex items-center px-0'
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف کاربر "${
                                    firstName || lastName
                                      ? `${firstName} ${lastName}`
                                      : id
                                  }" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteUser(id);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span className='text-sm'>حذف کاربر</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='pb-[18px] pt-3.5'>
                    <MoreOutlinedIcon className='text-xl' />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={usersDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
UsersTable.displayName = 'UsersTable';

export { UsersTable };
