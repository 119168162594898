import { Fetch } from 'lib';
import type { Purchase } from 'types';

type RequestResponses = Purchase;

type RequestParameters = {
  id: string;
};

type RequestBody = Pick<Purchase, 'status'>;

const updatePurchase = async (params: RequestParameters, body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminPurchase/updatePurchase',
    method: 'PUT',
    params,
    body,
  });

export { updatePurchase };
