import { Fetch } from 'lib';
import type {
  ProductComment,
  ProductCommentSortType,
  ProductCommentStatus,
} from 'types';

type RequestResponses = {
  total: number;
  data: ProductComment[];
};

type RequestParameters = {
  sortType: ProductCommentSortType;
  searchKey: string;
  statuses?: ProductCommentStatus[];
  lowerBoundCreateDate?: string;
  upperBoundCreateDate?: string;
  pageSize: number;
  pageNumber: number;
};

const searchProductsComments = async ({
  sortType,
  searchKey,
  statuses,
  lowerBoundCreateDate,
  upperBoundCreateDate,
  pageSize,
  pageNumber,
}: RequestParameters) => {
  const params: Record<string, unknown> = {
    sortType,
    searchKey,
    pageSize,
    pageNumber,
  };

  if (statuses) {
    params['statuses'] = statuses;
  }
  if (lowerBoundCreateDate) {
    params['lowerBoundCreateDate'] = lowerBoundCreateDate;
  }
  if (upperBoundCreateDate) {
    params['upperBoundCreateDate'] = upperBoundCreateDate;
  }

  return await Fetch<RequestResponses, typeof params>({
    url: 'adminProductComment/searchProductsComments',
    params,
  });
};

export { searchProductsComments };
