import * as React from 'react';

import {
  Badge,
  Button,
  Divider,
  Form,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlinedIcon } from 'components';
import { useUserValue } from 'context';
import { deleteUser } from 'services';
import type { UserRoleName } from 'types';
import { localizeUserRoleName, PATHNAME } from 'utils';

const {
  LOCAL: {
    USERS: { BASE_URL: USERS_BASE_URL },
  },
} = PATHNAME;

const userRoleNames = ['Admin', 'Editor', 'User'] satisfies UserRoleName[];

type FieldType = Pick<ReturnType<typeof useUserValue>, 'roleName'>;

const UserFormAction = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { id = '', defaultName, defaultRoleName, mode } = useUserValue();
  const navigate = useNavigate();

  const handleDeleteUser = async (id: string) => {
    deleteUser({ id })
      .then(() => {
        navigate(USERS_BASE_URL);
      })
      .catch(message => {
        notification.error({ message });
      });
  };

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='mb-0 text-base font-medium'>
          دسترسی
        </Typography.Title>
      </div>

      <Divider className='mb-8 mt-4' />

      <div className='px-6'>
        <Typography.Paragraph>
          <Badge
            text={`دسترسی فعلی: ${localizeUserRoleName(defaultRoleName).label}`}
            color={localizeUserRoleName(defaultRoleName).color}
          />
        </Typography.Paragraph>

        <Form.Item<FieldType>
          label='دسترسی جدید'
          name='roleName'
          className='mb-2'
        >
          <Select
            placeholder='نوع دسترسی را انتخاب کنید'
            options={userRoleNames.map(type => ({
              label: localizeUserRoleName(type).label,
              value: type,
            }))}
          />
        </Form.Item>
      </div>

      <div className='flex items-center gap-2 p-6 *:m-0 *:basis-full'>
        {mode === 'Update' ? (
          <Form.Item>
            <Button
              block
              danger
              type='primary'
              className='flex items-center'
              onClick={async () => {
                const confirmed = await modal.confirm({
                  title: `آیا از حذف کاربر "${defaultName}" اطمینان دارید؟`,
                  width: 500,
                });

                if (confirmed) {
                  handleDeleteUser(id);
                }
              }}
            >
              <DeleteOutlinedIcon />
              <span className='text-sm'>حذف کاربر</span>
            </Button>

            {contextHolder}
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {mode === 'Create' ? 'افزودن کاربر جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export { UserFormAction };
