import { PATHNAME } from 'utils';

const {
  DEFAULT: { LOCALE: DEFAULT_LOCALE },
} = PATHNAME;

const numberFormat = (
  value: number,
  options: Intl.NumberFormatOptions = {
    style: 'decimal',
  },
  addonAfter = '',
  locales: string | string[] = DEFAULT_LOCALE,
) => new Intl.NumberFormat(locales, options).format(value).concat(addonAfter);

const dateTimeFormat = (
  date: Date | number,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  addonAfter = '',
  locales: string | string[] = DEFAULT_LOCALE,
) => new Intl.DateTimeFormat(locales, options).format(date).concat(addonAfter);

const discountPercentage = (
  originalPrice: number,
  price: number,
  addonAfter = '',
  locales: string | string[] = DEFAULT_LOCALE,
) =>
  numberFormat(
    (1 / originalPrice) * (originalPrice - price),
    { style: 'percent' },
    addonAfter,
    locales,
  );

const localeSortByKey = <T extends Record<K, string>, K extends keyof T>(
  data: T[],
  key: K,
  options?: Intl.CollatorOptions,
  locales: string | string[] = DEFAULT_LOCALE,
): T[] =>
  [...data].sort((a, b) =>
    new Intl.Collator(locales, options).compare(a[key], b[key]),
  );

export { dateTimeFormat, discountPercentage, localeSortByKey, numberFormat };
