/* eslint-disable react/prop-types */
import * as React from 'react';

import { Flex, Typography, Upload } from 'antd';

import { FileImageOutlinedIcon } from 'components';
import { cn, FILE, PATHNAME, validateMediaFile } from 'utils';

const { Dragger } = Upload;
const {
  QUERY_PARAM: {
    DEFAULT: { COMMA: DEFAULT_COMMA },
  },
} = PATHNAME;
const {
  FORMAT: {
    IMAGES: IMAGES_FORMAT,
    MOVIES: MOVIES_FORMAT,
    DOCUMENTS: DOCUMENTS_FORMAT,
  },
} = FILE;

type DraggerUploadProps = React.ComponentPropsWithoutRef<typeof Dragger> &
  Partial<Record<'withImages' | 'withMovies' | 'withDocuments', boolean>>;

const DraggerUpload = React.forwardRef<
  React.ElementRef<typeof Dragger>,
  DraggerUploadProps
>(
  (
    {
      withImages = true,
      withMovies = true,
      withDocuments = true,
      multiple = true,
      showUploadList = false,
      className,
      beforeUpload,
      ...props
    },
    ref,
  ) => (
    <Dragger
      ref={ref}
      multiple={multiple}
      showUploadList={showUploadList}
      className={cn('first:[&>div]:border-solid', className)}
      beforeUpload={
        beforeUpload ??
        (({ type }) =>
          (withImages && validateMediaFile(type, IMAGES_FORMAT)) ||
          (withMovies && validateMediaFile(type, MOVIES_FORMAT)) ||
          (withDocuments && validateMediaFile(type, DOCUMENTS_FORMAT)) ||
          Upload.LIST_IGNORE)
      }
      {...props}
    >
      <FileImageOutlinedIcon
        style={{
          color: 'var(--ant-color-link-hover)',
          fontSize: 22,
          marginBlock: '0.75rem',
        }}
      />

      <Typography.Paragraph>
        تصاویر، ویدیوها و فایل‌های محصول را اینجا بارگذاری کنید
      </Typography.Paragraph>

      <Flex
        vertical
        justify='center'
        align='center'
        className='mt-1 text-gray-400'
      >
        {withImages ? (
          <p>فرمت قابل قبول تصاویر: {IMAGES_FORMAT.join(DEFAULT_COMMA)}</p>
        ) : null}
        {withMovies ? (
          <p>فرمت قابل قبول ویدیوها: {MOVIES_FORMAT.join(DEFAULT_COMMA)}</p>
        ) : null}
        {withDocuments ? (
          <p>فرمت قابل قبول فایل‌ها:‌ {DOCUMENTS_FORMAT.join(DEFAULT_COMMA)}</p>
        ) : null}
      </Flex>
    </Dragger>
  ),
);
DraggerUpload.displayName = 'DraggerUpload';

export { DraggerUpload };
