import type { Category, CategoryStatus, ComponentMode } from 'types';

type State = Partial<
  Category &
    Record<'mode', ComponentMode> &
    Record<'step', 1 | 2> &
    Record<'defaultTitle', string> &
    Record<'defaultStatus', CategoryStatus>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_CATEGORY_TITLE';
      payload: Pick<typeof initialState, 'title'>;
    }
  | {
      type: 'UPDATE_CATEGORY_ICON_ID';
      payload: Pick<typeof initialState, 'iconId'>;
    }
  | {
      type: 'UPDATE_CATEGORY_PARENT';
      payload: Pick<typeof initialState, 'parent'>;
    }
  | {
      type: 'UPDATE_CATEGORY_RELATED_ATTRIBUTE';
      payload: Pick<typeof initialState, 'relatedAttribute'>;
    }
  | {
      type: 'UPDATE_CATEGORY_SUB_SLUG';
      payload: Pick<typeof initialState, 'subSlug'>;
    }
  | {
      type: 'UPDATE_CATEGORY_DESCRIPTION';
      payload: Pick<typeof initialState, 'description'>;
    }
  | {
      type: 'UPDATE_CATEGORY_STEP';
      payload: Pick<typeof initialState, 'step'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
