import * as React from 'react';

import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider, notification } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fa';

import { locale } from './locale';
import { theme } from './theme';
import { transformers } from './transformers';

dayjs.locale('fa');
notification.config({
  rtl: true,
});

const AntdProvider = ({ children }: React.PropsWithChildren<unknown>) => (
  <ConfigProvider theme={theme} locale={locale} direction='rtl'>
    <StyleProvider transformers={transformers} hashPriority='high'>
      {children}
    </StyleProvider>
  </ConfigProvider>
);

export { AntdProvider };
