import * as React from 'react';

import { useUpdateEffect } from 'ahooks';
import {
  Badge,
  Button,
  Dropdown,
  Flex,
  Modal,
  notification,
  Space,
  Table,
} from 'antd';

import {
  AntdRouterExternalLink,
  AntdRouterLink,
  CheckCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import { deleteAttributeConfig } from 'services';
import type { AttributeConfigSummary } from 'types';
import {
  adjustPageNumber,
  generateTableDataSource,
  localizeAttributeType,
  PATHNAME,
} from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    CATEGORIES: {
      BASE_URL: CATEGORIES_BASE_URL,
      UPDATE_URL: CATEGORIES_UPDATE_URL,
    },
    ATTRIBUTES: { UPDATE_URL: ATTRIBUTES_UPDATE_URL },
  },
} = PATHNAME;

type AttributesTableProps = {
  loading: boolean;
  total: number;
  attributes: AttributeConfigSummary[];
  pageSize: number;
  pageNumber: number;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const AttributesTable = React.memo<AttributesTableProps>(
  ({
    loading,
    total,
    attributes,
    pageSize,
    pageNumber,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: AttributesTableProps) => {
    const [modal, contextHolder] = Modal.useModal();

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteAttributeConfig = async (id: string) => {
      deleteAttributeConfig({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const attributesDataSource = generateTableDataSource(attributes, 'id');

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: 700, y: 630 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ellipsis: true,
            render: (_, { id, name }) => (
              <AntdRouterLink
                ellipsis
                to={`${ATTRIBUTES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
              >
                {name}
              </AntdRouterLink>
            ),
          },
          {
            title: 'دسته‌بندی‌ها',
            dataIndex: 'categories',
            key: 'categories',
            ellipsis: true,
            render: (_, { categories }) => (
              <Space className='flex-wrap'>
                {categories.map(({ id, title }) => (
                  <AntdRouterExternalLink
                    ellipsis
                    key={id}
                    href={`${CATEGORIES_BASE_URL}${CATEGORIES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                  >
                    <Badge count={title} color='blue' />
                  </AntdRouterExternalLink>
                ))}
              </Space>
            ),
          },
          {
            title: 'نوع',
            dataIndex: 'type',
            key: 'type',
            width: 110,
            ellipsis: true,
            render: (_, { type }) => localizeAttributeType(type).label,
          },
          {
            title: 'قابلیت فیلتر',
            dataIndex: 'isFilter',
            key: 'isFilter',
            width: 110,
            ellipsis: true,
            render: (_, { isFilter }) => (
              <Flex justify='center'>
                {isFilter ? (
                  <CheckCircleOutlinedIcon className='text-green-500' />
                ) : (
                  <CloseCircleOutlinedIcon className='text-red-500' />
                )}
              </Flex>
            ),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, name }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-attribute-config',
                        label: (
                          <AntdRouterLink
                            to={`${ATTRIBUTES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>ویرایش فیلد</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-attribute-config',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='my-0.5 flex items-center px-0'
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف فیلد "${name}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteAttributeConfig(id);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span className='text-sm'>حذف فیلد</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='pb-[18px] pt-3.5'>
                    <MoreOutlinedIcon className='text-xl' />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={attributesDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
AttributesTable.displayName = 'AttributesTable';

export { AttributesTable };
