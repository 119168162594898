import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { PurchaseFormContext } from 'context';
import type { ComponentMode, Purchase as PurchaseConfig } from 'types';

import { PurchaseForm, PurchaseHeader } from './components';

type PurchaseProps = {
  mode: ComponentMode;
};

export default function Purchase({ mode }: PurchaseProps) {
  const data = useLoaderData() as PurchaseConfig;

  const isUpdatedMode = mode === 'Update';
  const defaultStatus: typeof data.status = isUpdatedMode
    ? data.status
    : 'Purchased';
  const title = isUpdatedMode
    ? `سفارش ${data.trackingCode}`
    : 'افزودن سفارش جدید';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <PurchaseHeader>{title}</PurchaseHeader>

      <PurchaseFormContext
        mode={mode}
        defaultStatus={defaultStatus}
        {...(isUpdatedMode && data)}
      >
        <PurchaseForm />
      </PurchaseFormContext>
    </>
  );
}
