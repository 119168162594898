import { configResponsive, useResponsive } from 'ahooks';

const responsiveConfig = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};
configResponsive(responsiveConfig);

const useMediaQuery = () =>
  useResponsive() as Record<keyof typeof responsiveConfig, boolean>;

export { useMediaQuery };
