import { Upload } from 'lib';

type RequestBody = StrictOmit<Parameters<typeof Upload>[number], 'url'>;

const uploadBrandFile = async (body: RequestBody) =>
  await Upload({
    url: 'adminBrand/uploadBrandFile',
    ...body,
  });

export { uploadBrandFile };
