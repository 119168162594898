import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  cssVar: true,
  token: {
    fontFamily: 'var(--font-iran-yekan)',
    colorPrimary: '#1890ff',
    borderRadius: 2,
    screenSMMin: 640,
    screenSM: 640,
    screenSMMax: 640,
    screenMDMin: 768,
    screenMD: 768,
    screenMDMax: 768,
    screenLGMin: 1024,
    screenLG: 1024,
    screenLGMax: 1024,
    screenXLMin: 1280,
    screenXL: 1280,
    screenXLMax: 1280,
    screenXXLMin: 1536,
    screenXXL: 1536,
  },
  components: {
    Layout: {
      bodyBg: 'unset',
      siderBg: 'unset',
    },
    Menu: {
      activeBarBorderWidth: 0,
      activeBarWidth: 3,
      itemMarginInline: 0,
      itemMarginBlock: 5,
      itemHoverBg: 'unset',
      itemActiveBg: 'unset',
      subMenuItemBg: 'unset',
    },
    DatePicker: {
      controlHeight: 40,
    },
    Select: {
      controlHeight: 40,
      multipleItemBg: 'var(--ant-color-primary-bg)',
      multipleItemBorderColor: 'var(--ant-color-primary-border)',
    },
    Input: {
      paddingBlock: 8,
    },
    InputNumber: {
      controlHeight: 40,
    },
    Button: {
      fontSize: 15,
      controlHeight: 40,
      textHoverBg: 'unset',
      colorBgTextActive: 'transparent',
    },
  },
};

export { theme };
