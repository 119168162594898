import * as React from 'react';

import { useDebounceFn } from 'ahooks';
import { Col, Form, Row } from 'antd';

import { RichTextEditor } from 'components';
import { useProductCommentAction, useProductCommentValue } from 'context';
import type { FileOwnerType } from 'types';
import { generateRequestUrl } from 'utils';

type FieldType = Pick<ReturnType<typeof useProductCommentValue>, 'content'>;

const ProductCommentFormContent = () => {
  const { content = '' } = useProductCommentValue();
  const action = useProductCommentAction();

  const { run: debounceUpdateContent } = useDebounceFn(
    (debouncedContent: typeof content) => {
      action({
        type: 'UPDATE_PRODUCT_COMMENT_CONTENT',
        payload: {
          content: debouncedContent,
        },
      });
    },
  );

  const uploadOwnerType: FileOwnerType = 'Product';
  const uploadAction = generateRequestUrl('adminProduct/uploadProductFile');

  return (
    <Row className='rounded bg-white px-6 pt-4 lg:basis-[70%]'>
      <Col span={24}>
        <Form.Item<FieldType> label='دیدگاه' name='content'>
          <RichTextEditor
            options={{
              content,
              onUpdate: ({ editor }) => {
                debounceUpdateContent(editor.getHTML());
              },
            }}
            config={{
              placeholder: {
                placeholder: 'دیدگاه مرتبط با محصول را ویرایش کنید',
              },
            }}
            upload={{
              ownerType: uploadOwnerType,
              action: uploadAction,
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { ProductCommentFormContent };
