/* eslint-disable react/prop-types */
import * as React from 'react';

import { useBoolean, useMemoizedFn, useMount, useRequest } from 'ahooks';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Empty,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';

import {
  CheckInputbox,
  CopyOutlinedIcon,
  DecimalInputNumber,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MenuOutlinedIcon,
  PlusOutlinedIcon,
  UpOutlinedIcon,
} from 'components';
import { useProductAction, useProductValue } from 'context';
import { DND_CSS, dndArrayMove, DndSortableContext, useDndSortable } from 'lib';
import { getCategory } from 'services';
import type { ProductType, SelectOption, SubProduct } from 'types';
import {
  cn,
  generateSelectOptions,
  generateSubProductTitle,
  inputRule,
  localeSortByKey,
  localizeProductType,
  localizeSubProductActivateStock,
  normalizeToString,
  numberFormat,
  randomId,
  replaceExtraSeparator,
  UNIT,
} from 'utils';

const { PRICE, WIDTH, WEIGHT } = UNIT;

const productTypes = ['Normal', 'Variable'] satisfies ProductType[];

type FieldType = Pick<
  ReturnType<typeof useProductValue>,
  'productType' | 'variableAttributes'
>;

type ModalFieldType = Partial<SubProduct & Record<string, string | string[]>>;

type ProductModelsInfoSubProductProps = SubProduct &
  Record<'openModal', () => void>;

const ProductModelsInfoSubProduct =
  React.memo<ProductModelsInfoSubProductProps>(
    ({
      id,
      identifier,
      abstraction,
      originalPrice,
      price,
      purchaseMinStock,
      purchaseMaxStock,
      activateStock,
      stock,
      weight,
      height,
      width,
      length,
      attributes,
      openModal,
    }) => {
      const {
        attributes: sortableAttributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
      } = useDndSortable({
        id,
      });
      const { name, productType, subProducts = [] } = useProductValue();
      const action = useProductAction();

      const modelName = generateSubProductTitle({
        name,
        productType,
        attributes: localeSortByKey(attributes, 'name'),
      });
      const disableDuplicateSubProduct =
        productType === 'Normal' ? true : false;
      const defaultButtonProps: React.ComponentPropsWithoutRef<typeof Button> =
        {
          type: 'text',
          size: 'small',
        };
      const defaultColProps: React.ComponentPropsWithoutRef<typeof Col> = {
        span: 24,
        sm: 12,
        lg: 8,
        className: 'first:[&>span>span]:text-gray-400',
      };

      return (
        <Collapse
          ref={setNodeRef}
          size='large'
          collapsible='icon'
          expandIconPosition='end'
          className='my-2.5 last-of-type:mb-0 [&_.ant-collapse-header]:py-5'
          style={{
            transform: DND_CSS.Transform.toString(transform),
            transition,
            ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
          }}
          expandIcon={({ isActive }) => (
            <UpOutlinedIcon
              className={cn(isActive ? 'rotate-0' : 'rotate-180')}
            />
          )}
          items={[
            {
              key: id,
              label: (
                <Space align='center'>
                  <MenuOutlinedIcon {...sortableAttributes} {...listeners} />
                  <Typography.Text ellipsis className='max-w-[30vw]'>
                    {typeof modelName === 'string'
                      ? modelName
                      : Array.isArray(modelName)
                        ? modelName.map(({ name, value }, index) => {
                            const id = randomId();

                            return (
                              <React.Fragment key={id}>
                                {index !== 0 ? <span> | </span> : null}
                                <span className='text-slate-400'>{name}: </span>
                                <span>{value}</span>
                              </React.Fragment>
                            );
                          })
                        : null}
                  </Typography.Text>

                  {identifier ? <Tag color='default'>{identifier}</Tag> : null}
                </Space>
              ),
              extra: (
                <Space size={4}>
                  <Tooltip title='حذف مدل'>
                    <Button
                      icon={<DeleteOutlinedIcon />}
                      onClick={() => {
                        const updatedSubProducts = subProducts.filter(
                          subProduct => subProduct.id !== id,
                        );

                        action({
                          type: 'UPDATE_SUB_PRODUCTS',
                          payload: {
                            subProducts: updatedSubProducts,
                            selectedSubProduct: undefined,
                          },
                        });
                      }}
                      {...defaultButtonProps}
                    />
                  </Tooltip>
                  <Tooltip title='کپی مدل'>
                    <Button
                      icon={<CopyOutlinedIcon />}
                      disabled={disableDuplicateSubProduct}
                      onClick={() => {
                        const duplicatedSubProduct = subProducts.find(
                          subProduct => subProduct.id === id,
                        );

                        if (duplicatedSubProduct) {
                          action({
                            type: 'UPDATE_SUB_PRODUCTS',
                            payload: {
                              subProducts: [
                                ...subProducts,
                                {
                                  ...duplicatedSubProduct,
                                  id: randomId(),
                                  identifier: undefined,
                                },
                              ],
                              selectedSubProduct: undefined,
                            },
                          });
                        }
                      }}
                      {...defaultButtonProps}
                    />
                  </Tooltip>
                  <Tooltip title='ویرایش مدل'>
                    <Button
                      icon={<EditOutlinedIcon />}
                      onClick={() => {
                        const selectedSubProduct = subProducts.find(
                          subProduct => subProduct.id === id,
                        );

                        if (selectedSubProduct) {
                          action({
                            type: 'UPDATE_SUB_PRODUCTS',
                            payload: {
                              subProducts,
                              selectedSubProduct,
                            },
                          });
                        }
                        openModal();
                      }}
                      {...defaultButtonProps}
                    />
                  </Tooltip>
                </Space>
              ),
              children: (
                <Row gutter={[16, 20]}>
                  {Array.isArray(modelName)
                    ? modelName.map(({ name, value }) => {
                        const id = randomId();

                        return (
                          <Col key={id} {...defaultColProps}>
                            <Typography.Text>
                              <span>{name}: </span>
                              <span>{value}</span>
                            </Typography.Text>
                          </Col>
                        );
                      })
                    : null}

                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>شناسه محصول: </span>
                      <span>{identifier ?? '-'}</span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>قیمت اولیه: </span>
                      <span>
                        {originalPrice
                          ? numberFormat(originalPrice, undefined, ` ${PRICE}`)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>قیمت فروش (به مشتری): </span>
                      <span>
                        {price
                          ? numberFormat(price, undefined, ` ${PRICE}`)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>حداقل سفارش: </span>
                      <span>
                        {purchaseMinStock
                          ? numberFormat(purchaseMinStock)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>حداکثر سفارش: </span>
                      <span>
                        {purchaseMaxStock
                          ? numberFormat(purchaseMaxStock)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>فعال‌سازی انبار: </span>
                      <span>
                        {localizeSubProductActivateStock(activateStock).label}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>موجودی انبار: </span>
                      <span>{stock ? numberFormat(stock) : '-'}</span>
                    </Typography.Text>
                  </Col>

                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>طول: </span>
                      <span>
                        {length
                          ? numberFormat(length, undefined, ` ${WIDTH}`)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>عرض: </span>
                      <span>
                        {width
                          ? numberFormat(width, undefined, ` ${WIDTH}`)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>ارتفاع: </span>
                      <span>
                        {height
                          ? numberFormat(height, undefined, ` ${WIDTH}`)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>
                  <Col {...defaultColProps}>
                    <Typography.Text>
                      <span>وزن: </span>
                      <span>
                        {weight
                          ? numberFormat(weight, undefined, ` ${WEIGHT}`)
                          : '-'}
                      </span>
                    </Typography.Text>
                  </Col>

                  <Col
                    span={defaultColProps.span}
                    className={defaultColProps.className}
                  >
                    <Typography.Text>
                      <span>توضیحات مختصر: </span>
                      <span>{abstraction ?? '-'}</span>
                    </Typography.Text>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      );
    },
  );
ProductModelsInfoSubProduct.displayName = 'ProductModelsInfoSubProduct';

type ProductModelsInfoModalProps = {
  open: boolean;
  closeModal: () => void;
};

const ProductModelsInfoModal = React.memo<ProductModelsInfoModalProps>(
  ({ open, closeModal }) => {
    const [form] = Form.useForm<ModalFieldType>();
    const {
      name,
      productType,
      selectedSubProduct,
      subProducts = [],
      variableAttributes = [],
    } = useProductValue();
    const action = useProductAction();

    const mainVariableAttributes: SubProduct['attributes'] = localeSortByKey(
      selectedSubProduct
        ? selectedSubProduct.attributes
        : variableAttributes.map(attribute => ({
            ...attribute,
            selectedValues: [],
          })),
      'name',
    );
    const modelName = generateSubProductTitle({
      name,
      productType,
      attributes: mainVariableAttributes,
    });

    const onCancel = useMemoizedFn(() => {
      action({
        type: 'UPDATE_SUB_PRODUCTS',
        payload: {
          subProducts,
          selectedSubProduct: undefined,
        },
      });
      form.resetFields();
      closeModal();
    });

    const onFinish = useMemoizedFn(
      ({
        id = '',
        identifier = '',
        abstraction = '',
        originalPrice = 0,
        price,
        purchaseMinStock,
        purchaseMaxStock,
        activateStock,
        warnThresholdStock,
        stock,
        width,
        length,
        height,
        weight,
        attributes = [],
        priceHistories = [],
        ...otherValues
      }: ModalFieldType) => {
        const defaultSubProducts = selectedSubProduct
          ? subProducts.filter(
              subProduct => subProduct.id !== selectedSubProduct.id,
            )
          : subProducts;
        const newSubProduct: NonNullable<typeof selectedSubProduct> = {
          id: selectedSubProduct ? selectedSubProduct.id : randomId(),
          identifier: replaceExtraSeparator(identifier),
          abstraction,
          originalPrice,
          price,
          purchaseMinStock,
          purchaseMaxStock,
          activateStock,
          warnThresholdStock,
          stock,
          width,
          length,
          height,
          weight,
          priceHistories,
          attributes: variableAttributes.map(({ id, ...otherAttributes }) => {
            const selectedValues =
              otherValues[`variable-attribute-${id}`] ?? [];

            return {
              ...otherAttributes,
              id,
              selectedValues:
                typeof selectedValues === 'string'
                  ? [selectedValues]
                  : selectedValues,
            };
          }),
        };

        action({
          type: 'UPDATE_SUB_PRODUCTS',
          payload: {
            subProducts: [newSubProduct, ...defaultSubProducts],
            selectedSubProduct: undefined,
          },
        });
        form.resetFields();
        closeModal();
      },
    );

    return (
      <Modal
        centered
        destroyOnClose
        width={800}
        title={
          <Space align='center'>
            <Typography.Title
              ellipsis
              level={5}
              className='mb-0 max-w-xl font-medium'
            >
              {selectedSubProduct
                ? typeof modelName === 'string'
                  ? modelName
                  : Array.isArray(modelName)
                    ? modelName.map(({ name, value }, index) => {
                        const id = randomId();

                        return (
                          <React.Fragment key={id}>
                            {index !== 0 ? <span> | </span> : null}
                            <span className='text-slate-400'>{name}: </span>
                            <span>{value}</span>
                          </React.Fragment>
                        );
                      })
                    : null
                : 'افزودن مدل جدید'}
            </Typography.Title>

            {selectedSubProduct ? (
              <Tag bordered={false} color='blue'>
                {selectedSubProduct.identifier}
              </Tag>
            ) : null}
          </Space>
        }
        okText={selectedSubProduct ? 'ذخیره تغییرات' : 'افزودن مدل'}
        okButtonProps={{
          className: 'px-5',
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        classNames={{
          content: 'p-0',
          header: 'px-5 pt-4',
          footer: 'px-5 pb-3',
        }}
        open={open}
        onOk={form.submit}
        onCancel={onCancel}
      >
        <Divider className='mt-4' />

        <Form
          labelWrap
          form={form}
          layout='vertical'
          autoComplete='off'
          className='max-h-[calc(100dvh-15rem)] overflow-y-auto py-3'
          fields={
            selectedSubProduct && [
              { name: 'identifier', value: selectedSubProduct.identifier },
              { name: 'abstraction', value: selectedSubProduct.abstraction },
              {
                name: 'originalPrice',
                value: selectedSubProduct.originalPrice,
              },
              { name: 'price', value: selectedSubProduct.price },
              {
                name: 'purchaseMinStock',
                value: selectedSubProduct.purchaseMinStock,
              },
              {
                name: 'purchaseMaxStock',
                value: selectedSubProduct.purchaseMaxStock,
              },
              {
                name: 'activateStock',
                value: selectedSubProduct.activateStock,
              },
              {
                name: 'warnThresholdStock',
                value: selectedSubProduct.warnThresholdStock,
              },
              { name: 'stock', value: selectedSubProduct.stock },
              { name: 'width', value: selectedSubProduct.width },
              { name: 'length', value: selectedSubProduct.length },
              { name: 'height', value: selectedSubProduct.height },
              { name: 'weight', value: selectedSubProduct.weight },
              ...selectedSubProduct.attributes.map(
                ({ id, type, selectedValues }) => ({
                  name: `variable-attribute-${id}`,
                  value:
                    type === 'CheckBox' ? selectedValues : selectedValues[0],
                }),
              ),
            ]
          }
          onFinish={onFinish}
        >
          <Row className='px-6'>
            {productType === 'Variable' ? (
              <>
                {mainVariableAttributes.map(
                  ({ id, name, type, values }, index) => {
                    const isLastIndex =
                      mainVariableAttributes.length - 1 === index;
                    const isOddLength = mainVariableAttributes.length % 2 === 1;
                    const isOddIndex = index % 2 === 1;
                    const isText = type === 'Text';
                    const isNumber = type === 'Number';
                    const isCheckBox = type === 'CheckBox';
                    const isSelect = type === 'Select';

                    return (
                      <Col
                        key={id}
                        span={24}
                        lg={isOddLength && isLastIndex ? 24 : 12}
                        className={cn(
                          isOddIndex ? 'lg:ps-3' : 'lg:pe-3',
                          isOddLength && isLastIndex && 'lg:p-0',
                        )}
                      >
                        <Form.Item<ModalFieldType>
                          label={name}
                          name={`variable-attribute-${id}`}
                          rules={
                            isText
                              ? [inputRule.required, inputRule.string]
                              : isNumber
                                ? [inputRule.required, inputRule.digit]
                                : [inputRule.required]
                          }
                        >
                          {isText || isNumber ? (
                            <Input placeholder={`${name} محصول را وارد کنید`} />
                          ) : isCheckBox || isSelect ? (
                            <Select<string>
                              showSearch
                              maxTagCount='responsive'
                              mode={isCheckBox ? 'multiple' : undefined}
                              placeholder={`${name} محصول را انتخاب کنید`}
                              options={values.map(value => ({ value }))}
                            />
                          ) : undefined}
                        </Form.Item>
                      </Col>
                    );
                  },
                )}

                <Col span={24}>
                  <Divider dashed className='mb-6 mt-1' />
                </Col>
              </>
            ) : null}

            <Col span={24} lg={12} className='lg:pe-3'>
              <Form.Item<ModalFieldType>
                label='شناسه محصول'
                name='identifier'
                rules={[inputRule.nonDot]}
              >
                <Input placeholder='شناسه محصول را وارد کنید' />
              </Form.Item>
            </Col>

            <Col span={24} lg={12} className='lg:ps-3'>
              <Form.Item<ModalFieldType>
                label='توضیحات مختصر'
                name='abstraction'
                rules={[inputRule.string, inputRule.abstraction]}
              >
                <Input.TextArea
                  rows={1}
                  placeholder='توضیحات مختصر محصول را وارد کنید'
                  className='min-h-10'
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider dashed className='mb-6 mt-1' />
            </Col>

            <Col span={24} lg={12} className='lg:pe-3'>
              <Form.Item<ModalFieldType>
                label='قیمت اولیه'
                name='originalPrice'
                rules={[inputRule.required, inputRule.digit]}
              >
                <DecimalInputNumber
                  placeholder='قیمت اولیه را وارد کنید'
                  addonAfter={PRICE}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:ps-3'>
              <Form.Item<ModalFieldType>
                label='قیمت فروش (به مشتری)'
                name='price'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber
                  placeholder='قیمت فروش (به مشتری) را وارد کنید'
                  addonAfter={PRICE}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:pe-3'>
              <Form.Item<ModalFieldType>
                label='حداقل سفارش'
                name='purchaseMinStock'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber placeholder='حداقل سفارش را وارد کنید' />
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:ps-3'>
              <Form.Item<ModalFieldType>
                label='حداکثر سفارش'
                name='purchaseMaxStock'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber placeholder='حداکثر سفارش را وارد کنید' />
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:pe-3'>
              <Form.Item<ModalFieldType>
                label='فعال‌سازی انبار'
                name='activateStock'
                valuePropName='checked'
              >
                <CheckInputbox>فعال‌سازی انبار برای موجودی</CheckInputbox>
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:ps-3'>
              <Form.Item<ModalFieldType>
                label='موجودی انبار'
                name='stock'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber placeholder='موجودی انبار را وارد کنید' />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider dashed className='mb-6 mt-1' />
            </Col>

            <Col span={24} lg={12} className='lg:pe-3'>
              <Form.Item<ModalFieldType>
                label='طول'
                name='length'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber
                  placeholder='طول را وارد کنید'
                  addonAfter={WIDTH}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:ps-3'>
              <Form.Item<ModalFieldType>
                label='عرض'
                name='width'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber
                  placeholder='عرض را وارد کنید'
                  addonAfter={WIDTH}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:pe-3'>
              <Form.Item<ModalFieldType>
                label='ارتفاع'
                name='height'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber
                  placeholder='ارتفاع را وارد کنید'
                  addonAfter={WIDTH}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12} className='lg:ps-3'>
              <Form.Item<ModalFieldType>
                label='وزن'
                name='weight'
                rules={[inputRule.digit]}
              >
                <DecimalInputNumber
                  placeholder='وزن را وارد کنید'
                  addonAfter={WEIGHT}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider className='mb-1.5' />
      </Modal>
    );
  },
);
ProductModelsInfoModal.displayName = 'ProductModelsInfoModal';

const ProductModelsInfo = () => {
  const [open, { set: setOpen }] = useBoolean(false);
  const {
    data: categoryData,
    loading: categoryLoading,
    run,
  } = useRequest(getCategory, {
    manual: true,
  });
  const {
    mainCategory,
    productType,
    subProducts = [],
    variableAttributes = [],
  } = useProductValue();
  const action = useProductAction();

  useMount(() => {
    if (mainCategory) {
      run({ id: mainCategory.id });
    }
  });

  const isNotVariableProduct = productType !== 'Variable';
  const disableCreateSubProduct =
    productType === 'Normal'
      ? subProducts.length >= 1
      : productType === 'Variable'
        ? variableAttributes.length === 0
        : true;

  const totalAttributes = categoryData?.attributes ?? [];
  const attributesSelectOptions = generateSelectOptions(
    totalAttributes,
    'id',
    'name',
    'id',
  );

  return (
    <Row>
      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='نوع محصول'
          name='productType'
          rules={[inputRule.required]}
        >
          <Select<SelectOption>
            labelInValue
            placeholder='نوع محصول را انتخاب کنید'
            options={productTypes.map(type => ({
              label: localizeProductType(type).label,
              value: type,
            }))}
            onChange={({ value }) => {
              action({
                type: 'UPDATE_PRODUCT_TYPE',
                payload: {
                  productType: value as typeof productType,
                },
              });
              action({
                type: 'UPDATE_SUB_PRODUCTS',
                payload: {
                  subProducts: undefined,
                  selectedSubProduct: undefined,
                },
              });
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='فیلد های متغیر'
          name='variableAttributes'
          rules={!isNotVariableProduct ? [inputRule.required] : undefined}
        >
          <Select<SelectOption[]>
            labelInValue
            mode='multiple'
            maxTagCount='responsive'
            className='text-[var(--ant-color-primary-active)]'
            placeholder='فیلد های متغیر را انتخاب کنید'
            disabled={isNotVariableProduct}
            loading={categoryLoading}
            options={attributesSelectOptions}
            filterOption={(inputValue, option) =>
              normalizeToString(option?.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={values => {
              const selectedAttributes = totalAttributes.filter(attribute =>
                values.some(({ value }) => attribute.id === value),
              );

              action({
                type: 'UPDATE_PRODUCT_VARIABLE_ATTRIBUTES',
                payload: {
                  variableAttributes: selectedAttributes,
                },
              });
              action({
                type: 'UPDATE_SUB_PRODUCTS',
                payload: {
                  subProducts: undefined,
                  selectedSubProduct: undefined,
                },
              });
            }}
            {...(!mainCategory && {
              notFoundContent: (
                <Empty
                  className='my-3'
                  description='ابتدا دسته‌بندی اصلی را انتخاب کنید.'
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 'calc(var(--ant-control-height-lg) * 0.875)',
                  }}
                />
              ),
            })}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Divider dashed className='mb-5 mt-2' />
      </Col>

      <Col span={24}>
        <Flex align='center' justify='space-between'>
          <Typography.Title level={3} className='text-base'>
            مدل‌ها
          </Typography.Title>

          <Button
            icon={<PlusOutlinedIcon className='text-xs' />}
            disabled={disableCreateSubProduct}
            onClick={() => setOpen(true)}
          >
            مدل جدید
          </Button>

          <ProductModelsInfoModal
            open={open}
            closeModal={() => setOpen(false)}
          />
        </Flex>

        <div className='my-4'>
          {subProducts.length > 0 ? (
            <DndSortableContext
              dnd={{
                onDragEnd: ({ active, over }) => {
                  if (active.id !== over?.id) {
                    const activeIndex = subProducts.findIndex(
                      ({ id }) => id === active.id,
                    );
                    const overIndex = subProducts.findIndex(
                      ({ id }) => id === over?.id,
                    );
                    const sortedSubProducts = dndArrayMove(
                      subProducts,
                      activeIndex,
                      overIndex,
                    );

                    action({
                      type: 'UPDATE_SUB_PRODUCTS',
                      payload: {
                        subProducts: sortedSubProducts,
                        selectedSubProduct: undefined,
                      },
                    });
                  }
                },
              }}
              sortable={{
                items: subProducts.map(({ id }) => id),
                children: subProducts.map(subProduct => (
                  <ProductModelsInfoSubProduct
                    key={subProduct.id}
                    openModal={() => setOpen(true)}
                    {...subProduct}
                  />
                )),
              }}
            />
          ) : (
            <Empty
              className='my-20'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span className='text-zinc-300'>هنوز مدلی ساخته نشده است.</span>
              }
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export {
  ProductModelsInfo,
  ProductModelsInfoModal,
  ProductModelsInfoSubProduct,
};
