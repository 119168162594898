import * as React from 'react';

import { useDebounceFn } from 'ahooks';
import { Col, Flex, Form, Input, Row, Typography } from 'antd';

import {
  ImgCropUpload,
  PictureOutlinedIcon,
  RichTextEditor,
  UploadOutlinedIcon,
} from 'components';
import { useBrandAction, useBrandValue } from 'context';
import type { FileOwnerType } from 'types';
import { generateFileUrl, generateRequestUrl, inputRule } from 'utils';

type FieldType = Pick<
  ReturnType<typeof useBrandValue>,
  'title' | 'bannerId' | 'iconId' | 'slug' | 'description'
>;

const BrandBaseInfo = () => {
  const {
    title = '',
    slug,
    description,
    bannerId,
    bannerStatus,
    iconId,
    iconStatus,
  } = useBrandValue();
  const action = useBrandAction();

  const { run: debounceUpdateTitle } = useDebounceFn(
    (debouncedTitle: typeof title) => {
      action({
        type: 'UPDATE_BRAND_TITLE',
        payload: {
          title: debouncedTitle,
        },
      });
    },
  );
  const { run: debounceUpdateSlug } = useDebounceFn(
    (debouncedSlug: typeof slug) => {
      action({
        type: 'UPDATE_BRAND_SLUG',
        payload: {
          slug: debouncedSlug,
        },
      });
    },
  );
  const { run: debounceUpdateDescription } = useDebounceFn(
    (debouncedDescription: typeof description) => {
      action({
        type: 'UPDATE_BRAND_DESCRIPTION',
        payload: {
          description: debouncedDescription,
        },
      });
    },
  );

  const uploadOwnerType: FileOwnerType = 'Brand';
  const uploadAction = generateRequestUrl('adminBrand/uploadBrandFile');

  return (
    <Row>
      <Col span={24}>
        <Form.Item<FieldType> name='bannerId' valuePropName='file'>
          <ImgCropUpload
            className='[&_.ant-upload-list]:h-56'
            action={uploadAction}
            fileList={
              bannerId
                ? [
                    {
                      uid: bannerId,
                      name: title,
                      status: 'done',
                      url: generateFileUrl(uploadOwnerType, bannerId, 400, 400),
                    },
                  ]
                : undefined
            }
            onChange={({ file: { status, response } }) => {
              action({
                type: 'UPDATE_BRAND_BANNER',
                payload: {
                  bannerId: undefined,
                  bannerStatus: true,
                },
              });

              if (status === 'done' && response) {
                const bannerId = response.id;
                action({
                  type: 'UPDATE_BRAND_BANNER',
                  payload: {
                    bannerId,
                    bannerStatus: true,
                  },
                });
              }

              if (status === 'error' || status === 'removed') {
                action({
                  type: 'UPDATE_BRAND_BANNER',
                  payload: {
                    bannerId: undefined,
                    bannerStatus: false,
                  },
                });
              }
            }}
          >
            {!bannerStatus ? (
              <Flex vertical justify='center' align='center' gap='middle'>
                <PictureOutlinedIcon className='text-xl text-[var(--ant-color-link-hover)]' />
                <Typography.Paragraph>
                  برنما برند را بار‌گذاری کنید
                </Typography.Paragraph>
              </Flex>
            ) : null}
          </ImgCropUpload>
        </Form.Item>
      </Col>

      <Col span={24} lg={8} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='نشان‌واره'
          name='iconId'
          valuePropName='file'
        >
          <ImgCropUpload
            action={uploadAction}
            fileList={
              iconId
                ? [
                    {
                      uid: iconId,
                      name: title,
                      status: 'done',
                      url: generateFileUrl(uploadOwnerType, iconId, 200, 200),
                    },
                  ]
                : undefined
            }
            onChange={({ file: { status, response } }) => {
              action({
                type: 'UPDATE_BRAND_ICON',
                payload: {
                  iconId: undefined,
                  iconStatus: true,
                },
              });

              if (status === 'done' && response) {
                const iconId = response.id;
                action({
                  type: 'UPDATE_BRAND_ICON',
                  payload: {
                    iconId,
                    iconStatus: true,
                  },
                });
              }

              if (status === 'error' || status === 'removed') {
                action({
                  type: 'UPDATE_BRAND_ICON',
                  payload: {
                    iconId: undefined,
                    iconStatus: false,
                  },
                });
              }
            }}
          >
            {!iconStatus ? (
              <Flex vertical justify='center' align='center' gap='small'>
                <UploadOutlinedIcon className='text-xl text-[var(--ant-color-link-hover)]' />
                <Typography.Paragraph>
                  نشان‌واره برند را بار‌گذاری کنید
                </Typography.Paragraph>
              </Flex>
            ) : null}
          </ImgCropUpload>
        </Form.Item>
      </Col>

      <Col span={24} lg={16} className='lg:ps-3'>
        <Row>
          <Col span={24}>
            <Form.Item<FieldType>
              label='عنوان'
              name='title'
              rules={[inputRule.required, inputRule.string]}
            >
              <Input
                placeholder='عنوان برند را وارد کنید'
                onChange={({ target: { value } }) => {
                  debounceUpdateTitle(value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item<FieldType>
              label='پیوند یکتا'
              name='slug'
              rules={[inputRule.required, inputRule.nonDot]}
            >
              <Input
                placeholder='پیوند یکتا برند را وارد کنید'
                onChange={({ target: { value } }) => {
                  debounceUpdateSlug(value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType> label='توضیحات' name='description'>
          <RichTextEditor
            options={{
              content: description,
              onUpdate: ({ editor }) => {
                debounceUpdateDescription(editor.getHTML());
              },
            }}
            config={{
              placeholder: {
                placeholder: 'توضیحات را وارد کنید',
              },
            }}
            upload={{
              ownerType: uploadOwnerType,
              action: uploadAction,
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { BrandBaseInfo };
