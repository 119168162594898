import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { ProductCommentFormContext } from 'context';
import type {
  ComponentMode,
  ProductComment as ProductCommentConfig,
} from 'types';

import { ProductCommentForm, ProductCommentHeader } from './components';

type ProductCommentProps = {
  mode: ComponentMode;
};

export default function ProductComment({ mode }: ProductCommentProps) {
  const data = useLoaderData() as ProductCommentConfig;

  const isUpdatedMode = mode === 'Update';
  const defaultStatus: typeof data.status = isUpdatedMode
    ? data.status
    : 'Accepted';
  const defaultName = isUpdatedMode
    ? `دیدگاه کاربر "${data.user.firstName ? data.user.firstName.concat(` ${data.user.lastName || ''}`) : data.user.id}" برای "${data.product.name}"`
    : '';
  const title = isUpdatedMode ? defaultName : 'افزودن دیدگاه جدید';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <ProductCommentHeader>{title}</ProductCommentHeader>

      <ProductCommentFormContext
        mode={mode}
        defaultName={defaultName}
        defaultStatus={defaultStatus}
        {...(isUpdatedMode && data)}
      >
        <ProductCommentForm />
      </ProductCommentFormContext>
    </>
  );
}
