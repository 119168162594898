import * as React from 'react';

import { Button, Flex, Typography } from 'antd';

import { AntdRouterLink, PlusOutlinedIcon } from 'components';
import { PATHNAME } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    CATEGORIES: { CREATE_URL: CATEGORIES_CREATE_URL },
  },
} = PATHNAME;

const CategoriesHeader = ({ children }: React.PropsWithChildren<unknown>) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='mb-0 py-1'>
      <span className='text-base'>دسته‌بندی‌ها</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      {children}

      <AntdRouterLink
        to={`${CATEGORIES_CREATE_URL}${DEFAULT_SLUG_SEPARATOR}`}
        className='max-lg:basis-full'
      >
        <Button
          type='primary'
          className='w-full'
          icon={<PlusOutlinedIcon className='text-xs' />}
        >
          دسته‌بندی‌ جدید
        </Button>
      </AntdRouterLink>
    </Flex>
  </Flex>
);

export { CategoriesHeader };
