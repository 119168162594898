import * as React from 'react';

import { Button, Result, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';

import { AntdRouterLink } from 'components';
import { PATHNAME } from 'utils';

const {
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
  },
} = PATHNAME;
const { Title, Text } = Typography;

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>404 | کاشی لند</title>
      </Helmet>

      <div className='flex h-full items-center justify-center rounded bg-white'>
        <Result
          status='404'
          title={<Title style={{ margin: 0 }}>404</Title>}
          subTitle={
            <Text type='secondary'>
              صفحه‌ای که به دنبال آن هستید ممکن است حذف شده باشد، نام آن تغییر
              کرده باشد یا به طور موقت در دسترس نباشد.
            </Text>
          }
          extra={
            <Button type='primary' className='rounded-md px-2.5 py-1.5 text-sm'>
              <AntdRouterLink to={HOME_BASE_URL} className='hover:text-inherit'>
                بازگشت به خانه
              </AntdRouterLink>
            </Button>
          }
        />
      </div>
    </>
  );
}
