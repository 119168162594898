import { useContext } from 'react';

import { PurchaseActionContext, PurchaseValueContext } from './context';

const usePurchaseValue = () => {
  const context = useContext(PurchaseValueContext);

  if (!context) {
    throw new Error(
      'usePurchaseValue must be used within a <PurchaseFormContext />',
    );
  }

  return context;
};

const usePurchaseAction = () => {
  const context = useContext(PurchaseActionContext);

  if (!context) {
    throw new Error(
      'usePurchaseAction must be used within a <PurchaseFormContext />',
    );
  }

  return context;
};

export { usePurchaseAction, usePurchaseValue };
