import type {
  AttributeConfigType,
  CategoryStatus,
  ProductCommentStatus,
  ProductStatus,
  ProductType,
  PurchaseStatus,
  UserRoleName,
} from 'types';

const localizeAttributeType = (type?: AttributeConfigType) => {
  switch (type) {
    case 'Text':
      return { label: 'متن' };
    case 'Number':
      return { label: 'عدد' };
    case 'Select':
      return { label: 'تک‌گزینه‌ایی' };
    case 'CheckBox':
      return { label: 'چندگزینه‌ایی' };
    default:
      return { label: undefined };
  }
};

const localizeProductType = (type?: ProductType) => {
  switch (type) {
    case 'Normal':
      return { label: 'ثابت' };
    case 'Variable':
      return { label: 'متغیر' };
    default:
      return { label: undefined };
  }
};

const localizeProductStatus = (status?: ProductStatus) => {
  switch (status) {
    case 'Draft':
      return { label: 'پیش‌نویس', color: 'yellow' };
    case 'Private':
      return { label: 'خصوصی', color: 'purple' };
    case 'Submitted':
      return { label: 'منتشرشده', color: 'green' };
    default:
      return { label: undefined, color: undefined };
  }
};

const localizeProductCommentStatus = (status?: ProductCommentStatus) => {
  switch (status) {
    case 'OnReview':
      return { label: 'در حال بررسی', color: 'yellow' };
    case 'Rejected':
      return { label: 'ردشده', color: 'red' };
    case 'Accepted':
      return { label: 'تاییدشده', color: 'green' };
    default:
      return { label: undefined, color: undefined };
  }
};

const localizePurchaseStatus = (status?: PurchaseStatus) => {
  switch (status) {
    case 'Purchased':
      return { label: 'در حال انجام', color: 'yellow' };
    case 'Posted':
      return { label: 'ارسال‌شده', color: 'blue' };
    case 'Delivered':
      return { label: 'تحویل داده‌شده', color: 'green' };
    default:
      return { label: undefined, color: undefined };
  }
};

const localizeCategoryStatus = (status?: CategoryStatus) => {
  switch (status) {
    case 'Active':
      return { label: 'فعال', color: 'green' };
    case 'DeActive':
      return { label: 'غیرفعال', color: 'red' };
    default:
      return { label: undefined, color: undefined };
  }
};

const localizeUserRoleName = (roleName?: UserRoleName) => {
  switch (roleName) {
    case 'Admin':
      return { label: 'مدیر کل', color: 'gold' };
    case 'Editor':
      return { label: 'ویراستار', color: 'green' };
    case 'User':
      return { label: 'مشترک', color: 'blue' };
    default:
      return { label: undefined, color: undefined };
  }
};

const localizeSubProductActivateStock = (activateStock?: boolean) => {
  switch (activateStock) {
    case true:
      return { label: 'فعال', color: 'green' };
    default:
      return { label: 'غیرفعال', color: 'red' };
  }
};

export {
  localizeAttributeType,
  localizeCategoryStatus,
  localizeProductCommentStatus,
  localizeProductStatus,
  localizeProductType,
  localizePurchaseStatus,
  localizeSubProductActivateStock,
  localizeUserRoleName,
};
