import { createContext } from 'react';
import type { Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const UserValueContext = createContext<typeof initialState>(initialState);

const UserActionContext = createContext<Dispatch<typeof initialAction>>(
  () => {},
);

export { UserActionContext, UserValueContext };
