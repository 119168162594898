const STORAGE = {
  COOKIE: {
    DEFAULT: {},
    IDENTITY: {
      BASE: 'identity',
      ACCESS_TOKEN: 'access-token',
      REFRESH_TOKEN: 'refresh-token',
      EXPIRE_AT: 'expire-at',
    },
  },
  LOCAL: {
    DEFAULT: {},
  },
  SESSION: {
    DEFAULT: {},
  },
} as const;

export { STORAGE };
