import * as React from 'react';

import {
  Badge,
  Button,
  Divider,
  Form,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlinedIcon } from 'components';
import { useProductValue } from 'context';
import { deleteProduct } from 'services';
import type { ProductStatus } from 'types';
import { localizeProductStatus, PATHNAME } from 'utils';

const {
  LOCAL: {
    PRODUCTS: { BASE_URL: PRODUCTS_BASE_URL },
  },
} = PATHNAME;

const productStatuses = [
  'Submitted',
  'Draft',
  'Private',
] satisfies ProductStatus[];

type FieldType = Pick<ReturnType<typeof useProductValue>, 'status'>;

const ProductFormAction = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { id = '', defaultName, defaultStatus, mode } = useProductValue();
  const navigate = useNavigate();

  const handleDeleteProduct = async (id: string) => {
    deleteProduct({ id })
      .then(() => {
        navigate(PRODUCTS_BASE_URL);
      })
      .catch(message => {
        notification.error({ message });
      });
  };

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='mb-0 text-base font-medium'>
          وضعیت انتشار
        </Typography.Title>
      </div>

      <Divider style={{ marginBlockStart: 15, marginBlockEnd: 30 }} />

      <div className='px-6'>
        <Typography.Paragraph>
          <Badge
            text={`وضعیت فعلی: ${localizeProductStatus(defaultStatus).label}`}
            color={localizeProductStatus(defaultStatus).color}
          />
        </Typography.Paragraph>

        <Form.Item<FieldType> label='وضعیت جدید' name='status' className='mb-2'>
          <Select
            placeholder='نوع وضعیت را انتخاب کنید'
            options={productStatuses.map(type => ({
              label: localizeProductStatus(type).label,
              value: type,
            }))}
          />
        </Form.Item>
      </div>

      <div className='p-6'>
        {mode === 'Update' ? (
          <Form.Item className='mb-2.5'>
            <Button
              block
              danger
              type='primary'
              className='flex items-center'
              onClick={async () => {
                const confirmed = await modal.confirm({
                  title: `آیا از حذف محصول "${defaultName}" اطمینان دارید؟`,
                  width: 500,
                });

                if (confirmed) {
                  handleDeleteProduct(id);
                }
              }}
            >
              <DeleteOutlinedIcon />
              <span className='text-sm'>حذف محصول</span>
            </Button>

            {contextHolder}
          </Form.Item>
        ) : null}

        <Form.Item className='mb-0'>
          <Button block type='primary' htmlType='submit'>
            {mode === 'Create' ? 'افزودن محصول جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export { ProductFormAction };
