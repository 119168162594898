const PATHNAME = {
  DEFAULT: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    INSTAGRAM_BASE_URL: process.env.REACT_APP_INSTAGRAM_BASE_URL,
    LINKEDIN_BASE_URL: process.env.REACT_APP_LINKEDIN_BASE_URL,
    TWITTER_BASE_URL: process.env.REACT_APP_TWITTER_BASE_URL,
    TELEGRAM_BASE_URL: process.env.REACT_APP_TELEGRAM_BASE_URL,
    WHATSAPP_BASE_URL: process.env.REACT_APP_WHATSAPP_BASE_URL,
    RUBIKA_BASE_URL: process.env.REACT_APP_RUBIKA_BASE_URL,
    BALE_BASE_URL: process.env.REACT_APP_BALE_BASE_URL,
    EITAA_BASE_URL: process.env.REACT_APP_EITAA_BASE_URL,
    SLUG_SEPARATOR: '/',
    TIMEZONE: 'Asia/Tehran',
    LOCALE: 'fa-IR',
  },
  LOCAL: {
    HOME: {
      BASE_URL: '/',
    },
    PRODUCTS: {
      BASE_URL: '/products/',
      CREATE_URL: 'create-product',
      UPDATE_URL: 'update-product',
    },
    PURCHASES: {
      BASE_URL: '/purchases/',
      CREATE_URL: 'create-purchase',
      UPDATE_URL: 'update-purchase',
    },
    USERS: {
      BASE_URL: '/users/',
      CREATE_URL: 'create-user',
      UPDATE_URL: 'update-user',
    },
    BRANDS: {
      BASE_URL: '/brands/',
      CREATE_URL: 'create-brand',
      UPDATE_URL: 'update-brand',
    },
    CATEGORIES: {
      BASE_URL: '/categories/',
      CREATE_URL: 'create-category',
      UPDATE_URL: 'update-category',
    },
    PACKAGING_MODELS: {
      BASE_URL: '/packaging-models/',
      CREATE_URL: 'create-packaging-model',
      UPDATE_URL: 'update-packaging-model',
    },
    ATTRIBUTES: {
      BASE_URL: '/attributes/',
      CREATE_URL: 'create-attribute',
      UPDATE_URL: 'update-attribute',
    },
    PACKAGE_MANAGEMENT: {
      BASE_URL: '/package-management/',
    },
    GLOBAL_EDIT: {
      BASE_URL: '/global-edit/',
    },
    MARKETING: {
      BASE_URL: '/marketing/',
    },
    REPORTS: {
      BASE_URL: '/reports/',
    },
    LOGISTIC: {
      BASE_URL: '/logistic/',
    },
    COMMENTS_MANAGEMENT: {
      BASE_URL: '/comments-management/',
      PRODUCTS_COMMENTS: {
        BASE_URL: 'products-comments',
        CREATE_URL: 'create-product-comment',
        UPDATE_URL: 'update-product-comment',
      },
      QUESTIONS: {
        BASE_URL: 'questions',
        CREATE_URL: 'create-question',
        UPDATE_URL: 'update-question',
      },
    },
    LAYOUT_SETTING: {
      BASE_URL: '/layout-setting/',
      MEGA_MENUS: {
        BASE_URL: 'mega-menus',
        CREATE_URL: 'create-mega-menu',
        UPDATE_URL: 'update-mega-menu',
      },
    },
    SEO_SETTING: {
      BASE_URL: '/seo-setting/',
    },
    LOGIN: {
      BASE_URL: '/login/',
    },
    PRODUCT: {
      BASE_URL: '/product/',
    },
    SITEMAP: {
      BASE_URL: 'sitemap',
      SUFFIX: '_index',
      EXTENSION: '.xml',
    },
  },
  QUERY_PARAM: {
    DEFAULT: {
      LOGIN_TYPE: 'OTP',
      SORT_TYPE: 'Newest',
      QUESTION_MARK: '?',
      ASSIGNMENT: '=',
      AMPERSAND: '&',
      PIPELINE: '|',
      COLON: ':',
      COMMA: ',',
      PLUS: '+',
      HASH: '#',
      DOT: '.',
      PAGE_SIBLING: 0,
      PAGE_NUMBER: 1,
      PAGE_SIZE: 10,
      SITEMAP_SIZE: 5_000,
    },
    ID: {
      BASE_URL: 'id',
    },
    TYPE: {
      BASE_URL: 'type',
    },
    CULTURE: {
      BASE_URL: 'culture',
    },
    STATUSES: {
      BASE_URL: 'statuses',
    },
    SORT_TYPE: {
      BASE_URL: 'sort-type',
    },
    SEARCH_KEY: {
      BASE_URL: 'search-key',
    },
    PAGE_SIZE: {
      BASE_URL: 'page-size',
    },
    PAGE_NUMBER: {
      BASE_URL: 'page-number',
    },
    BRANDS_IDS: {
      BASE_URL: 'brands-ids',
    },
    CATEGORIES_IDS: {
      BASE_URL: 'categories-ids',
    },
    CALL_FOR_PRICE: {
      BASE_URL: 'call-for-price',
    },
  },
} as const;

export { PATHNAME };
