import { Fetch } from 'lib';
import type { ProductSortType, ProductStatus, ProductSummary } from 'types';

type RequestResponses = {
  total: number;
  data: ProductSummary[];
};

type RequestParameters = {
  sortType: ProductSortType;
  searchKey: string;
  categoriesIds?: string[];
  brandsIds?: string[];
  statuses?: ProductStatus[];
  callForPrice?: boolean;
  lowerBoundCreateDate?: string;
  upperBoundCreateDate?: string;
  pageSize: number;
  pageNumber: number;
};

const searchProducts = async ({
  sortType,
  searchKey,
  categoriesIds,
  brandsIds,
  statuses,
  callForPrice,
  lowerBoundCreateDate,
  upperBoundCreateDate,
  pageSize,
  pageNumber,
}: RequestParameters) => {
  const params: Record<string, unknown> = {
    sortType,
    searchKey,
    pageSize,
    pageNumber,
  };

  if (categoriesIds) {
    params['categoriesIds'] = categoriesIds;
  }
  if (brandsIds) {
    params['brandsIds'] = brandsIds;
  }
  if (statuses) {
    params['statuses'] = statuses;
  }
  if (typeof callForPrice !== 'undefined') {
    params['callForPrice'] = callForPrice;
  }
  if (lowerBoundCreateDate) {
    params['lowerBoundCreateDate'] = lowerBoundCreateDate;
  }
  if (upperBoundCreateDate) {
    params['upperBoundCreateDate'] = upperBoundCreateDate;
  }

  return await Fetch<RequestResponses, typeof params>({
    url: 'adminProduct/searchProducts',
    params,
  });
};

export { searchProducts };
