import * as React from 'react';

import { useBoolean } from 'ahooks';
import { Layout, theme } from 'antd';
import { Outlet } from 'react-router-dom';

import { useMediaQuery } from 'hooks';

import { PrivateHeader, PrivateSidebar } from './components';

const PrivateLayout = () => {
  const [defaultCollapsed, { toggle }] = useBoolean(false);
  const { token } = theme.useToken();
  const responsive = useMediaQuery();

  const collapsed = responsive.lg ? defaultCollapsed : true;

  return (
    <Layout>
      <Layout.Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        width='15%'
      >
        <PrivateSidebar collapsed={collapsed} />
      </Layout.Sider>

      <Layout className='min-h-dvh'>
        <Layout.Header className='bg-[unset] pe-6 ps-4'>
          <PrivateHeader collapsed={collapsed} handleCollapse={toggle} />
        </Layout.Header>

        <Layout.Content
          className='p-6'
          style={{
            backgroundColor: token.colorPrimaryBg,
          }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export { PrivateLayout };
