import * as React from 'react';

import { Button, Divider, Form, Modal, notification, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlinedIcon } from 'components';
import { useBrandValue } from 'context';
import { deleteBrand } from 'services';
import { PATHNAME } from 'utils';

const {
  LOCAL: {
    BRANDS: { BASE_URL: BRANDS_BASE_URL },
  },
} = PATHNAME;

const BrandFormAction = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { id = '', defaultTitle, mode } = useBrandValue();
  const navigate = useNavigate();

  const handleDeleteBrand = async (id: string) => {
    deleteBrand({ id })
      .then(() => {
        navigate(BRANDS_BASE_URL);
      })
      .catch(message => {
        notification.error({ message });
      });
  };

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='mb-0 text-base font-medium'>
          انتشار برند
        </Typography.Title>
      </div>

      <Divider style={{ marginBlockStart: 15, marginBlockEnd: 30 }} />

      <div className='px-6'>
        <Typography.Paragraph>
          از طریق این بخش می‌توانید برند ساخته شده و یا ویرایش شده را منتشر
          کنید.
        </Typography.Paragraph>
      </div>

      <div className='p-6'>
        {mode === 'Update' ? (
          <Form.Item className='mb-2.5'>
            <Button
              block
              danger
              type='primary'
              className='flex items-center'
              onClick={async () => {
                const confirmed = await modal.confirm({
                  title: `آیا از حذف برند "${defaultTitle}" اطمینان دارید؟`,
                  width: 500,
                });

                if (confirmed) {
                  handleDeleteBrand(id);
                }
              }}
            >
              <DeleteOutlinedIcon />
              <span className='text-sm'>حذف برند</span>
            </Button>

            {contextHolder}
          </Form.Item>
        ) : null}

        <Form.Item className='mb-0'>
          <Button block type='primary' htmlType='submit'>
            {mode === 'Create' ? 'افزودن برند جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export { BrandFormAction };
