import * as React from 'react';

import { useUpdateEffect } from 'ahooks';
import {
  Badge,
  Button,
  Dropdown,
  Flex,
  Modal,
  notification,
  Space,
  Table,
} from 'antd';

import {
  AntdRouterLink,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import { deletePackagingModel } from 'services';
import type { PackagingModel } from 'types';
import { adjustPageNumber, generateTableDataSource, PATHNAME } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    PACKAGING_MODELS: { UPDATE_URL: PACKAGING_MODELS_UPDATE_URL },
  },
} = PATHNAME;

type PackagingModelsTableProps = {
  loading: boolean;
  total: number;
  packagingModels: PackagingModel[];
  pageSize: number;
  pageNumber: number;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const PackagingModelsTable = React.memo<PackagingModelsTableProps>(
  ({
    loading,
    total,
    packagingModels,
    pageSize,
    pageNumber,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: PackagingModelsTableProps) => {
    const [modal, contextHolder] = Modal.useModal();

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeletePackagingModel = async (id: string) => {
      deletePackagingModel({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const packagingModelsDataSource = generateTableDataSource(
      packagingModels,
      'id',
    );

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: 700, y: 630 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            ellipsis: true,
            render: (_, { id, name }) => (
              <AntdRouterLink
                to={`${PACKAGING_MODELS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
              >
                {name}
              </AntdRouterLink>
            ),
          },
          {
            title: 'دسته‌بندی‌ها',
            dataIndex: 'categories',
            key: 'categories',
            ellipsis: true,
            render: (_, { categories }) => (
              <Space className='flex-wrap'>
                {categories.map(({ id, title }) => (
                  <Badge key={id} count={title} color='blue' />
                ))}
              </Space>
            ),
          },
          {
            title: 'برچسب',
            dataIndex: 'label',
            key: 'label',
            width: 100,
            ellipsis: true,
            render: (_, { label }) => label,
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, name }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-packaging-model',
                        label: (
                          <AntdRouterLink
                            to={`${PACKAGING_MODELS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>
                              ویرایش بسته‌بندی
                            </span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-packaging-model',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='flex items-center'
                              style={{ paddingInline: 0, marginBlock: 2 }}
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف بسته‌بندی "${name}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeletePackagingModel(id);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span className='text-sm'>حذف بسته‌بندی</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                  placement='bottomLeft'
                >
                  <Button size='small' className='py-3.5'>
                    <MoreOutlinedIcon style={{ fontSize: 20 }} />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={packagingModelsDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
PackagingModelsTable.displayName = 'PackagingModelsTable';

export { PackagingModelsTable };
