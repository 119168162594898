import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Button, notification, Typography } from 'antd';

import { useLoginAction, useLoginValue } from 'context';
import { useTimer } from 'hooks';
import { sendSigninOtp } from 'services';
import { createTimestamp, padString } from 'utils';

const ExpirationPinTime = () => {
  const { isRunning, seconds, minutes, restart } = useTimer({
    targetDate: createTimestamp(2.5 * 60 * 1000),
  });
  const { phoneNumber = '' } = useLoginValue();
  const action = useLoginAction();

  const resendSigninOtp = useLockFn(async () => {
    sendSigninOtp({ phoneNumber })
      .then(({ ticket }) => {
        restart(createTimestamp(2.5 * 60 * 1000));
        action({ type: 'UPDATE_LOGIN_TICKET', payload: { ticket } });
      })
      .catch(message => {
        notification.error({ message });
      });
  });

  if (isRunning) {
    return (
      <Typography.Paragraph>
        <span>درخواست مجدد تا </span>
        <time>
          {padString(minutes.toString())}:{padString(seconds.toString())}
        </time>
      </Typography.Paragraph>
    );
  }

  return (
    <Button type='link' className='-mt-2' onClick={resendSigninOtp}>
      درخواست مجدد
    </Button>
  );
};

export { ExpirationPinTime };
