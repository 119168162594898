import type { Brand, ComponentMode } from 'types';

type State = Partial<
  Brand &
    Record<'mode', ComponentMode> &
    Record<'step', 1 | 2 | 3> &
    Record<'bannerStatus', boolean> &
    Record<'iconStatus', boolean> &
    Record<'defaultTitle', string>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_BRAND_TITLE';
      payload: Pick<typeof initialState, 'title'>;
    }
  | {
      type: 'UPDATE_BRAND_BANNER';
      payload: Pick<typeof initialState, 'bannerId' | 'bannerStatus'>;
    }
  | {
      type: 'UPDATE_BRAND_ICON';
      payload: Pick<typeof initialState, 'iconId' | 'iconStatus'>;
    }
  | {
      type: 'UPDATE_BRAND_SLUG';
      payload: Pick<typeof initialState, 'slug'>;
    }
  | {
      type: 'UPDATE_BRAND_DESCRIPTION';
      payload: Pick<typeof initialState, 'description'>;
    }
  | {
      type: 'UPDATE_BRAND_PICTURES';
      payload: Pick<typeof initialState, 'pictures'>;
    }
  | {
      type: 'UPDATE_BRAND_VIDEOS';
      payload: Pick<typeof initialState, 'videos'>;
    }
  | {
      type: 'UPDATE_BRAND_STEP';
      payload: Pick<typeof initialState, 'step'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
