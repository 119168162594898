/* eslint-disable react/prop-types */
import * as React from 'react';

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

import type { FileInfo } from 'types';
import { cn, FILE, validateMediaFile } from 'utils';

const {
  FORMAT: { PICTURES: PICTURES_FORMAT },
} = FILE;

type ImgCropUploadProps<T> = Partial<
  Record<'cropOptions', Partial<React.ComponentPropsWithoutRef<typeof ImgCrop>>>
> &
  React.ComponentPropsWithoutRef<typeof Upload<T>>;

function ImgCropUpload<T = FileInfo>({
  cropOptions = {
    modalTitle: 'ویرایش عکس',
    rotationSlider: true,
    aspectSlider: true,
    showGrid: true,
    minZoom: 0,
    maxZoom: 2,
    quality: 1,
  },
  listType = 'picture-card',
  maxCount = 1,
  className,
  beforeUpload,
  children,
  ...props
}: ImgCropUploadProps<T>) {
  return (
    <ImgCrop {...cropOptions}>
      <Upload<T>
        listType={listType}
        maxCount={maxCount}
        className={cn(
          '[&_.ant-upload-list-item-container]:size-full [&_.ant-upload-list-item-thumbnail]:h-[inherit] [&_.ant-upload-list-item-thumbnail]:rounded-sm [&_.ant-upload-list-item-thumbnail]:py-0.5 [&_.ant-upload-list]:mt-0 [&_.ant-upload-list]:h-[8.5rem] [&_.ant-upload-list]:w-full [&_.ant-upload-select]:size-full',
          className,
        )}
        beforeUpload={
          beforeUpload ??
          (({ type }) =>
            validateMediaFile(type, PICTURES_FORMAT) || Upload.LIST_IGNORE)
        }
        {...props}
      >
        {children}
      </Upload>
    </ImgCrop>
  );
}

export { ImgCropUpload };
