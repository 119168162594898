import { createContext } from 'react';
import type { Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const PurchaseValueContext = createContext<typeof initialState>(initialState);

const PurchaseActionContext = createContext<Dispatch<typeof initialAction>>(
  () => {},
);

export { PurchaseActionContext, PurchaseValueContext };
