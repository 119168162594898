import * as React from 'react';

import useUrlState from '@ahooksjs/use-url-state';
import { useLockFn, useSafeState } from 'ahooks';
import { Button, Form, Input, notification, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useLoginValue } from 'context';
import { signinWithOtp } from 'services';
import { inputRule, PATHNAME } from 'utils';

import { ExpirationPinTime } from './expiration-time';

const {
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
  },
  QUERY_PARAM: {
    TYPE: { BASE_URL: TYPE_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<ReturnType<typeof useLoginValue>, 'otp'>;

const PinForm = () => {
  const [, setUrlState] = useUrlState(
    {
      [TYPE_BASE_URL]: '',
    },
    { navigateMode: 'replace' },
  );
  const [message, setMessage] = useSafeState<string>();
  const [form] = Form.useForm<FieldType>();
  const { ticket = '' } = useLoginValue();
  const navigate = useNavigate();

  const onFinish = useLockFn(async ({ otp = '' }: FieldType) => {
    signinWithOtp({ otp, ticket })
      .then(() => {
        notification.success({
          message: 'با موفقیت وارد شدید.',
        });
        navigate(HOME_BASE_URL);
      })
      .catch(message => {
        setMessage(message);
      });
  });

  return (
    <div className='basis-1/2 space-y-10'>
      <div>
        <Typography.Title level={2} className='text-xl font-bold'>
          کد برای شما ارسال شده است.
        </Typography.Title>

        <Typography.Paragraph className='-mt-1 text-slate-400'>
          لطفا کد ورود خود را در قسمت زیر وارد کنید
        </Typography.Paragraph>
      </div>

      <Form
        labelWrap
        form={form}
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}
      >
        <Form.Item<FieldType>
          name='otp'
          rules={[inputRule.required, inputRule.nonWhitespace, inputRule.pin]}
        >
          <Input.OTP
            style={{
              display: 'flex',
              direction: 'ltr',
            }}
          />
        </Form.Item>

        <Form.Item className='text-center'>
          <ExpirationPinTime />

          <Typography.Paragraph
            type='danger'
            className='-mb-4 -mt-2 px-2 text-center empty:my-2'
          >
            {message}
          </Typography.Paragraph>
        </Form.Item>

        <Form.Item className='mb-4'>
          <Button type='primary' htmlType='submit' block>
            ادامه
          </Button>
        </Form.Item>

        <Form.Item className='mb-0'>
          <Button
            block
            onClick={() => {
              setUrlState({ [TYPE_BASE_URL]: undefined });
            }}
          >
            ورود با رمز ثابت
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { PinForm };
