import * as React from 'react';

import { useLockFn, useMemoizedFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useBrandAction, useBrandValue } from 'context';
import { createBrand, updateBrand } from 'services';
import { replaceExtraSeparator } from 'utils';
import { PATHNAME } from 'utils';

import { BrandFormAction } from './action';
import { BrandFormTabs } from './tabs';

const {
  LOCAL: {
    BRANDS: { BASE_URL: BRANDS_BASE_URL },
  },
} = PATHNAME;

type FieldType = Partial<Record<'seoTitle' | 'seoDescription', string>>;

const BrandForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    title = '',
    slug,
    bannerId,
    description,
    defaultTitle,
    mode,
    step,
  } = useBrandValue();
  const action = useBrandAction();
  const navigate = useNavigate();

  const formatedSlug = replaceExtraSeparator(slug ?? title);

  const handleUpdateBrandBaseInfo = useMemoizedFn(() => {
    action({
      type: 'UPDATE_BRAND_STEP',
      payload: {
        step: 2,
      },
    });
  });

  const handleUpdateBrandSeoInfo = useLockFn(
    async ({
      seoTitle = '',
      seoDescription = '',
    }: Pick<FieldType, 'seoTitle' | 'seoDescription'>) => {
      const params: Parameters<typeof updateBrand>[0] = {
        id,
      };
      const body: Parameters<typeof updateBrand>[1] = {
        slug: formatedSlug,
        title,
        bannerId,
        description,
        seo: {
          title: seoTitle,
          description: seoDescription,
        },
      };

      switch (mode) {
        case 'Create': {
          return createBrand(body)
            .then(() => {
              notification.success({
                message: `برند ${title} با موفقیت ساخته شد.`,
              });
              navigate(BRANDS_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        case 'Update': {
          return updateBrand(params, body)
            .then(() => {
              notification.success({
                message: `برند ${defaultTitle} با موفقیت ویرایش شد.`,
              });
              navigate(BRANDS_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        default:
          break;
      }
    },
  );

  const onFinish = useLockFn(
    async ({ seoTitle, seoDescription }: FieldType) => {
      switch (step) {
        case 1: {
          return handleUpdateBrandBaseInfo();
        }
        case 2: {
          return handleUpdateBrandSeoInfo({
            seoTitle,
            seoDescription,
          });
        }
        default:
          break;
      }
    },
  );

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      autoComplete='off'
      style={{
        marginBlock: 12,
      }}
      fields={[
        {
          name: 'title',
          value: title,
        },
        {
          name: 'slug',
          value: formatedSlug,
        },
      ]}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col xl:items-start' gap='large'>
        <BrandFormTabs />
        <BrandFormAction />
      </Flex>
    </Form>
  );
};

export { BrandForm };
