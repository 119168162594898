import * as React from 'react';

import { Flex, Layout } from 'antd';
import { Helmet } from 'react-helmet-async';

import LoginWallpaper from 'assets/images/login-wallpaper.png';
import { KashilandLogo, LazyLoadImage } from 'components';
import { LoginFormContext } from 'context';

import { LoginForm } from './components';

export default function Login() {
  return (
    <>
      <Helmet>
        <title>ورود یا ثبت‌نام | کاشی لند</title>
      </Helmet>

      <Layout className='h-dvh overflow-hidden'>
        <Layout.Content className='relative'>
          <Flex
            justify='center'
            align='center'
            className='absolute inset-x-0 py-8'
          >
            <KashilandLogo />
          </Flex>

          <Flex align='center' justify='center' className='h-full'>
            <LoginFormContext>
              <LoginForm />
            </LoginFormContext>
          </Flex>
        </Layout.Content>

        <Layout.Sider width='60%' className='max-lg:hidden'>
          <LazyLoadImage
            src={LoginWallpaper}
            className='h-dvh w-full'
            alt='عکس روشویی برای صفحه ورود'
          />
        </Layout.Sider>
      </Layout>
    </>
  );
}
