import * as React from 'react';

import { Button, DatePicker, Flex, Input, Typography } from 'antd';

import { AntdRouterLink, PlusOutlinedIcon } from 'components';
import { PATHNAME } from 'utils';

const {
  LOCAL: {
    PRODUCTS: { CREATE_URL: PRODUCTS_CREATE_URL },
  },
} = PATHNAME;

type ProductsHeaderProps = {
  total: number;
  searchKey: string;
  handleSearchKey: (searchKey: string) => void;
  handleLowerBoundCreateDate: (lowerBoundCreateDate?: string) => void;
  handleUpperBoundCreateDate: (upperBoundCreateDate?: string) => void;
};

const ProductsHeader = ({
  total,
  searchKey,
  handleSearchKey,
  handleLowerBoundCreateDate,
  handleUpperBoundCreateDate,
}: ProductsHeaderProps) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='py-1' style={{ marginBlockEnd: 0 }}>
      <span className='text-base'>محصولات</span>
      <span className='text-sm text-slate-400'> ({total})</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      <Input.Search
        allowClear
        className='basis-full lg:basis-1/2'
        styles={{
          affixWrapper: {
            height: '2.5rem',
          },
        }}
        placeholder='جستجو محصولات'
        defaultValue={searchKey}
        onSearch={value => handleSearchKey(value)}
      />

      <DatePicker.RangePicker
        placement='bottomLeft'
        className='basis-full lg:basis-1/2'
        placeholder={['از تاریخ', 'تا تاریخ']}
        allowEmpty={[false, true]}
        onChange={(_, dateString) => {
          const lowerBoundCreateDate = dateString.at(0);
          const upperBoundCreateDate = dateString.at(1);

          handleLowerBoundCreateDate(lowerBoundCreateDate);
          handleUpperBoundCreateDate(upperBoundCreateDate);
        }}
      />

      <AntdRouterLink to={PRODUCTS_CREATE_URL} className='max-lg:basis-full'>
        <Button
          type='primary'
          className='w-full'
          icon={<PlusOutlinedIcon style={{ fontSize: 12 }} />}
        >
          محصول جدید
        </Button>
      </AntdRouterLink>
    </Flex>
  </Flex>
);

export { ProductsHeader };
