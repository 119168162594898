import { Fetch } from 'lib';

type RequestResponses = {
  success: boolean;
};

const checkAuth = async () =>
  await Fetch<RequestResponses>({
    url: 'identity/checkAuth',
  });

export { checkAuth };
