import { useContext } from 'react';

import { UserActionContext, UserValueContext } from './context';

const useUserValue = () => {
  const context = useContext(UserValueContext);

  if (!context) {
    throw new Error('useUserValue must be used within a <UserFormContext />');
  }

  return context;
};

const useUserAction = () => {
  const context = useContext(UserActionContext);

  if (!context) {
    throw new Error('useUserAction must be used within a <UserFormContext />');
  }

  return context;
};

export { useUserAction, useUserValue };
