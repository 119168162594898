/* eslint-disable react/prop-types */
import * as React from 'react';

import { Checkbox, Divider } from 'antd';

import { cn } from 'utils';

const CheckInputbox = React.forwardRef<
  React.ElementRef<typeof Checkbox>,
  React.ComponentPropsWithoutRef<typeof Checkbox>
>(({ className, children, ...props }, ref) => (
  <Checkbox
    ref={ref}
    className={cn(
      'h-10 w-full items-center rounded border bg-zinc-50 px-3 transition-colors has-[.ant-checkbox-checked]:bg-inherit',
      className,
    )}
    {...props}
  >
    <span className='truncate'>
      <Divider
        type='vertical'
        className='me-3 mt-0.5 h-[38px] bg-[var(--ant-color-border)]'
      />
      <span className='text-zinc-400'>{children}</span>
    </span>
  </Checkbox>
));
CheckInputbox.displayName = 'CheckInputbox';

export { CheckInputbox };
