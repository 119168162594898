import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { usePackagingModelValue } from 'context';
import { createPackagingModel, updatePackagingModel } from 'services';
import { PATHNAME } from 'utils';

import { PackagingModelFormAction } from './action';
import { PackagingModelFormContent } from './content';

const {
  LOCAL: {
    PACKAGING_MODELS: { BASE_URL: PACKAGING_MODELS_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<
  ReturnType<typeof usePackagingModelValue>,
  'name' | 'label'
>;

const PackagingModelForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    categories = [],
    defaultName = '',
    mode,
  } = usePackagingModelValue();
  const navigate = useNavigate();

  const onFinish = useLockFn(async ({ name = '', label = '' }: FieldType) => {
    const params: Parameters<typeof updatePackagingModel>[0] = {
      id,
    };
    const body: Parameters<typeof updatePackagingModel>[1] = {
      name,
      label,
      categoriesIds: categories.map(({ id }) => id),
    };

    switch (mode) {
      case 'Create': {
        return createPackagingModel(body)
          .then(() => {
            notification.success({
              message: `بسته‌بندی ${name} با موفقیت ساخته شد.`,
            });
            navigate(PACKAGING_MODELS_BASE_URL);
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      case 'Update': {
        return updatePackagingModel(params, body)
          .then(() => {
            notification.success({
              message: `بسته‌بندی ${defaultName} با موفقیت ویرایش شد.`,
            });
            navigate(PACKAGING_MODELS_BASE_URL);
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      default:
        break;
    }
  });

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      autoComplete='off'
      style={{
        marginBlock: 12,
      }}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col lg:items-start' gap='large'>
        <PackagingModelFormContent />
        <PackagingModelFormAction />
      </Flex>
    </Form>
  );
};

export { PackagingModelForm };
