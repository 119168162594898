/* eslint-disable react/prop-types */
import * as React from 'react';

import { InputNumber } from 'antd';

import { cn, normalizeToString, PATHNAME } from 'utils';

const {
  QUERY_PARAM: {
    DEFAULT: { COMMA: DEFAULT_COMMA },
  },
} = PATHNAME;

const DecimalInputNumber = React.forwardRef<
  React.ElementRef<typeof InputNumber>,
  React.ComponentPropsWithoutRef<typeof InputNumber>
>(({ className, formatter, parser, ...props }, ref) => (
  <InputNumber
    ref={ref}
    className={cn('w-full', className)}
    formatter={
      formatter ??
      (value =>
        normalizeToString(value).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          DEFAULT_COMMA,
        ))
    }
    parser={
      parser ??
      (displayValue =>
        normalizeToString(displayValue).replace(
          /\$\s?|(,*)/g,
          '',
        ) as unknown as number)
    }
    {...props}
  />
));
DecimalInputNumber.displayName = 'DecimalInputNumber';

export { DecimalInputNumber };
