import { useCountDown, useGetState, useMemoizedFn } from 'ahooks';

import { padString } from 'utils';

const useTimer = ({
  targetDate: defaultTargetDate,
  ...options
}: NonNullable<Parameters<typeof useCountDown>[0]>) => {
  const [targetDate, setTargetDate] = useGetState(defaultTargetDate);
  const [totalSeconds, { milliseconds, seconds, minutes, hours, days }] =
    useCountDown({
      targetDate,
      ...options,
    });

  const restart = useMemoizedFn((date: typeof targetDate) => {
    setTargetDate(date);
  });

  const reset = useMemoizedFn(() => {
    setTargetDate(undefined);
  });

  return {
    isRunning: totalSeconds > 0,
    totalSeconds: padString(totalSeconds.toString()),
    milliseconds: padString(milliseconds.toString()),
    seconds: padString(seconds.toString()),
    minutes: padString(minutes.toString()),
    hours: padString(hours.toString()),
    days: padString(days.toString()),
    restart,
    reset,
  };
};

export { useTimer };
