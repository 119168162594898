import * as React from 'react';

import { LoginActionContext, LoginValueContext } from './context';
import { useLoginAction, useLoginValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const LoginFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <LoginValueContext.Provider value={state}>
      <LoginActionContext.Provider value={dispatch}>
        {children}
      </LoginActionContext.Provider>
    </LoginValueContext.Provider>
  );
};

export { LoginFormContext, useLoginAction, useLoginValue };
