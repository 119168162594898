import { Fetch } from 'lib';
import type { PackagingModel } from 'types';

type RequestResponses = PackagingModel;

type RequestParameters = {};

type RequestBody = Pick<PackagingModel, 'name' | 'label'> &
  Record<'categoriesIds', string[]>;

const createPackagingModel = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminPackagingModel/createPackagingModel',
    method: 'POST',
    body,
  });

export { createPackagingModel };
