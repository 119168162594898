'use client';

import * as React from 'react';

import LogoIcon from 'assets/images/logo-icon.png';
import Logo from 'assets/images/logo.png';
import { AntdRouterLink, LazyLoadImage } from 'components';
import { PATHNAME } from 'utils';

const {
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
  },
} = PATHNAME;

type KashilandLogoProps = Partial<
  Pick<React.ComponentPropsWithoutRef<typeof AntdRouterLink>, 'to'> &
    React.ComponentPropsWithoutRef<typeof LazyLoadImage>
>;

const KashilandLogo = React.forwardRef<
  React.ElementRef<typeof AntdRouterLink>,
  KashilandLogoProps
>(
  (
    {
      to = HOME_BASE_URL,
      src = Logo,
      width = 135,
      height = 35,
      alt = 'کاشی لند',
      ...props
    },
    ref,
  ) => (
    <AntdRouterLink ref={ref} to={to}>
      <LazyLoadImage
        src={src}
        width={width}
        height={height}
        alt={alt}
        {...props}
      />
    </AntdRouterLink>
  ),
);
KashilandLogo.displayName = 'KashilandLogo';

type KashilandLogoIconProps = Partial<
  Pick<React.ComponentPropsWithoutRef<typeof AntdRouterLink>, 'to'> &
    React.ComponentPropsWithoutRef<typeof LazyLoadImage>
>;

const KashilandLogoIcon = React.forwardRef<
  React.ElementRef<typeof AntdRouterLink>,
  KashilandLogoIconProps
>(
  (
    {
      to = HOME_BASE_URL,
      src = LogoIcon,
      width = 40,
      height = 15,
      alt = 'کاشی لند',
      ...props
    },
    ref,
  ) => (
    <AntdRouterLink ref={ref} to={to}>
      <LazyLoadImage
        src={src}
        width={width}
        height={height}
        alt={alt}
        {...props}
      />
    </AntdRouterLink>
  ),
);
KashilandLogoIcon.displayName = 'KashilandLogoIcon';

export { KashilandLogo, KashilandLogoIcon };
