import { clsx } from 'clsx';
import type { ClassValue as ClassNameValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { v4 as uuId } from 'uuid';
import type { V4Options as UUIdOptions } from 'uuid';

import { PATHNAME } from 'utils';

const {
  QUERY_PARAM: {
    DEFAULT: { PAGE_NUMBER: DEFAULT_PAGE_NUMBER },
  },
} = PATHNAME;

const cn = (...inputs: ClassNameValue[]) => twMerge(clsx(inputs));

const randomId = (options?: UUIdOptions) => uuId(options);

const createTimestamp = (ms: number) => Date.now() + ms;

const normalizeToString = (input: any = ''): string => input.toString();

const getObjectEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Array<[keyof T, T[keyof T]]>;

const validateMediaFile = (type: string, formats: readonly string[]) =>
  formats.some(format => type.toLowerCase().endsWith(format));

const parseFromContent = (
  content: string,
  type: DOMParserSupportedType = 'text/html',
) => new DOMParser().parseFromString(content, type).body.innerHTML;

const createRange = (start: number, end: number) =>
  Array.from(
    { length: end - start + DEFAULT_PAGE_NUMBER },
    (_, idx) => idx + start,
  );

const padString = (
  value: string,
  type: 'padStart' | 'padEnd' = 'padStart',
  maxLength = 2,
  fillString = '0',
) => value[type](maxLength, fillString);

const replaceExtraSeparator = (
  value: string,
  type: 'space' | 'dash' = 'space',
  replaceValue = '-',
) => value.trim().replace(type === 'space' ? /\s+/g : /[-]+/g, replaceValue);

const adjustPageNumber = (
  total: number,
  size: number,
  page: number,
  updatePage: (page: number) => void,
) => {
  const totalPage = Math.ceil(total / size);
  if (page > totalPage && totalPage >= DEFAULT_PAGE_NUMBER) {
    updatePage(totalPage);
  }
};

export {
  adjustPageNumber,
  cn,
  createRange,
  createTimestamp,
  getObjectEntries,
  normalizeToString,
  padString,
  parseFromContent,
  randomId,
  replaceExtraSeparator,
  validateMediaFile,
};
