import { useMemoizedFn, useSafeState } from 'ahooks';

const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useSafeState<string | null>(null);

  const copyToClipboard = useMemoizedFn(async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn('کلیپبورد پشتیبانی نمی شود.');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('خطا در کپی گرفتن: ', error);
      setCopiedText(null);
      return false;
    }
  });

  return { copiedText, copyToClipboard };
};

export { useCopyToClipboard };
