import * as React from 'react';

import { createBrowserRouter, Navigate } from 'react-router-dom';

import { PrivateLayout } from 'layouts';
import {
  Attribute,
  Attributes,
  Brand,
  Brands,
  Categories,
  Category,
  Home,
  Login,
  NotFound,
  PackagingModel,
  PackagingModels,
  Product,
  ProductComment,
  Products,
  ProductsComments,
} from 'pages';
import {
  checkAuth,
  getAttributeConfig,
  getBrand,
  getCategory,
  getPackagingModel,
  getProduct,
  getProductComment,
} from 'services';
import { PATHNAME } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
    PRODUCTS: {
      BASE_URL: PRODUCTS_BASE_URL,
      CREATE_URL: PRODUCTS_CREATE_URL,
      UPDATE_URL: PRODUCTS_UPDATE_URL,
    },
    CATEGORIES: {
      BASE_URL: CATEGORIES_BASE_URL,
      CREATE_URL: CATEGORIES_CREATE_URL,
      UPDATE_URL: CATEGORIES_UPDATE_URL,
    },
    BRANDS: {
      BASE_URL: BRANDS_BASE_URL,
      CREATE_URL: BRANDS_CREATE_URL,
      UPDATE_URL: BRANDS_UPDATE_URL,
    },
    COMMENTS_MANAGEMENT: {
      BASE_URL: COMMENTS_MANAGEMENT_BASE_URL,
      PRODUCTS_COMMENTS: {
        BASE_URL: PRODUCTS_COMMENTS_BASE_URL,
        CREATE_URL: PRODUCTS_COMMENTS_CREATE_URL,
        UPDATE_URL: PRODUCTS_COMMENTS_UPDATE_URL,
      },
    },
    ATTRIBUTES: {
      BASE_URL: ATTRIBUTES_BASE_URL,
      CREATE_URL: ATTRIBUTES_CREATE_URL,
      UPDATE_URL: ATTRIBUTES_UPDATE_URL,
    },
    PACKAGING_MODELS: {
      BASE_URL: PACKAGING_MODELS_BASE_URL,
      CREATE_URL: PACKAGING_MODELS_CREATE_URL,
      UPDATE_URL: PACKAGING_MODELS_UPDATE_URL,
    },
    LOGIN: { BASE_URL: LOGIN_BASE_URL },
  },
  QUERY_PARAM: {
    DEFAULT: { COLON: DEFAULT_COLON },
    ID: { BASE_URL: ID_BASE_URL },
  },
} = PATHNAME;

const router = createBrowserRouter([
  // NOTE: If the user was authenticated, then user can access the private layout routes, otherwise user will be redirected to the login page.
  {
    path: HOME_BASE_URL,
    loader: async () => checkAuth(),
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: PRODUCTS_BASE_URL,
        children: [
          {
            index: true,
            element: <Products />,
          },
          {
            path: PRODUCTS_CREATE_URL,
            element: <Product mode='Create' />,
          },
          {
            path: `${PRODUCTS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${DEFAULT_COLON}${ID_BASE_URL}`,
            loader: async ({ params }) =>
              await getProduct({
                id: params.id as string,
              }),
            element: <Product mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: CATEGORIES_BASE_URL,
        children: [
          {
            index: true,
            element: <Categories />,
          },
          {
            path: CATEGORIES_CREATE_URL,
            element: <Category mode='Create' />,
          },
          {
            path: `${CATEGORIES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${DEFAULT_COLON}${ID_BASE_URL}`,
            loader: async ({ params }) =>
              await getCategory({
                id: params.id as string,
              }),
            element: <Category mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: BRANDS_BASE_URL,
        children: [
          {
            index: true,
            element: <Brands />,
          },
          {
            path: BRANDS_CREATE_URL,
            element: <Brand mode='Create' />,
          },
          {
            path: `${BRANDS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${DEFAULT_COLON}${ID_BASE_URL}`,
            loader: async ({ params }) =>
              await getBrand({
                id: params.id as string,
              }),
            element: <Brand mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: COMMENTS_MANAGEMENT_BASE_URL,
        children: [
          {
            path: PRODUCTS_COMMENTS_BASE_URL,
            children: [
              {
                index: true,
                element: <ProductsComments />,
              },
              {
                path: PRODUCTS_COMMENTS_CREATE_URL,
                element: <ProductComment mode='Create' />,
              },
              {
                path: `${PRODUCTS_COMMENTS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${DEFAULT_COLON}${ID_BASE_URL}`,
                loader: async ({ params }) =>
                  await getProductComment({
                    id: params.id as string,
                  }),
                element: <ProductComment mode='Update' />,
                errorElement: <NotFound />,
              },
            ],
          },
        ],
      },
      {
        path: ATTRIBUTES_BASE_URL,
        children: [
          {
            index: true,
            element: <Attributes />,
          },
          {
            path: ATTRIBUTES_CREATE_URL,
            element: <Attribute mode='Create' />,
          },
          {
            path: `${ATTRIBUTES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${DEFAULT_COLON}${ID_BASE_URL}`,
            loader: async ({ params }) =>
              await getAttributeConfig({
                id: params.id as string,
              }),
            element: <Attribute mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: PACKAGING_MODELS_BASE_URL,
        children: [
          {
            index: true,
            element: <PackagingModels />,
          },
          {
            path: PACKAGING_MODELS_CREATE_URL,
            element: <PackagingModel mode='Create' />,
          },
          {
            path: `${PACKAGING_MODELS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${DEFAULT_COLON}${ID_BASE_URL}`,
            loader: async ({ params }) =>
              await getPackagingModel({
                id: params.id as string,
              }),
            element: <PackagingModel mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
    errorElement: <Navigate to={LOGIN_BASE_URL} />,
  },
  // NOTE: If the user is not authenticated, the login page will be displayed to user, otherwise user will be redirected to the main page
  {
    path: LOGIN_BASE_URL,
    loader: async () => checkAuth(),
    element: <Navigate to={HOME_BASE_URL} />,
    errorElement: <Login />,
  },
]);

export { router };
