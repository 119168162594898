import * as React from 'react';

import { Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  AntdRouterExternalLink,
  EyeOutlinedIcon,
  RightOutlinedIcon,
} from 'components';
import { PATHNAME } from 'utils';

const {
  DEFAULT: {
    BASE_URL: DEFAULT_BASE_URL,
    SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR,
  },
  LOCAL: {
    PRODUCT: { BASE_URL: PRODUCT_BASE_URL },
  },
} = PATHNAME;

type ProductHeaderProps = {
  slug?: string;
};

const ProductHeader = ({
  slug,
  children,
}: React.PropsWithChildren<ProductHeaderProps>) => {
  const navigate = useNavigate();

  return (
    <Space size={2}>
      <Button
        type='text'
        size='small'
        icon={
          <>
            <RightOutlinedIcon className='stroke-2 text-[0.625rem]' />
            <span className='sr-only'>آیکون برگشت</span>
          </>
        }
        onClick={() => navigate(-1)}
      />

      <Typography.Text className='font-semibold'>{children}</Typography.Text>

      {slug ? (
        <AntdRouterExternalLink
          href={`${DEFAULT_BASE_URL}${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
          className='ms-2 text-inherit'
        >
          <EyeOutlinedIcon className='text-xs' />
          <span className='sr-only'>مشاهده در فروشگاه</span>
        </AntdRouterExternalLink>
      ) : null}
    </Space>
  );
};

export { ProductHeader };
