/* eslint-disable react/prop-types */
import * as React from 'react';

import {
  useBoolean,
  useLockFn,
  useMemoizedFn,
  useMount,
  useRequest,
} from 'ahooks';
import {
  Button,
  Col,
  Divider,
  Empty,
  Flex,
  Form,
  List,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';

import {
  CopyOutlinedIcon,
  DecimalInputNumber,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MenuOutlinedIcon,
  PlusOutlinedIcon,
} from 'components';
import { useProductAction, useProductValue } from 'context';
import { DND_CSS, dndArrayMove, DndSortableContext, useDndSortable } from 'lib';
import { getCategory, getMeasurementUnits } from 'services';
import type { ProductPackaging, SelectOption } from 'types';
import {
  generateSelectOptions,
  inputRule,
  normalizeToString,
  randomId,
} from 'utils';

type ModalFieldType = Pick<ProductPackaging, 'amount'> &
  Record<'packagingModel' | 'measurementUnit', SelectOption>;

type ProductPackagingInfoItemProps = ProductPackaging &
  Record<'openModal', () => void>;

const ProductPackagingInfoItem = React.memo<ProductPackagingInfoItemProps>(
  ({ identifier, packagingModel, measurementUnit, amount, openModal }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useDndSortable({
      id: identifier,
    });
    const { packagingInfo = [] } = useProductValue();
    const action = useProductAction();

    const defaultButtonProps: React.ComponentPropsWithoutRef<typeof Button> = {
      type: 'text',
      size: 'small',
    };

    return (
      <List.Item
        ref={setNodeRef}
        className='my-2 rounded border'
        actions={[
          <Tooltip key='delete-packaging-models' title='حذف بسته‌بندی'>
            <Button
              icon={<DeleteOutlinedIcon />}
              onClick={() => {
                const updatedPackagingInfo = packagingInfo.filter(
                  packaging => packaging.identifier !== identifier,
                );

                action({
                  type: 'UPDATE_PRODUCT_PACKAGING_INFO',
                  payload: {
                    packagingInfo: updatedPackagingInfo,
                    selectedPackage: undefined,
                  },
                });
              }}
              {...defaultButtonProps}
            />
          </Tooltip>,
          <Tooltip key='copy-packaging-models' title='کپی بسته‌بندی'>
            <Button
              icon={<CopyOutlinedIcon />}
              onClick={() => {
                const duplicatedPackagingInfo = packagingInfo.find(
                  packaging => packaging.identifier === identifier,
                );

                if (duplicatedPackagingInfo) {
                  action({
                    type: 'UPDATE_PRODUCT_PACKAGING_INFO',
                    payload: {
                      packagingInfo: [
                        ...packagingInfo,
                        {
                          ...duplicatedPackagingInfo,
                          identifier: randomId(),
                        },
                      ],
                      selectedPackage: undefined,
                    },
                  });
                }
              }}
              {...defaultButtonProps}
            />
          </Tooltip>,
          <Tooltip key='edit-packaging-models' title='ویرایش بسته‌بندی'>
            <Button
              icon={<EditOutlinedIcon />}
              onClick={() => {
                const selectedPackage = packagingInfo.find(
                  packaging => packaging.identifier === identifier,
                );

                if (selectedPackage) {
                  action({
                    type: 'UPDATE_PRODUCT_PACKAGING_INFO',
                    payload: {
                      packagingInfo,
                      selectedPackage,
                    },
                  });
                }
                openModal();
              }}
              {...defaultButtonProps}
            />
          </Tooltip>,
        ]}
        style={{
          transform: DND_CSS.Transform.toString(transform),
          transition,
          ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
        }}
      >
        <List.Item.Meta
          className='items-center'
          avatar={<MenuOutlinedIcon {...attributes} {...listeners} />}
          title={
            <Typography.Text ellipsis style={{ maxWidth: '30vw' }}>
              <span className='text-slate-400'>{packagingModel.name}: </span>
              <span>{`${amount} ${measurementUnit.name}`}</span>
            </Typography.Text>
          }
        />
      </List.Item>
    );
  },
);
ProductPackagingInfoItem.displayName = 'ProductPackagingInfoItem';

type ProductPackagingInfoModalProps = {
  open: boolean;
  closeModal: () => void;
};

const ProductPackagingInfoModal = React.memo<ProductPackagingInfoModalProps>(
  ({ open, closeModal }) => {
    const [form] = Form.useForm<ModalFieldType>();
    const {
      mainCategory,
      selectedPackage,
      packagingInfo = [],
    } = useProductValue();
    const action = useProductAction();
    const {
      data: categoryData,
      loading: categoryLoading,
      run,
    } = useRequest(getCategory, {
      manual: true,
    });
    const { data: measurementUnitsData, loading: measurementUnitsLoading } =
      useRequest(getMeasurementUnits, {
        cacheKey: 'measurementUnits',
      });

    useMount(() => {
      if (mainCategory) {
        run({ id: mainCategory.id });
      }
    });

    const onCancel = useMemoizedFn(async () => {
      action({
        type: 'UPDATE_PRODUCT_PACKAGING_INFO',
        payload: {
          packagingInfo,
          selectedPackage: undefined,
        },
      });
      form.resetFields();
      closeModal();
    });

    const onFinish = useLockFn(
      async ({ packagingModel, amount, measurementUnit }: ModalFieldType) => {
        const defaultPackagingInfo = selectedPackage
          ? packagingInfo.filter(
              packaging => packaging.identifier !== selectedPackage.identifier,
            )
          : packagingInfo;
        const newPackagingInfo: NonNullable<typeof selectedPackage> = {
          identifier: randomId(),
          packagingModel: {
            id: normalizeToString(packagingModel.value),
            name: normalizeToString(packagingModel.label),
            label: normalizeToString(packagingModel.label),
            categories: [],
          },
          measurementUnit: {
            id: normalizeToString(measurementUnit.value),
            name: normalizeToString(measurementUnit.label),
            dataType: 'Integer',
          },
          amount,
        };

        action({
          type: 'UPDATE_PRODUCT_PACKAGING_INFO',
          payload: {
            packagingInfo: [newPackagingInfo, ...defaultPackagingInfo],
            selectedPackage: undefined,
          },
        });
        form.resetFields();
        closeModal();
      },
    );

    const title =
      selectedPackage?.packagingModel.name ?? 'افزودن بسته‌بندی جدید';

    const totalPackagingModels = categoryData?.packagingModels ?? [];
    const totalMeasurementUnits = measurementUnitsData?.measurementUnits ?? [];

    const packagingModelSelectOptions = generateSelectOptions(
      totalPackagingModels,
      'id',
      'name',
      'id',
    );
    const measurementUnitSelectOptions = generateSelectOptions(
      totalMeasurementUnits,
      'id',
      'name',
      'id',
    );

    return (
      <Modal
        centered
        destroyOnClose
        width={800}
        title={
          <Typography.Title ellipsis level={5} className='max-w-xl font-medium'>
            {title}
          </Typography.Title>
        }
        okText={selectedPackage ? 'ذخیره تغییرات' : 'افزودن بسته‌بندی'}
        okButtonProps={{
          className: 'px-5',
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        classNames={{
          content: 'p-0',
          header: 'px-5 pt-4',
          footer: 'px-5 pb-3',
        }}
        open={open}
        onOk={form.submit}
        onCancel={onCancel}
      >
        <Divider style={{ marginBlockStart: 15 }} />

        <Form
          form={form}
          layout='vertical'
          requiredMark={false}
          autoComplete='off'
          style={{
            marginBlock: 12,
            maxHeight: 'calc(100dvh - 15rem)',
            overflowY: 'auto',
          }}
          fields={
            selectedPackage && [
              {
                name: 'packagingModel',
                value: {
                  key: selectedPackage.packagingModel.id,
                  label: selectedPackage.packagingModel.name,
                  value: selectedPackage.packagingModel.id,
                },
              },
              {
                name: 'measurementUnit',
                value: {
                  key: selectedPackage.measurementUnit?.id,
                  label: selectedPackage.measurementUnit?.name,
                  value: selectedPackage.measurementUnit?.id,
                },
              },
              {
                name: 'amount',
                value: selectedPackage.amount,
              },
            ]
          }
          onFinish={onFinish}
        >
          <Row className='px-6'>
            <Col span={24} lg={8} className='lg:pe-1.5'>
              <Form.Item<ModalFieldType>
                label='نام'
                name='packagingModel'
                rules={[inputRule.required]}
              >
                <Select
                  labelInValue
                  showSearch
                  maxTagCount='responsive'
                  style={{
                    color: 'var(--ant-color-primary-active)',
                  }}
                  placeholder='نام بسته‌بندی را انتخاب کنید'
                  loading={categoryLoading}
                  options={packagingModelSelectOptions}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24} lg={8} className='lg:px-1.5'>
              <Form.Item<ModalFieldType>
                label='مقدار واحد'
                name='amount'
                rules={[inputRule.required, inputRule.digit]}
              >
                <DecimalInputNumber placeholder='مقدار واحد خود را وارد کنید' />
              </Form.Item>
            </Col>

            <Col span={24} lg={8} className='lg:ps-1.5'>
              <Form.Item<ModalFieldType>
                name='measurementUnit'
                label='واحدهای اختصاص یافته'
                rules={[inputRule.required]}
              >
                <Select
                  labelInValue
                  showSearch
                  maxTagCount='responsive'
                  style={{
                    color: 'var(--ant-color-primary-active)',
                  }}
                  placeholder='واحدهای خود را انتخاب کنید'
                  loading={measurementUnitsLoading}
                  options={measurementUnitSelectOptions}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider style={{ marginBlockEnd: 6 }} />
      </Modal>
    );
  },
);
ProductPackagingInfoModal.displayName = 'ProductPackagingInfoModal';

const ProductPackagingInfo = () => {
  const [open, { set: setOpen }] = useBoolean(false);
  const { packagingInfo = [] } = useProductValue();
  const action = useProductAction();

  return (
    <Row>
      <Col span={24}>
        <Flex align='center' justify='space-between'>
          <Typography.Title level={3} className='text-base'>
            بسته‌بندی‌ها
          </Typography.Title>

          <Button
            icon={<PlusOutlinedIcon style={{ fontSize: 12 }} />}
            onClick={() => setOpen(true)}
          >
            بسته‌بندی جدید
          </Button>

          <ProductPackagingInfoModal
            open={open}
            closeModal={() => setOpen(false)}
          />
        </Flex>

        <div className='my-4'>
          {packagingInfo.length > 0 ? (
            <DndSortableContext
              dnd={{
                onDragEnd: ({ active, over }) => {
                  if (active.id !== over?.id) {
                    const activeIndex = packagingInfo.findIndex(
                      ({ identifier }) => identifier === active.id,
                    );
                    const overIndex = packagingInfo.findIndex(
                      ({ identifier }) => identifier === over?.id,
                    );
                    const sortedPackagingInfo = dndArrayMove(
                      packagingInfo,
                      activeIndex,
                      overIndex,
                    );

                    action({
                      type: 'UPDATE_PRODUCT_PACKAGING_INFO',
                      payload: {
                        packagingInfo: sortedPackagingInfo,
                        selectedPackage: undefined,
                      },
                    });
                  }
                },
              }}
              sortable={{
                items: packagingInfo.map(({ identifier = '' }) => identifier),
                children: (
                  <List
                    size='large'
                    className='[&_li]:border-solid [&_li]:border-[inherit]'
                    dataSource={packagingInfo}
                    renderItem={item => (
                      <ProductPackagingInfoItem
                        openModal={() => setOpen(true)}
                        {...item}
                      />
                    )}
                  />
                ),
              }}
            />
          ) : (
            <Empty
              className='my-20'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span className='text-zinc-300'>
                  هنوز بسته‌بندی ساخته نشده است.
                </span>
              }
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export {
  ProductPackagingInfo,
  ProductPackagingInfoItem,
  ProductPackagingInfoModal,
};
