import * as React from 'react';

import {
  Badge,
  Button,
  Divider,
  Form,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlinedIcon } from 'components';
import { useCategoryValue } from 'context';
import { deleteCategory } from 'services';
import type { CategoryStatus } from 'types';
import { localizeCategoryStatus, PATHNAME } from 'utils';

const {
  LOCAL: {
    CATEGORIES: { BASE_URL: CATEGORIES_BASE_URL },
  },
} = PATHNAME;

const categoryStatuses = ['Active', 'DeActive'] satisfies CategoryStatus[];

type FieldType = Pick<ReturnType<typeof useCategoryValue>, 'status'>;

const CategoryFormAction = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { id = '', defaultTitle, defaultStatus, mode } = useCategoryValue();
  const navigate = useNavigate();

  const handleDeleteCategory = async (id: string) => {
    deleteCategory({ id })
      .then(() => {
        navigate(CATEGORIES_BASE_URL);
      })
      .catch(message => {
        notification.error({ message });
      });
  };

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='mb-0 text-base font-medium'>
          وضعیت
        </Typography.Title>
      </div>

      <Divider style={{ marginBlockStart: 15, marginBlockEnd: 30 }} />

      <div className='px-6'>
        <Typography.Paragraph>
          <Badge
            text={`وضعیت فعلی: ${localizeCategoryStatus(defaultStatus).label}`}
            color={localizeCategoryStatus(defaultStatus).color}
          />
        </Typography.Paragraph>

        <Form.Item<FieldType> label='وضعیت جدید' name='status' className='mb-2'>
          <Select
            placeholder='نوع وضعیت را انتخاب کنید'
            options={categoryStatuses.map(type => ({
              label: localizeCategoryStatus(type).label,
              value: type,
            }))}
          />
        </Form.Item>
      </div>

      <div className='p-6'>
        {mode === 'Update' ? (
          <Form.Item className='mb-2.5'>
            <Button
              block
              danger
              type='primary'
              className='flex items-center'
              onClick={async () => {
                const confirmed = await modal.confirm({
                  title: `آیا از حذف دسته‌بندی‌ "${defaultTitle}" اطمینان دارید؟`,
                  width: 500,
                });

                if (confirmed) {
                  handleDeleteCategory(id);
                }
              }}
            >
              <DeleteOutlinedIcon />
              <span className='text-sm'>حذف دسته‌بندی‌</span>
            </Button>

            {contextHolder}
          </Form.Item>
        ) : null}

        <Form.Item className='mb-0'>
          <Button block type='primary' htmlType='submit'>
            {mode === 'Create' ? 'افزودن دسته‌بندی‌ جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export { CategoryFormAction };
