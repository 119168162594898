import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useUserValue } from 'context';
import { createUser, updateUser } from 'services';
import { PATHNAME, replaceExtraSeparator } from 'utils';

import { UserFormAction } from './action';
import { UserFormContent } from './content';

const {
  LOCAL: {
    USERS: { BASE_URL: USERS_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<
  ReturnType<typeof useUserValue>,
  'lastName' | 'userName' | 'phoneNumber' | 'telNumber' | 'email' | 'roleName'
>;

const UserForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    firstName = '',
    displayName,
    avatarId,
    defaultName,
    defaultRoleName,
    mode,
  } = useUserValue();
  const navigate = useNavigate();

  const formatedDisplayName = replaceExtraSeparator(
    displayName ?? firstName,
    'space',
    ' ',
  );

  const onFinish = useLockFn(
    async ({
      lastName,
      userName,
      phoneNumber,
      telNumber,
      email,
      roleName = defaultRoleName!,
    }: FieldType) => {
      const params: Parameters<typeof updateUser>[0] = {
        id,
      };
      const body: Parameters<typeof updateUser>[1] = {
        firstName,
        lastName,
        displayName: formatedDisplayName,
        userName,
        phoneNumber,
        telNumber,
        email,
        avatarId,
        roleName,
      };

      switch (mode) {
        case 'Create': {
          return createUser(body)
            .then(() => {
              notification.success({
                message: `کاربر ${firstName} با موفقیت ساخته شد.`,
              });
              navigate(USERS_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        case 'Update': {
          return updateUser(params, body)
            .then(() => {
              notification.success({
                message: `کاربر ${defaultName} با موفقیت ویرایش شد.`,
              });
              navigate(USERS_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        default:
          break;
      }
    },
  );

  return (
    <Form
      labelWrap
      form={form}
      layout='vertical'
      autoComplete='off'
      className='my-3'
      fields={[
        {
          name: 'firstName',
          value: firstName,
        },
        {
          name: 'displayName',
          value: formatedDisplayName,
        },
      ]}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col lg:items-start' gap='large'>
        <UserFormContent />
        <UserFormAction />
      </Flex>
    </Form>
  );
};

export { UserForm };
