import { initialAction, initialState } from './initial';

type Reducer = (
  state: typeof initialState,
  action: typeof initialAction,
) => typeof initialState;

const reducer: Reducer = (state, action) => {
  switch (action?.type) {
    case 'UPDATE_BRAND_TITLE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_BRAND_BANNER': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_BRAND_ICON': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_BRAND_SLUG': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_BRAND_DESCRIPTION': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_BRAND_PICTURES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_BRAND_VIDEOS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_BRAND_STEP': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer };
