import { Fetch } from 'lib';
import type { IdentityPayload } from 'types';

type RequestResponses = {};

type RequestParameters = {};

type RequestBody = Pick<IdentityPayload, 'password' | 'newPassword'>;

const changePassword = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/changePassword',
    method: 'PUT',
    body,
  });

export { changePassword };
