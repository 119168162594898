import * as React from 'react';

import useUrlState from '@ahooksjs/use-url-state';
import { useLockFn, useSafeState } from 'ahooks';
import { Button, Form, Input, Typography } from 'antd';

import { MobileOutlinedIcon } from 'components';
import { useLoginAction, useLoginValue } from 'context';
import { sendSigninOtp } from 'services';
import { inputRule, PATHNAME } from 'utils';

const {
  QUERY_PARAM: {
    TYPE: { BASE_URL: TYPE_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<ReturnType<typeof useLoginValue>, 'phoneNumber'>;

const PhoneNumberForm = () => {
  const [, setUrlState] = useUrlState(
    {
      [TYPE_BASE_URL]: '',
    },
    { navigateMode: 'replace' },
  );
  const [message, setMessage] = useSafeState<string>();
  const [form] = Form.useForm<FieldType>();
  const action = useLoginAction();

  const onFinish = useLockFn(async ({ phoneNumber = '' }: FieldType) => {
    sendSigninOtp({ phoneNumber })
      .then(({ ticket }) => {
        action({ type: 'UPDATE_LOGIN_PHONE_NUMBER', payload: { phoneNumber } });
        action({ type: 'UPDATE_LOGIN_TICKET', payload: { ticket } });
      })
      .catch(message => {
        setMessage(message);
      });
  });

  return (
    <div className='basis-1/2 space-y-8'>
      <Typography.Title level={2} className='text-xl font-bold'>
        ورود با رمز یک‌بار مصرف
      </Typography.Title>

      <Form
        labelWrap
        form={form}
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}
      >
        <Form.Item<FieldType>
          label='شماره همراه'
          name='phoneNumber'
          rules={[inputRule.required, inputRule.phoneNumber]}
        >
          <Input
            placeholder='شماره همراه را وارد کنید'
            prefix={
              <MobileOutlinedIcon className='text-lg text-[var(--ant-color-text-placeholder)]' />
            }
          />
        </Form.Item>

        <Typography.Paragraph
          type='danger'
          className='-mt-4 mb-2 px-2 empty:my-12'
        >
          {message}
        </Typography.Paragraph>

        <Form.Item className='mb-4'>
          <Button type='primary' htmlType='submit' block>
            ادامه
          </Button>
        </Form.Item>

        <Form.Item className='mb-0'>
          <Button
            block
            onClick={() => {
              setUrlState({ [TYPE_BASE_URL]: undefined });
            }}
          >
            ورود با رمز ثابت
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { PhoneNumberForm };
