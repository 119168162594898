import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { ProductFormContext } from 'context';
import type { ComponentMode, Product as ProductConfig } from 'types';

import { ProductForm, ProductHeader } from './components';

type ProductProps = {
  mode: ComponentMode;
};

export default function Product({ mode }: ProductProps) {
  const data = useLoaderData() as ProductConfig;

  const updateMode = mode === 'Update';
  const title = updateMode ? data.name : 'افزودن محصول جدید';
  const defaultName = updateMode ? data.name : '';
  const defaultStatus: typeof data.status = updateMode
    ? data.status
    : 'Submitted';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <ProductHeader>{title}</ProductHeader>

      <ProductFormContext
        mode={mode}
        step={1}
        defaultName={defaultName}
        defaultStatus={defaultStatus}
        {...(updateMode && data)}
      >
        <ProductForm />
      </ProductFormContext>
    </>
  );
}
