import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAttributeValue } from 'context';
import { createAttributeConfig, updateAttributeConfig } from 'services';
import { PATHNAME } from 'utils';

import { AttributeFormAction } from './action';
import { AttributeFormContent } from './content';

const {
  LOCAL: {
    ATTRIBUTES: { BASE_URL: ATTRIBUTES_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<
  ReturnType<typeof useAttributeValue>,
  'name' | 'isFilter' | 'type'
>;

const AttributeForm = () => {
  const [form] = Form.useForm<FieldType>();
  const { id = '', categories = [], defaultName, mode } = useAttributeValue();
  const navigate = useNavigate();

  const onFinish = useLockFn(
    async ({ name = '', isFilter = false, type }: FieldType) => {
      const params: Parameters<typeof updateAttributeConfig>[0] = {
        id,
      };
      const body: Parameters<typeof updateAttributeConfig>[1] = {
        name,
        isFilter,
        type: type!,
        categories: categories.map(({ id, values }) => ({
          id,
          values,
        })),
      };

      switch (mode) {
        case 'Create': {
          return createAttributeConfig(body)
            .then(() => {
              notification.success({
                message: `فیلد ${name} با موفقیت ساخته شد.`,
              });
              navigate(ATTRIBUTES_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        case 'Update': {
          return updateAttributeConfig(params, body)
            .then(() => {
              notification.success({
                message: `فیلد ${defaultName} با موفقیت ویرایش شد.`,
              });
              navigate(ATTRIBUTES_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        default:
          break;
      }
    },
  );

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      autoComplete='off'
      style={{
        marginBlock: 12,
      }}
      fields={[
        {
          name: 'categories',
          value: categories.map(({ id, title }) => ({
            key: id,
            label: title,
            value: id,
          })),
        },
      ]}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col lg:items-start' gap='large'>
        <AttributeFormContent />
        <AttributeFormAction />
      </Flex>
    </Form>
  );
};

export { AttributeForm };
