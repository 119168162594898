import * as React from 'react';

import { DatePicker, Flex, Input, Typography } from 'antd';

type ProductsCommentsHeaderProps = {
  total: number;
  searchKey: string;
  handleSearchKey: (searchKey: string) => void;
  handleLowerBoundCreateDate: (lowerBoundCreateDate?: string) => void;
  handleUpperBoundCreateDate: (upperBoundCreateDate?: string) => void;
};

const ProductsCommentsHeader = ({
  total,
  searchKey,
  handleSearchKey,
  handleLowerBoundCreateDate,
  handleUpperBoundCreateDate,
}: ProductsCommentsHeaderProps) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='mb-0 py-1'>
      <span className='text-base'>دیدگاه کاربرها</span>
      <span className='text-sm text-slate-400'> ({total})</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      <Input.Search
        allowClear
        className='basis-full lg:basis-1/2'
        styles={{
          affixWrapper: {
            height: '2.5rem',
          },
        }}
        placeholder='جستجو دیدگاه کاربرها'
        defaultValue={searchKey}
        onSearch={value => handleSearchKey(value)}
      />

      <DatePicker.RangePicker
        placement='bottomLeft'
        className='basis-full lg:basis-1/2'
        placeholder={['از تاریخ', 'تا تاریخ']}
        allowEmpty={[false, true]}
        onChange={(_, dateString) => {
          const lowerBoundCreateDate = dateString.at(0);
          const upperBoundCreateDate = dateString.at(1);

          handleLowerBoundCreateDate(lowerBoundCreateDate);
          handleUpperBoundCreateDate(upperBoundCreateDate);
        }}
      />
    </Flex>
  </Flex>
);

export { ProductsCommentsHeader };
