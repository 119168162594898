import * as React from 'react';

import { Tabs } from 'antd';

import { useCategoryAction, useCategoryValue } from 'context';

import { CategoryBaseInfo } from './base-info';
import { CategorySeoInfo } from './seo-info';

const CategoryFormTabs = () => {
  const { step = 1 } = useCategoryValue();
  const action = useCategoryAction();

  return (
    <Tabs
      destroyInactiveTabPane
      items={[
        {
          key: '1',
          label: 'اطلاعات اولیه',
          children: <CategoryBaseInfo />,
        },
        {
          key: '2',
          label: 'تنظیمات سئو',
          children: <CategorySeoInfo />,
          disabled: step < 2,
        },
      ]}
      className='rounded bg-white lg:basis-[70%] [&>div]:px-6 first-of-type:[&>div]:pt-3 last-of-type:[&>div]:py-3'
      activeKey={step.toString()}
      onChange={activeKey => {
        action({
          type: 'UPDATE_CATEGORY_STEP',
          payload: {
            step: +activeKey as typeof step,
          },
        });
      }}
    />
  );
};

export { CategoryFormTabs };
