import * as React from 'react';

import { Button, Flex, Input, Typography } from 'antd';

import { AntdRouterLink, PlusOutlinedIcon } from 'components';
import { PATHNAME } from 'utils';

const {
  LOCAL: {
    BRANDS: { CREATE_URL: BRANDS_CREATE_URL },
  },
} = PATHNAME;

type BrandsHeaderProps = {
  total: number;
  searchKey: string;
  handleSearchKey: (searchKey: string) => void;
};

const BrandsHeader = ({
  total,
  searchKey,
  handleSearchKey,
}: BrandsHeaderProps) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='py-1' style={{ marginBlockEnd: 0 }}>
      <span className='text-base'>برندها</span>
      <span className='text-sm text-slate-400'> ({total})</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      <Input.Search
        allowClear
        styles={{
          affixWrapper: {
            height: '2.5rem',
          },
        }}
        placeholder='جستجو برندها'
        defaultValue={searchKey}
        onSearch={value => handleSearchKey(value)}
      />

      <AntdRouterLink to={BRANDS_CREATE_URL} className='max-lg:basis-full'>
        <Button
          type='primary'
          className='w-full'
          icon={<PlusOutlinedIcon style={{ fontSize: 12 }} />}
        >
          برند جدید
        </Button>
      </AntdRouterLink>
    </Flex>
  </Flex>
);

export { BrandsHeader };
