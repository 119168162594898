import { Fetch } from 'lib';
import type { Purchase } from 'types';

type RequestResponses = Purchase;

type RequestParameters = {
  id: string;
};

const getPurchase = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminPurchase/getPurchase',
    params,
  });

export { getPurchase };
