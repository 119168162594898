import type { InputRule } from 'types';

const inputRule = {
  required: {
    required: true,
  },
  string: {
    whitespace: true,
  },
  email: {
    type: 'email',
    message: 'لطفا ایمیل معتبر وارد کنید.',
  },
  seoTitle: {
    max: 60,
    message: 'تعداد کاراکترهای عنوان سئو بیش از حد مجاز است.',
    warningOnly: true,
  },
  seoDescription: {
    max: 200,
    message: 'تعداد کاراکترهای توضیحات سئو بیش از حد مجاز است.',
    warningOnly: true,
  },
  abstraction: {
    max: 100,
    message: 'تعداد کاراکترهای توضیحات مختصر بیش از حد مجاز است.',
    warningOnly: true,
  },
  pin: {
    min: 6,
    message: 'لطفا کد تایید وارد کنید.',
  },
  password: {
    min: 8,
    message: 'لطفا رمز عبور وارد کنید.',
  },
  phoneNumber: {
    pattern: /^((\+98)|0)?9\d{9}$/gm,
    message: 'لطفا شماره همراه وارد کنید.',
  },
  telNumber: {
    pattern: /^((\+98)|0)?\d{10}$/gm,
    message: 'لطفا شماره ثابت وارد کنید.',
  },
  userName: {
    pattern: /^\w+([_]?\w+)*$/gm,
    message: 'لطفا حروف انگلیسی، عدد یا "_" وارد کنید.',
  },
  digit: {
    pattern: /^\d+(\.\d+)?$/gm,
    message: 'لطفا مقدار عددی وارد کنید.',
  },
  nonWhitespace: {
    pattern: /^\S+$/gm,
    message: 'لطفا بدون فاصله وارد کنید.',
  },
  nonDot: {
    pattern: /^[^.]+$/gm,
    message: 'لطفا بدون نقطه وارد کنید.',
  },
} satisfies Record<string, InputRule>;

export { inputRule };
