/* eslint-disable react/prop-types */
import * as React from 'react';

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

import type { FileInfo } from 'types';
import { FILE, validateMediaFile } from 'utils';

const {
  FORMAT: { IMAGES: IMAGES_FORMAT },
} = FILE;

type ImgCropUploadProps<T> = Partial<
  Record<'cropOptions', Partial<React.ComponentPropsWithoutRef<typeof ImgCrop>>>
> &
  React.ComponentPropsWithoutRef<typeof Upload<T>>;

function ImgCropUpload<T = FileInfo>({
  cropOptions = {
    modalTitle: 'ویرایش عکس',
    aspectSlider: true,
    rotationSlider: true,
    quality: 1,
  },
  showUploadList = false,
  beforeUpload,
  children,
  ...props
}: ImgCropUploadProps<T>) {
  return (
    <ImgCrop {...cropOptions}>
      <Upload<T>
        showUploadList={showUploadList}
        beforeUpload={
          beforeUpload ??
          (({ type }) =>
            validateMediaFile(type, IMAGES_FORMAT) || Upload.LIST_IGNORE)
        }
        {...props}
      >
        {children}
      </Upload>
    </ImgCrop>
  );
}

export { ImgCropUpload };
