const FILE = {
  FORMAT: {
    PICTURES: ['png', 'jpg', 'jpeg'],
    VIDEOS: ['mp4'],
    DOCUMENTS: ['pdf'],
  },
  PICTURES: {
    ASPECT: {
      WIDTH: 16,
      HEIGHT: 9,
    },
    SIZE: {
      WIDTH: 1920,
      HEIGHT: 1080,
    },
  },
} as const;

export { FILE };
