import * as React from 'react';

import { Result, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';

const { Text } = Typography;

export default function Home() {
  return (
    <>
      <Helmet>
        <title>داشبورد | کاشی لند</title>
      </Helmet>

      <div className='flex h-full items-center justify-center rounded bg-white'>
        <Result
          subTitle={<Text>صفحه اصلی داشبورد در حال بررسی و اجرا می‌باشد.</Text>}
        />
      </div>
    </>
  );
}
