/* eslint-disable react/prop-types */
import * as React from 'react';

import { Flex, Typography, Upload } from 'antd';

import { FileImageOutlinedIcon } from 'components';
import { cn, FILE, PATHNAME, validateMediaFile } from 'utils';

const { Dragger } = Upload;
const {
  QUERY_PARAM: {
    DEFAULT: { COMMA: DEFAULT_COMMA },
  },
} = PATHNAME;
const {
  FORMAT: {
    PICTURES: PICTURES_FORMAT,
    VIDEOS: VIDEOS_FORMAT,
    DOCUMENTS: DOCUMENTS_FORMAT,
  },
} = FILE;

type DraggerUploadProps = React.ComponentPropsWithoutRef<typeof Dragger> &
  Partial<Record<'withPictures' | 'withVideos' | 'withDocuments', boolean>>;

const DraggerUpload = React.forwardRef<
  React.ElementRef<typeof Dragger>,
  DraggerUploadProps
>(
  (
    {
      withPictures = true,
      withVideos = true,
      withDocuments = true,
      multiple = true,
      showUploadList = false,
      className,
      beforeUpload,
      ...props
    },
    ref,
  ) => (
    <Dragger
      ref={ref}
      multiple={multiple}
      showUploadList={showUploadList}
      className={cn('first:[&>div]:border-solid', className)}
      beforeUpload={
        beforeUpload ??
        (({ type }) =>
          (withPictures && validateMediaFile(type, PICTURES_FORMAT)) ||
          (withVideos && validateMediaFile(type, VIDEOS_FORMAT)) ||
          (withDocuments && validateMediaFile(type, DOCUMENTS_FORMAT)) ||
          Upload.LIST_IGNORE)
      }
      {...props}
    >
      <FileImageOutlinedIcon className='my-3 text-[1.375rem] text-[var(--ant-color-link-hover)]' />

      <Typography.Paragraph>مستندات را بارگذاری کنید</Typography.Paragraph>

      <Flex
        vertical
        justify='center'
        align='center'
        className='mt-1 text-gray-400'
      >
        {withPictures ? (
          <p>
            فرمت قابل قبول تصویرها: {PICTURES_FORMAT.join(`${DEFAULT_COMMA} `)}
          </p>
        ) : null}
        {withVideos ? (
          <p>
            فرمت قابل قبول ویدیوها: {VIDEOS_FORMAT.join(`${DEFAULT_COMMA} `)}
          </p>
        ) : null}
        {withDocuments ? (
          <p>
            فرمت قابل قبول فایل‌ها:‌{' '}
            {DOCUMENTS_FORMAT.join(`${DEFAULT_COMMA} `)}
          </p>
        ) : null}
      </Flex>
    </Dragger>
  ),
);
DraggerUpload.displayName = 'DraggerUpload';

export { DraggerUpload };
