import * as React from 'react';

import { useUpdateEffect } from 'ahooks';
import {
  Badge,
  Button,
  Dropdown,
  Flex,
  Image,
  Modal,
  notification,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import {
  AntdRouterLink,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import { deleteProductComment } from 'services';
import type { ProductComment, ProductCommentStatus } from 'types';
import {
  adjustPageNumber,
  dateTimeFormat,
  generateFileUrl,
  generateTableDataSource,
  localizeProductCommentStatus,
  normalizeToString,
  parseFromContent,
  PATHNAME,
} from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    PRODUCTS: { BASE_URL: PRODUCTS_BASE_URL },
    USERS: { BASE_URL: USERS_BASE_URL },
    COMMENTS_MANAGEMENT: {
      PRODUCTS_COMMENTS: { UPDATE_URL: PRODUCTS_COMMENTS_UPDATE_URL },
    },
  },
  QUERY_PARAM: {
    DEFAULT: {
      QUESTION_MARK: DEFAULT_QUESTION_MARK,
      ASSIGNMENT: DEFAULT_ASSIGNMENT,
    },
    SEARCH_KEY: { BASE_URL: SEARCH_KEY_BASE_URL },
  },
} = PATHNAME;

const productCommentStatuses = [
  'OnReview',
  'Rejected',
  'Accepted',
] satisfies ProductCommentStatus[];

type ProductsCommentsTableProps = {
  loading: boolean;
  total: number;
  productsComments: ProductComment[];
  pageSize: number;
  pageNumber: number;
  handleStatuses: (statuses: ProductCommentStatus[]) => void;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const ProductsCommentsTable = React.memo<ProductsCommentsTableProps>(
  ({
    loading,
    total,
    productsComments,
    pageSize,
    pageNumber,
    handleStatuses,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: ProductsCommentsTableProps) => {
    const [modal, contextHolder] = Modal.useModal();

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteProductComment = async (id: string) => {
      deleteProductComment({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const productsCommentsDataSource = generateTableDataSource(
      productsComments,
      'id',
    );

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: 900, y: 630 }}
        columns={[
          {
            title: 'نام محصول',
            dataIndex: 'product',
            key: 'product',
            width: '30%',
            ellipsis: true,
            render: (_, { product: { name, bannerId } }) => (
              <Space>
                <Image
                  src={generateFileUrl('Product', bannerId, 900, 900)}
                  width={38}
                  height={38}
                />
                <Tooltip title={name}>
                  <AntdRouterLink
                    ellipsis
                    to={`${PRODUCTS_BASE_URL}${DEFAULT_QUESTION_MARK}${SEARCH_KEY_BASE_URL}${DEFAULT_ASSIGNMENT}${name}`}
                    style={{ maxWidth: 360 }}
                  >
                    {name}
                  </AntdRouterLink>
                </Tooltip>
              </Space>
            ),
          },
          {
            title: 'دیدگاه کاربر',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true,
            render: (_, { content }) => (
              <Typography
                dangerouslySetInnerHTML={{ __html: parseFromContent(content) }}
              />
            ),
          },
          {
            title: 'شناسه کاربر',
            dataIndex: 'user',
            key: 'user',
            width: 150,
            ellipsis: true,
            render: (
              _,
              { user: { firstName, lastName, displayName, userName, id } },
            ) => {
              const name =
                firstName || lastName ? `${firstName} ${lastName}` : id;

              return (
                <AntdRouterLink
                  to={`${USERS_BASE_URL}${DEFAULT_QUESTION_MARK}${SEARCH_KEY_BASE_URL}${DEFAULT_ASSIGNMENT}${id}`}
                >
                  {userName ?? displayName ?? name}
                </AntdRouterLink>
              );
            },
          },
          {
            title: 'وضعیت انتشار',
            dataIndex: 'status',
            key: 'status',
            width: 140,
            ellipsis: true,
            filterDropdown: () => (
              <div
                style={{ background: 'var(--ant-table-header-bg)', padding: 8 }}
              >
                <Select<ProductCommentStatus[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='وضعیت مورد نظر را انتخاب کنید'
                  options={productCommentStatuses.map(type => ({
                    label: localizeProductCommentStatus(type).label,
                    value: type,
                  }))}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleStatuses(value);
                  }}
                />
              </div>
            ),
            render: (_, { status }) => (
              <Flex justify='center'>
                <Badge
                  count={localizeProductCommentStatus(status).label}
                  color={localizeProductCommentStatus(status).color}
                />
              </Flex>
            ),
          },
          {
            title: 'تاریخ انتشار',
            dataIndex: 'publishTime',
            key: 'publishTime',
            width: 125,
            ellipsis: true,
            render: (_, { publishTime }) =>
              publishTime ? dateTimeFormat(new Date(publishTime)) : null,
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, content }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-product-comment',
                        label: (
                          <AntdRouterLink
                            to={`${PRODUCTS_COMMENTS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>ویرایش دیدگاه</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-product-comment',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='flex items-center'
                              style={{ paddingInline: 0, marginBlock: 2 }}
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف دیدگاه "${content}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteProductComment(id);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span className='text-sm'>حذف دیدگاه</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                  placement='bottomLeft'
                >
                  <Button size='small' className='py-3.5'>
                    <MoreOutlinedIcon style={{ fontSize: 20 }} />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={productsCommentsDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
ProductsCommentsTable.displayName = 'ProductsCommentsTable';

export { ProductsCommentsTable };
