import * as React from 'react';

import { useRequest } from 'ahooks';
import { Col, Form, Input, Row, Select, TreeSelect, Typography } from 'antd';

import { CheckInputbox } from 'components';
import { useAttributeAction, useAttributeValue } from 'context';
import { getCategories } from 'services';
import type { TreeSelectSource } from 'types';
import {
  generateTreeSelectData,
  inputRule,
  localizeAttributeType,
  normalizeToString,
} from 'utils';

type FieldType = Pick<
  ReturnType<typeof useAttributeValue>,
  'name' | 'type' | 'isFilter' | 'categories'
>;

const attributeConfigTypes = [
  'Text',
  'Number',
  'Select',
  'CheckBox',
] satisfies NonNullable<FieldType['type']>[];

type AttributeFormInformationProps = {
  handleSelectedCategories: (
    selectedCategories: NonNullable<FieldType['categories']>,
  ) => void;
};

const AttributeFormInformation = ({
  handleSelectedCategories,
}: AttributeFormInformationProps) => {
  const { data: categoriesData, loading: categoriesLoading } = useRequest(
    getCategories,
    {
      cacheKey: 'categories',
    },
  );
  const { name, type, isFilter, defaultName } = useAttributeValue();
  const action = useAttributeAction();

  const totalCategories = categoriesData?.categories ?? [];
  const categoriesTreeData = generateTreeSelectData(
    totalCategories,
    'id',
    'title',
    'id',
  );

  return (
    <Row className='space-y-2 rounded bg-white px-6 py-8'>
      <Col span={24}>
        <Typography.Title level={2} className='text-sm'>
          اطلاعات اولیه فیلد {defaultName}
        </Typography.Title>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType>
          label='نام'
          name='name'
          rules={[inputRule.required, inputRule.string]}
          initialValue={name}
        >
          <Input placeholder='نام فیلد را وارد کنید' />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='نوع'
          name='type'
          rules={[inputRule.required]}
          initialValue={type}
        >
          <Select<typeof type>
            placeholder='نوع فیلد را انتخاب کنید'
            options={attributeConfigTypes.map(type => ({
              label: localizeAttributeType(type).label,
              value: type,
            }))}
            onChange={value => {
              action({
                type: 'UPDATE_ATTRIBUTE_TYPE',
                payload: {
                  type: value,
                },
              });
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='قابلیت فیلتر'
          name='isFilter'
          valuePropName='checked'
          initialValue={isFilter}
        >
          <CheckInputbox>قابلیت فیلتر توسط کاربر دارد</CheckInputbox>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType>
          label='دسته‌بندی‌های اختصاص یافته'
          name='categories'
        >
          <TreeSelect<TreeSelectSource[]>
            multiple
            treeCheckable
            treeCheckStrictly
            placeholder='دسته‌بندی‌های خود را انتخاب کنید'
            loading={categoriesLoading}
            treeData={categoriesTreeData}
            filterTreeNode={(inputValue, treeNode) =>
              normalizeToString(treeNode.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={values => {
              handleSelectedCategories(
                values.map(({ label, value }) => ({
                  id: normalizeToString(value),
                  slug: normalizeToString(label),
                  subSlug: normalizeToString(label),
                  title: normalizeToString(label),
                  status: 'Active',
                  values: [],
                })),
              );
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { AttributeFormInformation };
