import { clsx } from 'clsx';
import type { ClassValue as ClassNameValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { v4 as uuId } from 'uuid';
import type { V4Options as UUIdOptions } from 'uuid';

import type { BaseTreeOption } from 'types';
import { PATHNAME } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  QUERY_PARAM: {
    DEFAULT: { PAGE_NUMBER: DEFAULT_PAGE_NUMBER },
  },
} = PATHNAME;

const randomId = (options?: UUIdOptions) => uuId(options);

const cn = (...inputs: ClassNameValue[]) => twMerge(clsx(inputs));

const createTimestamp = (ms: number) => Date.now() + ms;

const normalizeToString = (input: any = ''): string => input.toString();

const fixedParseFloat = (value: number, fractionDigits = 0) =>
  parseFloat(value.toFixed(fractionDigits));

const getObjectEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Array<[keyof T, T[keyof T]]>;

const getObjectValues = <T extends object>(obj: T) =>
  Object.values(obj).filter(Boolean) as Array<NonNullable<T[keyof T]>>;

const deepFlatFilter = <T>(data: T[], depth = Infinity) =>
  data.flat(depth).filter(Boolean) as Array<NonNullable<T>>;

const validateMediaFile = (type: string, formats: readonly string[]) =>
  formats.some(format => type.toLowerCase().endsWith(format));

const joinEncodedSegments = (
  segments: string[],
  separator: string = DEFAULT_SLUG_SEPARATOR,
) => encodeURIComponent(segments.join(separator));

const joinDecodedSegments = (
  segments: string[],
  separator: string = DEFAULT_SLUG_SEPARATOR,
) => decodeURIComponent(segments.join(separator));

const filterObjectFromEntries = <T extends object, K extends Array<keyof T>>(
  obj: T,
  fields: K,
) =>
  Object.fromEntries(
    getObjectEntries(obj).filter(([key]) => !fields.includes(key)),
  ) as StrictOmit<T, K[number]>;

const getObjectFromEntries = <T extends object, K extends Array<keyof T>>(
  obj: T,
  fields: K,
) =>
  Object.fromEntries(
    getObjectEntries(obj).filter(([key]) => fields.includes(key)),
  ) as Pick<T, K[number]>;

const parseFromContent = (
  content: string,
  type: DOMParserSupportedType = 'text/html',
) =>
  typeof window !== 'undefined'
    ? new DOMParser().parseFromString(content, type).body.innerHTML
    : '';

const createRange = (start: number, end: number) =>
  Array.from(
    { length: end - start + DEFAULT_PAGE_NUMBER },
    (_, idx) => idx + start,
  );

const padString = (
  value: string,
  type: 'padStart' | 'padEnd' = 'padStart',
  maxLength = 2,
  fillString = '0',
) => value[type](maxLength, fillString);

const replaceExtraSeparator = (
  value: string,
  type: 'space' | 'dash' = 'space',
  replaceValue = '-',
) => value.trim().replace(type === 'space' ? /\s+/g : /[-]+/g, replaceValue);

const hasIntersection = <T>(i1: Iterable<T>, i2: Iterable<T>) => {
  const secondIterable = new Set(i2);
  if (secondIterable.size === 0) return false;
  return Array.from(i1).some(value => secondIterable.has(value));
};

const adjustPageNumber = (
  total: number,
  size: number,
  page: number,
  updatePage: (page: number) => void,
) => {
  const totalPage = Math.ceil(total / size);
  if (page > totalPage && totalPage >= DEFAULT_PAGE_NUMBER) {
    updatePage(totalPage);
  }
};

const flatMapTreeOption = <T extends BaseTreeOption<T>>(options: T[]): T[] => {
  if (options.length === 0) return [];
  return options.flatMap(option => [
    option,
    ...(option.children ? flatMapTreeOption(option.children) : []),
  ]);
};

const triggerPathname = (pathname: string, triggers: string[]) =>
  triggers.some(trigger => {
    const decodedPathname = joinDecodedSegments(
      pathname.split(DEFAULT_SLUG_SEPARATOR),
    );
    const decodedTrigger = joinDecodedSegments(
      trigger.split(DEFAULT_SLUG_SEPARATOR),
    );
    const splitedTrigger = trigger
      .split(DEFAULT_SLUG_SEPARATOR)
      .filter(Boolean);

    if (splitedTrigger.length > 0) {
      return (
        decodedPathname === decodedTrigger ||
        decodedPathname.startsWith(decodedTrigger)
      );
    }
    return decodedPathname === decodedTrigger;
  });

const getTimeProgress = (
  targetDate: Date,
  middleDate: Date,
  startDate: Date,
) => {
  const targetDateTime = targetDate.getTime();
  const middleDateTime = middleDate.getTime();
  const startDateTime = startDate.getTime();

  const isBeforeStart = middleDateTime < startDateTime;
  const isAfterTarget = middleDateTime > targetDateTime;
  const isOutOfRange = isBeforeStart || isAfterTarget;

  const timeRange = targetDateTime - startDateTime;
  const timeElapsed = !isOutOfRange ? middleDateTime - startDateTime : 0;
  const timeRemaining = !isOutOfRange ? targetDateTime - middleDateTime : 0;
  const timeProgress = isBeforeStart
    ? 0
    : isAfterTarget
      ? 1
      : fixedParseFloat(timeElapsed / timeRange, 2);

  return {
    isBeforeStart,
    isAfterTarget,
    isOutOfRange,
    timeRange,
    timeElapsed,
    timeRemaining,
    timeProgress,
  };
};

export {
  adjustPageNumber,
  cn,
  createRange,
  createTimestamp,
  deepFlatFilter,
  filterObjectFromEntries,
  fixedParseFloat,
  flatMapTreeOption,
  getObjectEntries,
  getObjectFromEntries,
  getObjectValues,
  getTimeProgress,
  hasIntersection,
  joinDecodedSegments,
  joinEncodedSegments,
  normalizeToString,
  padString,
  parseFromContent,
  randomId,
  replaceExtraSeparator,
  triggerPathname,
  validateMediaFile,
};
