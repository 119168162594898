import * as React from 'react';

import { useLockFn, useMemoizedFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useProductAction, useProductValue } from 'context';
import { createProduct, updateProduct } from 'services';
import {
  generateUniqueElements,
  localizeProductType,
  PATHNAME,
  replaceExtraSeparator,
} from 'utils';

import { ProductFormAction } from './action';
import { ProductFormTabs } from './tabs';

const {
  LOCAL: {
    PRODUCTS: { BASE_URL: PRODUCTS_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<
  ReturnType<typeof useProductValue>,
  'callForPrice' | 'showPostingStatus' | 'status'
> &
  Partial<
    Record<'seoTitle' | 'seoDescription', string> &
      Record<string, string | string[]>
  >;

const ProductForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    name = '',
    identifier = '',
    slug,
    brand,
    mainCategory,
    otherCategories = [],
    callForPrice = false,
    showPostingStatus = false,
    description = '',
    productType,
    variableAttributes = [],
    subProducts = [],
    baseAttributes = [],
    packagingInfo = [],
    pictures = [],
    documents = [],
    defaultName,
    defaultStatus,
    mode,
    step,
  } = useProductValue();
  const action = useProductAction();
  const navigate = useNavigate();

  const formatedIdentifier = replaceExtraSeparator(identifier);
  const formatedSlug = replaceExtraSeparator(slug ?? name);

  const handleUpdateProductBaseInfo = useMemoizedFn(
    ({
      callForPrice,
      showPostingStatus,
    }: Pick<FieldType, 'callForPrice' | 'showPostingStatus'>) => {
      action({
        type: 'UPDATE_PRODUCT_BASE_INFO',
        payload: {
          callForPrice,
          showPostingStatus,
        },
      });
      action({
        type: 'UPDATE_PRODUCT_STEP',
        payload: {
          step: 2,
        },
      });
    },
  );

  const handleUpdateProductModelInfo = useMemoizedFn(() => {
    if (subProducts.length > 0) {
      action({
        type: 'UPDATE_PRODUCT_STEP',
        payload: {
          step: 3,
        },
      });
    } else {
      notification.warning({
        message: 'حداقل یک مدل باید موجود باشد.',
      });
    }
  });

  const handleUpdateProductAttributesInfo = useMemoizedFn(
    (attributes: Partial<Record<string, string | string[]>>) => {
      action({
        type: 'UPDATE_PRODUCT_BASE_ATTRIBUTES',
        payload: {
          baseAttributes: baseAttributes.map(({ id, ...otherAttributes }) => {
            const selectedValues = attributes[`base-attribute-${id}`] ?? [];

            return {
              ...otherAttributes,
              id,
              selectedValues:
                typeof selectedValues === 'string'
                  ? [selectedValues]
                  : selectedValues,
            };
          }),
        },
      });
      action({
        type: 'UPDATE_PRODUCT_STEP',
        payload: {
          step: 4,
        },
      });
    },
  );

  const handleUpdateProductFilesInfo = useMemoizedFn(
    (files: Partial<Record<string, string | string[]>>) => {
      if (pictures.length > 0 || documents.length > 0) {
        action({
          type: 'UPDATE_PRODUCT_PICTURES',
          payload: {
            pictures: pictures.map(picture => {
              const { id } = picture;
              const title = files[`file-title-${id}`] as string;
              const watermark = Boolean(files[`file-watermark-${id}`]);
              const subProductId = files[`file-subProduct-${id}`] as string;

              return {
                ...picture,
                title,
                watermark,
                subProductId,
              };
            }),
          },
        });
        action({
          type: 'UPDATE_PRODUCT_DOCUMENTS',
          payload: {
            documents: documents.map(document => {
              const { id } = document;
              const title = files[`file-title-${id}`] as string;
              const watermark = Boolean(files[`file-watermark-${id}`]);
              const subProductId = files[`file-subProduct-${id}`] as string;

              return {
                ...document,
                title,
                watermark,
                subProductId,
              };
            }),
          },
        });
        action({
          type: 'UPDATE_PRODUCT_STEP',
          payload: {
            step: 5,
          },
        });
      } else {
        notification.warning({
          message: 'حداقل یک مستند از محصول باید موجود باشد.',
        });
      }
    },
  );

  const handleUpdateProductPackagingInfo = useMemoizedFn(() => {
    if (packagingInfo.length > 0) {
      action({
        type: 'UPDATE_PRODUCT_STEP',
        payload: {
          step: 6,
        },
      });
    } else {
      notification.warning({
        message: 'حداقل یک بسته‌بندی از محصول باید موجود باشد.',
      });
    }
  });

  const handleUpdateProductSeoInfo = useLockFn(
    async ({
      seoTitle = '',
      seoDescription = '',
      status = defaultStatus!,
    }: Pick<FieldType, 'seoTitle' | 'seoDescription' | 'status'>) => {
      const params: Parameters<typeof updateProduct>[0] = {
        id,
      };
      const body: Parameters<typeof updateProduct>[1] = {
        name,
        identifier: formatedIdentifier,
        slug: formatedSlug,
        brandId: brand?.id,
        mainCategoryId: mainCategory?.id,
        otherCategoriesIds: otherCategories.map(({ id }) => id),
        callForPrice,
        showPostingStatus,
        description,
        productType: productType!,
        variableAttributesIds: variableAttributes.map(({ id }) => id),
        subProducts: subProducts.map(
          ({ priceHistories, attributes, ...otherSubProduct }) => ({
            ...otherSubProduct,
            attributes: attributes.map(({ id, selectedValues }) => ({
              id,
              selectedValues,
            })),
          }),
        ),
        baseAttributes: baseAttributes.map(({ id, selectedValues }) => ({
          id,
          selectedValues,
        })),
        pictures: pictures.map(({ id, title, subProductId, watermark }) => ({
          id,
          title,
          subProductId,
          watermark,
        })),
        documents: documents.map(({ id, title, subProductId, watermark }) => ({
          id,
          title,
          subProductId,
          watermark,
        })),
        packagingInfo: packagingInfo.map(
          ({
            packagingModel: { id: packagingModelId },
            measurementUnit: { id: measurementUnitId },
            amount,
          }) => ({
            packagingModelId,
            measurementUnitId,
            amount,
          }),
        ),
        seo: {
          title: seoTitle,
          description: seoDescription,
        },
        status,
      };

      switch (mode) {
        case 'Create': {
          return createProduct(body)
            .then(() => {
              notification.success({
                message: `محصول ${name} با موفقیت ساخته شد.`,
              });
              navigate(PRODUCTS_BASE_URL);
            })
            .catch(message => {
              notification.error({
                message,
              });
            });
        }
        case 'Update': {
          return updateProduct(params, body)
            .then(() => {
              notification.success({
                message: `محصول ${defaultName} با موفقیت ویرایش شد.`,
              });
              navigate(PRODUCTS_BASE_URL);
            })
            .catch(message => {
              notification.error({
                message,
              });
            });
        }
        default:
          break;
      }
    },
  );

  const onFinish = useMemoizedFn(
    ({
      callForPrice,
      showPostingStatus,
      seoTitle,
      seoDescription,
      status,
      ...otherValues
    }: FieldType) => {
      switch (step) {
        case 1: {
          return handleUpdateProductBaseInfo({
            callForPrice,
            showPostingStatus,
          });
        }
        case 2: {
          return handleUpdateProductModelInfo();
        }
        case 3: {
          return handleUpdateProductAttributesInfo(otherValues);
        }
        case 4: {
          return handleUpdateProductFilesInfo(otherValues);
        }
        case 5: {
          return handleUpdateProductPackagingInfo();
        }
        case 6: {
          return handleUpdateProductSeoInfo({
            seoTitle,
            seoDescription,
            status,
          });
        }
        default:
          break;
      }
    },
  );

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      autoComplete='off'
      style={{
        marginBlock: 12,
      }}
      fields={[
        {
          name: 'name',
          value: name,
        },
        {
          name: 'identifier',
          value: formatedIdentifier,
        },
        {
          name: 'slug',
          value: formatedSlug,
        },
        {
          name: 'brand',
          value: brand && {
            key: brand.id,
            label: brand.title,
            value: brand.id,
          },
        },
        {
          name: 'mainCategory',
          value: mainCategory && {
            key: mainCategory.id,
            label: mainCategory.title,
            value: mainCategory.id,
          },
        },
        {
          name: 'otherCategories',
          value: otherCategories.map(({ id, title }) => ({
            key: id,
            label: title,
            value: id,
          })),
        },
        {
          name: 'productType',
          value: productType && {
            key: productType,
            label: localizeProductType(productType).label,
            value: productType,
          },
        },
        {
          name: 'variableAttributes',
          value: variableAttributes.map(({ id, name }) => ({
            key: id,
            label: name,
            value: id,
          })),
        },
        ...baseAttributes.map(({ id, type, selectedValues }) => {
          const isCheckBox = type === 'CheckBox';

          const relatedCategories = otherCategories.filter(
            ({ relatedAttribute }) => relatedAttribute?.id === id,
          );
          const relatedValues = relatedCategories.flatMap(
            ({ relatedAttribute }) => relatedAttribute?.selectedValues ?? [],
          );
          const currentSelectedValues = generateUniqueElements([
            ...relatedValues,
            ...selectedValues,
          ]);

          return {
            name: `base-attribute-${id}`,
            value: isCheckBox
              ? currentSelectedValues
              : currentSelectedValues.at(0),
          };
        }),
        ...[...pictures, ...documents].map(({ id, subProductId }) => {
          const isValidSubProduct = subProducts.some(
            subProduct => subProduct.id === subProductId,
          );

          return {
            name: `file-subProduct-${id}`,
            value: isValidSubProduct ? subProductId : undefined,
          };
        }),
      ]}
      onFinish={onFinish}
    >
      <Flex className='max-xl:flex-col xl:items-start' gap='large'>
        <ProductFormTabs />
        <ProductFormAction />
      </Flex>
    </Form>
  );
};

export { ProductForm };
