import * as React from 'react';

import { useRequest, useUpdateEffect } from 'ahooks';
import {
  Badge,
  Button,
  Dropdown,
  Flex,
  Image,
  Modal,
  notification,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';

import {
  AntdRouterExternalLink,
  AntdRouterLink,
  CheckCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  EyeOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import { deleteProduct, getBrands, getCategories } from 'services';
import type { ProductStatus, ProductSummary } from 'types';
import {
  adjustPageNumber,
  dateTimeFormat,
  generateFileUrl,
  generateSelectOptions,
  generateTableDataSource,
  localizeProductStatus,
  normalizeToString,
  numberFormat,
  PATHNAME,
  UNIT,
} from 'utils';

const { PRICE } = UNIT;
const {
  DEFAULT: {
    BASE_URL: DEFAULT_BASE_URL,
    SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR,
  },
  LOCAL: {
    PRODUCTS: { UPDATE_URL: PRODUCTS_UPDATE_URL },
    BRANDS: { BASE_URL: BRANDS_BASE_URL, UPDATE_URL: BRANDS_UPDATE_URL },
    CATEGORIES: {
      BASE_URL: CATEGORIES_BASE_URL,
      UPDATE_URL: CATEGORIES_UPDATE_URL,
    },
    PRODUCT: { BASE_URL: PRODUCT_BASE_URL },
  },
} = PATHNAME;

const productStatuses = [
  'Submitted',
  'Draft',
  'Private',
] satisfies ProductStatus[];

type ProductsTableProps = {
  loading: boolean;
  total: number;
  products: ProductSummary[];
  pageSize: number;
  pageNumber: number;
  handleCategoriesIds: (categoriesIds: string[]) => void;
  handleBrandsIds: (brandsIds: string[]) => void;
  handleStatuses: (statuses: ProductStatus[]) => void;
  handleCallForPrice: (callForPrice: boolean) => void;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const ProductsTable = React.memo<ProductsTableProps>(
  ({
    loading,
    total,
    products,
    pageSize,
    pageNumber,
    handleCategoriesIds,
    handleBrandsIds,
    handleStatuses,
    handleCallForPrice,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: ProductsTableProps) => {
    const [modal, contextHolder] = Modal.useModal();
    const { data: categoriesData, loading: categoriesLoading } = useRequest(
      getCategories,
      {
        cacheKey: 'categories',
      },
    );
    const { data: brandsData, loading: brandsLoading } = useRequest(getBrands, {
      cacheKey: 'brands',
    });

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteProduct = async (id: string) => {
      deleteProduct({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const categories = categoriesData?.categories ?? [];
    const brands = brandsData?.brands ?? [];

    const productsDataSource = generateTableDataSource(products, 'id');
    const categoriesSelectOptions = generateSelectOptions(
      categories,
      'id',
      'title',
      'id',
    );
    const brandsSelectOptions = generateSelectOptions(
      brands,
      'id',
      'title',
      'id',
    );

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: 1600, y: 630 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ellipsis: true,
            render: (_, { id, name, bannerId }) => (
              <Space>
                <Image
                  src={generateFileUrl('Product', bannerId, 600, 600)}
                  width={40}
                  height={40}
                />
                <Tooltip title={name}>
                  <AntdRouterLink
                    ellipsis
                    to={`${PRODUCTS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                    className='max-w-[400px]'
                  >
                    {name}
                  </AntdRouterLink>
                </Tooltip>
              </Space>
            ),
          },
          {
            title: 'دسته‌بندی',
            dataIndex: 'mainCategory',
            key: 'mainCategory',
            ellipsis: true,
            filterDropdown: () => (
              <div className='bg-[var(--ant-table-header-bg)] p-2'>
                <Select<string[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='دسته‌بندی مورد نظر را انتخاب کنید'
                  loading={categoriesLoading}
                  options={categoriesSelectOptions}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleCategoriesIds(value);
                  }}
                />
              </div>
            ),
            render: (_, { mainCategory }) =>
              mainCategory ? (
                <AntdRouterExternalLink
                  ellipsis
                  href={`${CATEGORIES_BASE_URL}${CATEGORIES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${mainCategory.id}${DEFAULT_SLUG_SEPARATOR}`}
                >
                  {mainCategory.title}
                </AntdRouterExternalLink>
              ) : null,
          },
          {
            title: 'برند',
            dataIndex: 'brand',
            key: 'brand',
            ellipsis: true,
            filterDropdown: () => (
              <div className='bg-[var(--ant-table-header-bg)] p-2'>
                <Select<string[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='برند مورد نظر را انتخاب کنید'
                  loading={brandsLoading}
                  options={brandsSelectOptions}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleBrandsIds(value);
                  }}
                />
              </div>
            ),
            render: (_, { brand }) =>
              brand ? (
                <AntdRouterExternalLink
                  ellipsis
                  href={`${BRANDS_BASE_URL}${BRANDS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${brand.id}${DEFAULT_SLUG_SEPARATOR}`}
                >
                  {brand.title}
                </AntdRouterExternalLink>
              ) : null,
          },
          {
            title: 'قیمت',
            dataIndex: 'mainSubProduct',
            key: 'mainSubProduct',
            ellipsis: true,
            render: (_, { mainSubProduct }) =>
              numberFormat(mainSubProduct?.price ?? 0, undefined, ` ${PRICE}`),
          },
          {
            title: 'کل موجودی',
            dataIndex: 'totalStock',
            key: 'totalStock',
            width: 110,
            ellipsis: true,
            render: (_, { totalStock }) => numberFormat(totalStock),
          },
          {
            title: 'استعلام بها',
            dataIndex: 'callForPrice',
            key: 'callForPrice',
            width: 120,
            ellipsis: true,
            filterMultiple: false,
            filterDropdown: () => (
              <div className='bg-[var(--ant-table-header-bg)] p-2'>
                <Select<boolean>
                  allowClear
                  className='w-72'
                  placeholder='استعلام مورد نظر را انتخاب کنید'
                  options={[
                    {
                      label: 'نیاز به استعلام بها دارد',
                      value: 'true',
                    },
                    {
                      label: 'نیاز به استعلام بها ندارد',
                      value: 'false',
                    },
                  ]}
                  onChange={value => {
                    handleCallForPrice(value);
                  }}
                />
              </div>
            ),
            render: (_, { callForPrice }) => (
              <Flex justify='center'>
                {callForPrice ? (
                  <CheckCircleOutlinedIcon className='text-green-500' />
                ) : (
                  <CloseCircleOutlinedIcon className='text-red-500' />
                )}
              </Flex>
            ),
          },
          {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            width: 110,
            ellipsis: true,
            filterDropdown: () => (
              <div className='bg-[var(--ant-table-header-bg)] p-2'>
                <Select<ProductStatus[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='وضعیت مورد نظر را انتخاب کنید'
                  options={productStatuses.map(type => ({
                    label: localizeProductStatus(type).label,
                    value: type,
                  }))}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleStatuses(value);
                  }}
                />
              </div>
            ),
            render: (_, { status }) => (
              <Flex justify='center'>
                <Badge
                  count={localizeProductStatus(status).label}
                  color={localizeProductStatus(status).color}
                />
              </Flex>
            ),
          },
          {
            title: 'تاریخ ساخت',
            dataIndex: 'createdDate',
            key: 'createdDate',
            width: 120,
            ellipsis: true,
            render: (_, { createdDate }) =>
              dateTimeFormat(new Date(createdDate)),
          },
          {
            title: 'تاریخ آخرین ویرایش',
            dataIndex: 'lastModifiedDate',
            key: 'lastModifiedDate',
            width: 155,
            ellipsis: true,
            render: (_, { lastModifiedDate }) =>
              dateTimeFormat(new Date(lastModifiedDate)),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, slug, name }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'see-product',
                        label: (
                          <AntdRouterExternalLink
                            href={`${DEFAULT_BASE_URL}${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-[var(--ant-color-primary)]'
                          >
                            <EyeOutlinedIcon />
                            <span className='ms-2 text-sm'>
                              مشاهده در فروشگاه
                            </span>
                          </AntdRouterExternalLink>
                        ),
                      },
                      {
                        key: 'update-product',
                        label: (
                          <AntdRouterLink
                            to={`${PRODUCTS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>ویرایش محصول</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-product',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='my-0.5 flex items-center px-0'
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف محصول "${name}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteProduct(id);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span className='text-sm'>حذف محصول</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='pb-[18px] pt-3.5'>
                    <MoreOutlinedIcon className='text-xl' />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={productsDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
ProductsTable.displayName = 'ProductsTable';

export { ProductsTable };
