import type {
  BaseAttribute,
  BaseTreeOption,
  FileOwnerType,
  Product,
  ResponseErrors,
  SelectOption,
  TableSource,
  TreeSelectSource,
} from 'types';
import { PATHNAME } from 'utils';

const {
  DEFAULT: {
    API_BASE_URL: DEFAULT_API_BASE_URL,
    SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR,
    LOCALE: DEFAULT_LOCALE,
  },
  QUERY_PARAM: {
    DEFAULT: {
      QUESTION_MARK: DEFAULT_QUESTION_MARK,
      AMPERSAND: DEFAULT_AMPERSAND,
      ASSIGNMENT: DEFAULT_ASSIGNMENT,
    },
    CULTURE: { BASE_URL: CULTURE_BASE_URL },
  },
} = PATHNAME;

const generateRequestUrl = <P extends object = object>(
  url: string,
  params?: P,
) => {
  let queryParams = '';

  if (params) {
    queryParams = Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value)
          ? value.map(
              v => `${key}${DEFAULT_ASSIGNMENT}${encodeURIComponent(v)}`,
            )
          : `${key}${DEFAULT_ASSIGNMENT}${encodeURIComponent(value)}`,
      )
      .join(DEFAULT_AMPERSAND);
  }

  return `${DEFAULT_API_BASE_URL}${DEFAULT_SLUG_SEPARATOR}${url}${DEFAULT_QUESTION_MARK}${CULTURE_BASE_URL}${DEFAULT_ASSIGNMENT}${DEFAULT_LOCALE}${DEFAULT_AMPERSAND}${queryParams}`;
};

const generateFileUrl = (
  ownerType: FileOwnerType,
  id: string,
  width = 100,
  height = 100,
  quality = 80,
) =>
  generateRequestUrl(
    `file${DEFAULT_SLUG_SEPARATOR}downloadFile${DEFAULT_SLUG_SEPARATOR}${ownerType}`,
    {
      id,
      width,
      height,
      quality,
    },
  );

const generateResponseError = ({ errors }: ResponseErrors) => {
  let message = '';

  errors.forEach(error => {
    if ('field' in error) {
      message = error.messages.join(', ');
    }
    if ('code' in error) {
      message = error.message;
    }
  });

  return message;
};

const generateBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const generateVariableSubProductTitle = ({
  name = '',
  type,
  selectedValues = [],
}: Partial<BaseAttribute>) => ({
  name,
  value:
    type === 'CheckBox'
      ? selectedValues.join(', ')
      : selectedValues.at(0) ?? '-',
});

const generateSubProductTitle = ({
  name,
  productType,
  attributes = [],
}: Partial<Product & Record<'attributes', Partial<BaseAttribute>[]>>) =>
  productType === 'Normal'
    ? name
    : productType === 'Variable'
      ? attributes.map(attribute => generateVariableSubProductTitle(attribute))
      : undefined;

const generateUniqueElements = <T, K extends keyof T = any>(
  elements: T[],
  key?: K,
): (T[K] | T)[] => {
  const uniqueElements = new Set<T[K] | T>();

  elements.forEach(element => {
    if (typeof element === 'object' && element !== null && key) {
      if (key in element) {
        uniqueElements.add(element[key]);
      }
    } else {
      uniqueElements.add(element);
    }
  });

  return Array.from(uniqueElements);
};

const generateSelectOptions = <
  T extends BaseTreeOption<T>,
  K extends keyof T,
  L extends keyof T,
  V extends keyof T,
>(
  elements: T[],
  key: K,
  label: L,
  value: V,
): SelectOption[] =>
  elements.map(element => {
    const { children = [], ...otherElements } = element;

    return {
      ...otherElements,
      key: element[key],
      label: element[label],
      value: element[value],
      children:
        children.length > 0
          ? generateSelectOptions(children, key, label, value)
          : undefined,
    };
  });

const generateTreeSelectData = <
  T extends BaseTreeOption<T>,
  K extends keyof T,
  L extends keyof T,
  V extends keyof T,
>(
  elements: T[],
  key: K,
  label: L,
  value: V,
): TreeSelectSource[] =>
  elements.map(element => {
    const { children = [], ...otherElements } = element;

    return {
      ...otherElements,
      key: element[key],
      label: element[label],
      value: element[value],
      children:
        children.length > 0
          ? generateTreeSelectData(children, key, label, value)
          : undefined,
    };
  });

const generateTableDataSource = <
  T extends BaseTreeOption<T>,
  K extends keyof T,
>(
  elements: T[],
  key: K,
): TableSource<T>[] =>
  elements.map(element => {
    const { children = [], ...otherElements } = element;

    return {
      ...otherElements,
      key: element[key],
      children:
        children.length > 0
          ? generateTableDataSource(children, key)
          : undefined,
    };
  });

const findElementByKey = <T, K extends keyof T>(
  elements: T[],
  key: K,
  value: T[K],
) => elements.find(element => element[key] === value);

const findTreeElementByKey = <T extends BaseTreeOption<T>, K extends keyof T>(
  elements: T[],
  key: K,
  value: T[K],
): T | undefined => {
  for (const element of elements) {
    const { children } = element;
    if (element[key] === value) return element;

    if (children) {
      const foundedElement = findTreeElementByKey(children, key, value);
      if (foundedElement) return foundedElement;
    }
  }
  return undefined;
};

export {
  findElementByKey,
  findTreeElementByKey,
  generateBase64,
  generateFileUrl,
  generateRequestUrl,
  generateResponseError,
  generateSelectOptions,
  generateSubProductTitle,
  generateTableDataSource,
  generateTreeSelectData,
  generateUniqueElements,
  generateVariableSubProductTitle,
};
