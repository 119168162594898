import * as React from 'react';

import { useDebounceFn } from 'ahooks';
import { Col, Flex, Form, Input, Row, Typography } from 'antd';

import { ImgCropUpload, UploadOutlinedIcon } from 'components';
import { useUserAction, useUserValue } from 'context';
import type { FileOwnerType } from 'types';
import {
  generateFileUrl,
  generateRequestUrl,
  inputRule,
  PATHNAME,
} from 'utils';

const {
  QUERY_PARAM: {
    DEFAULT: { AT_SIGN: DEFAULT_AT_SIGN },
  },
} = PATHNAME;

type FieldType = Pick<
  ReturnType<typeof useUserValue>,
  | 'firstName'
  | 'lastName'
  | 'displayName'
  | 'userName'
  | 'phoneNumber'
  | 'telNumber'
  | 'email'
  | 'avatarId'
>;

const UserFormContent = () => {
  const {
    firstName = '',
    lastName,
    displayName,
    userName,
    phoneNumber,
    telNumber,
    email,
    avatarId,
    avatarStatus,
  } = useUserValue();
  const action = useUserAction();

  const { run: debounceUpdateFirstName } = useDebounceFn(
    (debouncedFirstName: typeof firstName) => {
      action({
        type: 'UPDATE_USER_FIRST_NAME',
        payload: {
          firstName: debouncedFirstName,
        },
      });
    },
  );
  const { run: debounceUpdateDisplayName } = useDebounceFn(
    (debouncedDisplayName: typeof displayName) => {
      action({
        type: 'UPDATE_USER_DISPLAY_NAME',
        payload: {
          displayName: debouncedDisplayName,
        },
      });
    },
  );

  const uploadOwnerType: FileOwnerType = 'User';
  const uploadAction = generateRequestUrl('adminUser/uploadUserFile');

  return (
    <Row className='rounded bg-white px-6 py-8 lg:basis-[70%]'>
      <Col span={24} lg={8} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='نشان‌واره'
          name='avatarId'
          valuePropName='file'
        >
          <ImgCropUpload
            action={uploadAction}
            fileList={
              avatarId
                ? [
                    {
                      uid: avatarId,
                      name: firstName,
                      status: 'done',
                      url: generateFileUrl(uploadOwnerType, avatarId, 200, 200),
                    },
                  ]
                : undefined
            }
            onChange={({ file: { status, response } }) => {
              action({
                type: 'UPDATE_USER_AVATAR',
                payload: {
                  avatarId: undefined,
                  avatarStatus: true,
                },
              });

              if (status === 'done' && response) {
                const avatarId = response.id;
                action({
                  type: 'UPDATE_USER_AVATAR',
                  payload: {
                    avatarId,
                    avatarStatus: true,
                  },
                });
              }

              if (status === 'error' || status === 'removed') {
                action({
                  type: 'UPDATE_USER_AVATAR',
                  payload: {
                    avatarId: undefined,
                    avatarStatus: false,
                  },
                });
              }
            }}
          >
            {!avatarStatus ? (
              <Flex vertical justify='center' align='center' gap='small'>
                <UploadOutlinedIcon className='text-xl text-[var(--ant-color-link-hover)]' />
                <Typography.Paragraph>
                  نشان‌واره کاربر را بار‌گذاری کنید
                </Typography.Paragraph>
              </Flex>
            ) : null}
          </ImgCropUpload>
        </Form.Item>
      </Col>

      <Col span={24} lg={16} className='lg:ps-3'>
        <Row>
          <Col span={24} lg={12} className='lg:pe-3'>
            <Form.Item<FieldType>
              label='نام'
              name='firstName'
              rules={[inputRule.required, inputRule.string]}
            >
              <Input
                placeholder='نام کاربر را وارد کنید'
                onChange={({ target: { value } }) => {
                  debounceUpdateFirstName(value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} className='lg:ps-3'>
            <Form.Item<FieldType>
              label='نام خانوادگی'
              name='lastName'
              rules={[inputRule.string]}
              initialValue={lastName}
            >
              <Input placeholder='نام خانوادگی کاربر را وارد کنید' />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} className='lg:pe-3'>
            <Form.Item<FieldType>
              label='نام نمایشی'
              name='displayName'
              rules={[inputRule.string]}
            >
              <Input
                placeholder='نام نمایشی کاربر را وارد کنید'
                onChange={({ target: { value } }) => {
                  debounceUpdateDisplayName(value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} className='lg:ps-3'>
            <Form.Item<FieldType>
              label='نام کاربری'
              name='userName'
              rules={[inputRule.userName]}
              initialValue={userName}
            >
              <Input
                placeholder='نام کاربری کاربر را وارد کنید'
                addonAfter={DEFAULT_AT_SIGN}
                classNames={{
                  input: '[&:not([value=""])]:[direction:ltr]',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={24} lg={8} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='ایمیل'
          name='email'
          rules={[inputRule.email]}
          initialValue={email}
        >
          <Input placeholder='ایمیل کاربر را وارد کنید' />
        </Form.Item>
      </Col>

      <Col span={24} lg={8} className='lg:px-3'>
        <Form.Item<FieldType>
          label='شماره همراه'
          name='phoneNumber'
          rules={[inputRule.phoneNumber]}
          initialValue={phoneNumber}
        >
          <Input placeholder='شماره همراه کاربر را وارد کنید' />
        </Form.Item>
      </Col>

      <Col span={24} lg={8} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='شماره ثابت'
          name='telNumber'
          rules={[inputRule.telNumber]}
          initialValue={telNumber}
        >
          <Input placeholder='شماره ثابت کاربر را وارد کنید' />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { UserFormContent };
