import * as React from 'react';

import { Button, Flex, Input, Typography } from 'antd';

import { AntdRouterLink, PlusOutlinedIcon } from 'components';
import { PATHNAME } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    PACKAGING_MODELS: { CREATE_URL: PACKAGING_MODELS_CREATE_URL },
  },
} = PATHNAME;

type PackagingModelsHeaderProps = {
  total: number;
  searchKey: string;
  handleSearchKey: (searchKey: string) => void;
};

const PackagingModelsHeader = ({
  total,
  searchKey,
  handleSearchKey,
}: PackagingModelsHeaderProps) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='mb-0 py-1'>
      <span className='text-base'>بسته‌بندی‌ها</span>
      <span className='text-sm text-slate-400'> ({total})</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      <Input.Search
        allowClear
        styles={{
          affixWrapper: {
            height: '2.5rem',
          },
        }}
        placeholder='جستجو بسته‌بندی‌ها'
        defaultValue={searchKey}
        onSearch={value => handleSearchKey(value)}
      />

      <AntdRouterLink
        to={`${PACKAGING_MODELS_CREATE_URL}${DEFAULT_SLUG_SEPARATOR}`}
        className='max-lg:basis-full'
      >
        <Button
          type='primary'
          className='w-full'
          icon={<PlusOutlinedIcon className='text-xs' />}
        >
          بسته‌بندی جدید
        </Button>
      </AntdRouterLink>
    </Flex>
  </Flex>
);

export { PackagingModelsHeader };
