import { Upload } from 'lib';

type RequestBody = StrictOmit<Parameters<typeof Upload>[number], 'url'>;

const uploadCategoryFile = async (body: RequestBody) =>
  await Upload({
    url: 'adminCategory/uploadCategoryFile',
    ...body,
  });

export { uploadCategoryFile };
