import type {
  ComponentMode,
  Product,
  ProductPackaging,
  ProductStatus,
  SubProduct,
} from 'types';

type State = Partial<
  Product &
    Record<'mode', ComponentMode> &
    Record<'step', 1 | 2 | 3 | 4 | 5 | 6> &
    Record<'selectedSubProduct', SubProduct> &
    Record<'selectedPackage', ProductPackaging> &
    Record<'defaultStatus', ProductStatus> &
    Record<'defaultName', string>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_PRODUCT_NAME';
      payload: Pick<typeof initialState, 'name'>;
    }
  | {
      type: 'UPDATE_PRODUCT_IDENTIFIER';
      payload: Pick<typeof initialState, 'identifier'>;
    }
  | {
      type: 'UPDATE_PRODUCT_SLUG';
      payload: Pick<typeof initialState, 'slug'>;
    }
  | {
      type: 'UPDATE_PRODUCT_BRAND';
      payload: Pick<typeof initialState, 'brand'>;
    }
  | {
      type: 'UPDATE_PRODUCT_MAIN_CATEGORY';
      payload: Pick<typeof initialState, 'mainCategory'>;
    }
  | {
      type: 'UPDATE_PRODUCT_OTHER_CATEGORIES';
      payload: Pick<typeof initialState, 'otherCategories'>;
    }
  | {
      type: 'UPDATE_PRODUCT_DESCRIPTION';
      payload: Pick<typeof initialState, 'description'>;
    }
  | {
      type: 'UPDATE_PRODUCT_BASE_INFO';
      payload: Pick<typeof initialState, 'callForPrice' | 'showPostingStatus'>;
    }
  | {
      type: 'UPDATE_PRODUCT_TYPE';
      payload: Pick<typeof initialState, 'productType'>;
    }
  | {
      type: 'UPDATE_PRODUCT_VARIABLE_ATTRIBUTES';
      payload: Pick<typeof initialState, 'variableAttributes'>;
    }
  | {
      type: 'UPDATE_SUB_PRODUCTS';
      payload: Pick<typeof initialState, 'subProducts' | 'selectedSubProduct'>;
    }
  | {
      type: 'UPDATE_PRODUCT_BASE_ATTRIBUTES';
      payload: Pick<typeof initialState, 'baseAttributes'>;
    }
  | {
      type: 'UPDATE_PRODUCT_PICTURES';
      payload: Pick<typeof initialState, 'pictures'>;
    }
  | {
      type: 'UPDATE_PRODUCT_DOCUMENTS';
      payload: Pick<typeof initialState, 'documents'>;
    }
  | {
      type: 'UPDATE_PRODUCT_PACKAGING_INFO';
      payload: Pick<typeof initialState, 'packagingInfo' | 'selectedPackage'>;
    }
  | {
      type: 'UPDATE_PRODUCT_STEP';
      payload: Pick<typeof initialState, 'step'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
