import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { BrandFormContext } from 'context';
import type { Brand as BrandConfig, ComponentMode } from 'types';

import { BrandForm, BrandHeader } from './components';

type BrandProps = {
  mode: ComponentMode;
};

export default function Brand({ mode }: BrandProps) {
  const data = useLoaderData() as BrandConfig;

  const updateMode = mode === 'Update';
  const title = updateMode ? data.title : 'افزودن برند جدید';
  const defaultTitle = updateMode ? data.title : '';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <BrandHeader>{title}</BrandHeader>

      <BrandFormContext
        mode={mode}
        step={1}
        defaultTitle={defaultTitle}
        {...(updateMode && data)}
      >
        <BrandForm />
      </BrandFormContext>
    </>
  );
}
