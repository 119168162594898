import * as React from 'react';

import { useMemoizedFn } from 'ahooks';
import { Typography } from 'antd';

import { useAttributeAction, useAttributeValue } from 'context';
import { useCopyToClipboard } from 'hooks';

import { AttributeFormCategory } from './category';
import { AttributeFormInformation } from './information';

const AttributeFormContent = () => {
  const { type, categories = [] } = useAttributeValue();
  const { copiedText, copyToClipboard } = useCopyToClipboard();
  const action = useAttributeAction();

  const handleSelectedCategories = useMemoizedFn(
    (selectedCategories: typeof categories) => {
      const existedCategories = categories.filter(category =>
        selectedCategories.some(
          updatedCategory => updatedCategory.id === category.id,
        ),
      );
      const latestCategories = selectedCategories.filter(
        updatedCategory =>
          !categories.some(category => category.id === updatedCategory.id),
      );

      action({
        type: 'UPDATE_ATTRIBUTE_CATEGORIES',
        payload: {
          categories: [...existedCategories, ...latestCategories],
        },
      });
    },
  );

  const handleCopyToClipboard = useMemoizedFn((text: string) => {
    copyToClipboard(text);
  });

  const isSelect = type === 'Select';
  const isCheckBox = type === 'CheckBox';

  return (
    <div className='space-y-8 lg:basis-[70%]'>
      <AttributeFormInformation
        handleSelectedCategories={handleSelectedCategories}
      />

      <div className='space-y-3'>
        <Typography.Title level={2} className='mb-4 indent-2 text-base'>
          دسته‌بندی‌های اختصاص یافته
        </Typography.Title>

        {isSelect || isCheckBox
          ? categories.map(category => (
              <AttributeFormCategory
                key={category.id}
                copiedText={copiedText}
                handleCopyToClipboard={handleCopyToClipboard}
                {...category}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export { AttributeFormContent };
