import * as React from 'react';

import useUrlState from '@ahooksjs/use-url-state';
import { useLockFn, useSafeState } from 'ahooks';
import { Button, Form, Input, notification, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { MobileOutlinedIcon } from 'components';
import { useLoginValue } from 'context';
import { signinWithPassword } from 'services';
import { inputRule, PATHNAME } from 'utils';

const {
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
  },
  QUERY_PARAM: {
    DEFAULT: { LOGIN_TYPE: DEFAULT_LOGIN_TYPE },
    TYPE: { BASE_URL: TYPE_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<
  ReturnType<typeof useLoginValue>,
  'phoneNumber' | 'password'
>;

const LoginDefaultForm = () => {
  const [, setUrlState] = useUrlState(
    {
      [TYPE_BASE_URL]: '',
    },
    { navigateMode: 'replace' },
  );
  const [message, setMessage] = useSafeState<string>();
  const [form] = Form.useForm<FieldType>();
  const navigate = useNavigate();

  const onFinish = useLockFn(
    async ({ phoneNumber = '', password = '' }: FieldType) => {
      signinWithPassword({ phoneNumber, password })
        .then(() => {
          notification.success({
            message: 'با موفقیت وارد شدید.',
          });
          navigate(HOME_BASE_URL);
        })
        .catch(message => {
          setMessage(message);
        });
    },
  );

  return (
    <div className='basis-1/2 space-y-8'>
      <Typography.Title level={2} className='text-xl font-bold'>
        ورود به کاشی لند
      </Typography.Title>

      <Form
        form={form}
        layout='vertical'
        requiredMark={false}
        autoComplete='off'
        onFinish={onFinish}
      >
        <Form.Item<FieldType>
          label='شماره موبایل'
          name='phoneNumber'
          rules={[inputRule.required, inputRule.phoneNumber]}
        >
          <Input
            placeholder='برای مثال ۰۹۱۲۳۴۵۶۷۸۹'
            prefix={
              <MobileOutlinedIcon
                className='text-lg'
                style={{ color: 'var(--ant-color-text-placeholder)' }}
              />
            }
          />
        </Form.Item>

        <Form.Item<FieldType>
          label='رمز عبور'
          name='password'
          rules={[
            inputRule.required,
            inputRule.nonWhitespace,
            inputRule.password,
          ]}
        >
          <Input.Password
            placeholder='برای مثال ۱۲۳۴۵۶۷۸۹'
            prefix={
              <MobileOutlinedIcon
                className='text-lg'
                style={{ color: 'var(--ant-color-text-placeholder)' }}
              />
            }
          />
        </Form.Item>

        <Typography.Paragraph
          type='danger'
          className='-mt-4 mb-2 px-2 text-center empty:my-12'
        >
          {message}
        </Typography.Paragraph>

        <Form.Item className='mb-4'>
          <Button type='primary' htmlType='submit' block>
            ورود
          </Button>
        </Form.Item>

        <Form.Item className='mb-0'>
          <Button
            block
            onClick={() => {
              setUrlState({ [TYPE_BASE_URL]: DEFAULT_LOGIN_TYPE });
            }}
          >
            ورود با رمز یک‌بار مصرف
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { LoginDefaultForm };
