import { initialAction, initialState } from './initial';

type Reducer = (
  state: typeof initialState,
  action: typeof initialAction,
) => typeof initialState;

const reducer: Reducer = (state, action) => state;

export { reducer };
