import * as React from 'react';

import { useUpdateEffect } from 'ahooks';
import { Button, Dropdown, Flex, Modal, notification, Table } from 'antd';

import {
  AntdRouterLink,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import { useMediaQuery } from 'hooks';
import { deleteBrand } from 'services';
import type { BrandSummary } from 'types';
import { adjustPageNumber, generateTableDataSource, PATHNAME } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    BRANDS: { UPDATE_URL: BRANDS_UPDATE_URL },
  },
} = PATHNAME;

type BrandsTableProps = {
  loading: boolean;
  total: number;
  brands: BrandSummary[];
  pageSize: number;
  pageNumber: number;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const BrandsTable = React.memo<BrandsTableProps>(
  ({
    loading,
    total,
    brands,
    pageSize,
    pageNumber,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: BrandsTableProps) => {
    const [modal, contextHolder] = Modal.useModal();
    const responsive = useMediaQuery();

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteBrand = async (id: string) => {
      deleteBrand({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const brandsDataSource = generateTableDataSource(brands, 'id');

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: !responsive.lg || undefined, y: 700 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
            render: (_, { id, title }) => (
              <AntdRouterLink
                ellipsis
                to={`${BRANDS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
              >
                {title}
              </AntdRouterLink>
            ),
          },
          {
            title: 'پیوند یکتا',
            dataIndex: 'slug',
            key: 'slug',
            ellipsis: true,
            render: (_, { slug }) => <bdi>{slug}</bdi>,
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, title }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-brand',
                        label: (
                          <AntdRouterLink
                            to={`${BRANDS_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>ویرایش برند</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-brand',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='my-0.5 flex items-center px-0'
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف برند "${title}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteBrand(id);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span className='text-sm'>حذف برند</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='pb-[18px] pt-3.5'>
                    <MoreOutlinedIcon className='text-xl' />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={brandsDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
BrandsTable.displayName = 'BrandsTable';

export { BrandsTable };
