import * as React from 'react';

import { PurchaseActionContext, PurchaseValueContext } from './context';
import { usePurchaseAction, usePurchaseValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const PurchaseFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <PurchaseValueContext.Provider value={state}>
      <PurchaseActionContext.Provider value={dispatch}>
        {children}
      </PurchaseActionContext.Provider>
    </PurchaseValueContext.Provider>
  );
};

export { PurchaseFormContext, usePurchaseAction, usePurchaseValue };
