import * as React from 'react';

import { Flex, Menu } from 'antd';
import { useLocation } from 'react-router-dom';

import {
  AntdRouterLink,
  ApartmentOutlinedIcon,
  AppstoreOutlinedIcon,
  CommentOutlinedIcon,
  DashboardOutlinedIcon,
  DeploymentUnitOutlinedIcon,
  FileSearchOutlinedIcon,
  FolderOpenOutlinedIcon,
  KashilandLogo,
  KashilandLogoIcon,
  NodeIndexOutlinedIcon,
  PercentageOutlinedIcon,
  PieChartOutlinedIcon,
  SettingOutlinedIcon,
  ShoppingCartOutlinedIcon,
  ShoppingOutlinedIcon,
  TagsOutlinedIcon,
  TeamOutlinedIcon,
  UngroupOutlinedIcon,
  UnorderedListOutlinedIcon,
} from 'components';
import { PATHNAME } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
    PRODUCTS: { BASE_URL: PRODUCTS_BASE_URL },
    PURCHASES: { BASE_URL: PURCHASES_BASE_URL },
    DISCOUNT_CODES: { BASE_URL: DISCOUNT_CODES_BASE_URL },
    USERS: { BASE_URL: USERS_BASE_URL },
    BRANDS: { BASE_URL: BRANDS_BASE_URL },
    CATEGORIES: { BASE_URL: CATEGORIES_BASE_URL },
    PACKAGING_MODELS: { BASE_URL: PACKAGING_MODELS_BASE_URL },
    ATTRIBUTES: { BASE_URL: ATTRIBUTES_BASE_URL },
    PACKAGE_MANAGEMENT: { BASE_URL: PACKAGE_MANAGEMENT_BASE_URL },
    GLOBAL_EDIT: { BASE_URL: GLOBAL_EDIT_BASE_URL },
    MARKETING: { BASE_URL: MARKETING_BASE_URL },
    REPORTS: { BASE_URL: REPORTS_BASE_URL },
    LOGISTIC: { BASE_URL: LOGISTIC_BASE_URL },
    COMMENTS_MANAGEMENT: {
      BASE_URL: COMMENTS_MANAGEMENT_BASE_URL,
      PRODUCTS_COMMENTS: { BASE_URL: PRODUCTS_COMMENTS_BASE_URL },
      QUESTIONS: { BASE_URL: QUESTIONS_BASE_URL },
    },
    LAYOUT_SETTING: {
      BASE_URL: LAYOUT_SETTING_BASE_URL,
      MEGA_MENUS: { BASE_URL: MEGA_MENUS_BASE_URL },
    },
    SEO_SETTING: { BASE_URL: SEO_SETTING_BASE_URL },
  },
} = PATHNAME;

type PrivateSidebarProps = {
  collapsed: boolean;
};

const PrivateSidebar = ({ collapsed }: PrivateSidebarProps) => {
  const { pathname } = useLocation();

  const splitedPath = pathname.split(DEFAULT_SLUG_SEPARATOR).filter(Boolean);
  const selectedKeys =
    splitedPath.length > 0
      ? splitedPath.map(
          (_, index) =>
            `${DEFAULT_SLUG_SEPARATOR}${splitedPath.slice(0, index + 1).join(DEFAULT_SLUG_SEPARATOR)}${DEFAULT_SLUG_SEPARATOR}`,
        )
      : [DEFAULT_SLUG_SEPARATOR];

  const defaultIconProps: React.ComponentPropsWithoutRef<'span'> = {
    style: { fontSize: '1rem', marginInlineStart: '0.25rem' },
  };

  return (
    <>
      <Flex justify='center' align='center' className='py-4'>
        {collapsed ? <KashilandLogoIcon /> : <KashilandLogo />}
      </Flex>

      <Menu
        mode='inline'
        className='py-4'
        items={[
          {
            label: <AntdRouterLink to={HOME_BASE_URL}>داشبورد</AntdRouterLink>,
            key: HOME_BASE_URL,
            icon: <DashboardOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink to={PRODUCTS_BASE_URL}>محصول‌ها</AntdRouterLink>
            ),
            key: PRODUCTS_BASE_URL,
            icon: <ShoppingOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink to={PURCHASES_BASE_URL}>سفارش‌ها</AntdRouterLink>
            ),
            key: PURCHASES_BASE_URL,
            icon: <ShoppingCartOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink disabled to={DISCOUNT_CODES_BASE_URL}>
                تخفیف‌ها
              </AntdRouterLink>
            ),
            key: DISCOUNT_CODES_BASE_URL,
            icon: <PercentageOutlinedIcon {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: <AntdRouterLink to={USERS_BASE_URL}>کاربرها</AntdRouterLink>,
            key: USERS_BASE_URL,
            icon: <TeamOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: <AntdRouterLink to={BRANDS_BASE_URL}>برندها</AntdRouterLink>,
            key: BRANDS_BASE_URL,
            icon: <AppstoreOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink to={CATEGORIES_BASE_URL}>
                دسته‌بندی‌ها
              </AntdRouterLink>
            ),
            key: CATEGORIES_BASE_URL,
            icon: <ApartmentOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink to={PACKAGING_MODELS_BASE_URL}>
                بسته‌بندی‌ها
              </AntdRouterLink>
            ),
            key: PACKAGING_MODELS_BASE_URL,
            icon: <DeploymentUnitOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink to={ATTRIBUTES_BASE_URL}>فیلد‌ها</AntdRouterLink>
            ),
            key: ATTRIBUTES_BASE_URL,
            icon: <UnorderedListOutlinedIcon {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink disabled to={PACKAGE_MANAGEMENT_BASE_URL}>
                مدیریت پکیج
              </AntdRouterLink>
            ),
            key: PACKAGE_MANAGEMENT_BASE_URL,
            icon: <FolderOpenOutlinedIcon {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={GLOBAL_EDIT_BASE_URL}>
                ویرایش انبوه
              </AntdRouterLink>
            ),
            key: GLOBAL_EDIT_BASE_URL,
            icon: <UngroupOutlinedIcon {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={MARKETING_BASE_URL}>
                بازاریابی
              </AntdRouterLink>
            ),
            key: MARKETING_BASE_URL,
            icon: <TagsOutlinedIcon {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={REPORTS_BASE_URL}>
                گزارش‌ها
              </AntdRouterLink>
            ),
            key: REPORTS_BASE_URL,
            icon: <PieChartOutlinedIcon {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={LOGISTIC_BASE_URL}>
                لجستیک
              </AntdRouterLink>
            ),
            key: LOGISTIC_BASE_URL,
            icon: <NodeIndexOutlinedIcon {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: 'مدیریت نظرها',
            key: COMMENTS_MANAGEMENT_BASE_URL,
            icon: <CommentOutlinedIcon {...defaultIconProps} />,
            children: [
              {
                label: (
                  <AntdRouterLink
                    to={`${COMMENTS_MANAGEMENT_BASE_URL}${PRODUCTS_COMMENTS_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`}
                  >
                    دیدگاه کاربرها
                  </AntdRouterLink>
                ),
                key: `${COMMENTS_MANAGEMENT_BASE_URL}${PRODUCTS_COMMENTS_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`,
              },
              {
                label: (
                  <AntdRouterLink
                    disabled
                    to={`${COMMENTS_MANAGEMENT_BASE_URL}${QUESTIONS_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`}
                  >
                    پرسش و پاسخ
                  </AntdRouterLink>
                ),
                key: `${COMMENTS_MANAGEMENT_BASE_URL}${QUESTIONS_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`,
                disabled: true,
              },
            ],
          },
          {
            label: 'تنظیمات قالب',
            key: LAYOUT_SETTING_BASE_URL,
            icon: <SettingOutlinedIcon {...defaultIconProps} />,
            disabled: true,
            children: [
              {
                label: (
                  <AntdRouterLink
                    disabled
                    to={`${LAYOUT_SETTING_BASE_URL}${MEGA_MENUS_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`}
                  >
                    مگامنوها
                  </AntdRouterLink>
                ),
                key: `${LAYOUT_SETTING_BASE_URL}${MEGA_MENUS_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`,
                disabled: true,
              },
            ],
          },
          {
            label: (
              <AntdRouterLink disabled to={SEO_SETTING_BASE_URL}>
                تنظیمات سئو
              </AntdRouterLink>
            ),
            key: SEO_SETTING_BASE_URL,
            icon: <FileSearchOutlinedIcon {...defaultIconProps} />,
            disabled: true,
          },
        ]}
        selectedKeys={selectedKeys}
      />
    </>
  );
};

export { PrivateSidebar };
