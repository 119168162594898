import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { PackagingModelFormContext } from 'context';
import type {
  ComponentMode,
  PackagingModel as PackagingModelConfig,
} from 'types';

import { PackagingModelForm, PackagingModelHeader } from './components';

type PackagingModelProps = {
  mode: ComponentMode;
};

export default function PackagingModel({ mode }: PackagingModelProps) {
  const data = useLoaderData() as PackagingModelConfig;

  const updateMode = mode === 'Update';
  const title = updateMode ? data.name : 'افزودن بسته‌بندی جدید';
  const defaultName = updateMode ? data.name : '';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <PackagingModelHeader>{title}</PackagingModelHeader>

      <PackagingModelFormContext
        mode={mode}
        defaultName={defaultName}
        {...(updateMode && data)}
      >
        <PackagingModelForm />
      </PackagingModelFormContext>
    </>
  );
}
