/* eslint-disable react/prop-types */
import * as React from 'react';

import { Typography } from 'antd';
import { useHref, useLinkClickHandler } from 'react-router-dom';

const { Link } = Typography;

type AntdRouterLinkProps = StrictOmit<
  React.ComponentPropsWithoutRef<typeof Link>,
  'href' | 'target'
> &
  Record<'to', Parameters<typeof useLinkClickHandler>[0]> &
  DeepPartial<
    Record<'clickHandler', Parameters<typeof useLinkClickHandler>[1]>
  >;

const AntdRouterLink = React.forwardRef<
  React.ElementRef<typeof Link>,
  AntdRouterLinkProps
>(({ to, clickHandler, onClick, ...props }, ref) => {
  const href = useHref(to, { relative: clickHandler?.relative });
  const handleClick = useLinkClickHandler(to, clickHandler);

  return (
    <Link
      ref={ref}
      href={href}
      target={clickHandler?.target}
      onClick={event => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
        }
      }}
      {...props}
    />
  );
});
AntdRouterLink.displayName = 'AntdRouterLink';

const AntdRouterExternalLink = React.forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link>
>(
  (
    { target = '_blank', rel = 'noopener noreferrer nofollow', ...props },
    ref,
  ) => <Link ref={ref} target={target} rel={rel} {...props} />,
);
AntdRouterExternalLink.displayName = 'AntdRouterExternalLink';

export { AntdRouterExternalLink, AntdRouterLink };
