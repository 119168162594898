import * as React from 'react';

import { Button, Divider, Form, Modal, notification, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlinedIcon } from 'components';
import { usePackagingModelValue } from 'context';
import { deletePackagingModel } from 'services';
import { PATHNAME } from 'utils';

const {
  LOCAL: {
    PACKAGING_MODELS: { BASE_URL: PACKAGING_MODELS_BASE_URL },
  },
} = PATHNAME;

const PackagingModelFormAction = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { id = '', defaultName, mode } = usePackagingModelValue();
  const navigate = useNavigate();

  const handleDeletePackagingModel = async (id: string) => {
    deletePackagingModel({ id })
      .then(() => {
        navigate(PACKAGING_MODELS_BASE_URL);
      })
      .catch(message => {
        notification.error({ message });
      });
  };

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='mb-0 text-base font-medium'>
          انتشار بسته‌بندی
        </Typography.Title>
      </div>

      <Divider className='mb-8 mt-4' />

      <div className='px-6'>
        <Typography.Paragraph>
          از طریق این بخش می‌توانید بسته‌بندی ساخته شده و یا ویرایش شده را منتشر
          کنید.
        </Typography.Paragraph>
      </div>

      <div className='flex items-center gap-2 p-6 *:m-0 *:basis-full'>
        {mode === 'Update' ? (
          <Form.Item>
            <Button
              block
              danger
              type='primary'
              className='flex items-center'
              onClick={async () => {
                const confirmed = await modal.confirm({
                  title: `آیا از حذف بسته‌بندی "${defaultName}" اطمینان دارید؟`,
                  width: 500,
                });

                if (confirmed) {
                  handleDeletePackagingModel(id);
                }
              }}
            >
              <DeleteOutlinedIcon />
              <span className='text-sm'>حذف بسته‌بندی</span>
            </Button>

            {contextHolder}
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {mode === 'Create' ? 'افزودن بسته‌بندی جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export { PackagingModelFormAction };
