import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { CategoryFormContext } from 'context';
import type { Category as CategoryConfig, ComponentMode } from 'types';

import { CategoryForm, CategoryHeader } from './components';

type CategoryProps = {
  mode: ComponentMode;
};

export default function Category({ mode }: CategoryProps) {
  const data = useLoaderData() as CategoryConfig;

  const updateMode = mode === 'Update';
  const title = updateMode ? data.title : 'افزودن دسته‌بندی‌ جدید';
  const defaultTitle = updateMode ? data.title : '';
  const defaultStatus: typeof data.status = updateMode ? data.status : 'Active';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <CategoryHeader>{title}</CategoryHeader>

      <CategoryFormContext
        mode={mode}
        step={1}
        defaultTitle={defaultTitle}
        defaultStatus={defaultStatus}
        {...(updateMode && data)}
      >
        <CategoryForm />
      </CategoryFormContext>
    </>
  );
}
