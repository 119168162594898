import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useProductCommentValue } from 'context';
import { updateProductComment } from 'services';
import { PATHNAME } from 'utils';

import { ProductCommentFormAction } from './action';
import { ProductCommentFormContent } from './content';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    COMMENTS_MANAGEMENT: {
      BASE_URL: COMMENTS_MANAGEMENT_BASE_URL,
      PRODUCTS_COMMENTS: { BASE_URL: PRODUCTS_COMMENTS_BASE_URL },
    },
  },
} = PATHNAME;

type FieldType = Pick<ReturnType<typeof useProductCommentValue>, 'status'>;

const ProductCommentForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    content = '',
    defaultName,
    defaultStatus,
    mode,
  } = useProductCommentValue();
  const navigate = useNavigate();

  const onFinish = useLockFn(async ({ status = defaultStatus! }: FieldType) => {
    const params: Parameters<typeof updateProductComment>[0] = {
      id,
    };
    const body: Parameters<typeof updateProductComment>[1] = {
      content,
      status,
    };

    switch (mode) {
      case 'Update': {
        return updateProductComment(params, body)
          .then(() => {
            notification.success({
              message: `دیدگاه ${defaultName} با موفقیت ویرایش شد.`,
            });
            navigate(
              `${COMMENTS_MANAGEMENT_BASE_URL}${PRODUCTS_COMMENTS_BASE_URL}${DEFAULT_SLUG_SEPARATOR}`,
            );
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      default:
        break;
    }
  });

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      autoComplete='off'
      style={{
        marginBlock: 12,
      }}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col lg:items-start' gap='large'>
        <ProductCommentFormContent />
        <ProductCommentFormAction />
      </Flex>
    </Form>
  );
};

export { ProductCommentForm };
