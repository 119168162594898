import * as React from 'react';

import { usePrevious, useRequest, useUpdateEffect } from 'ahooks';
import { Tabs } from 'antd';

import { useProductAction, useProductValue } from 'context';
import { getCategory } from 'services';

import { ProductAttributesInfo } from './attributes-info';
import { ProductBaseInfo } from './base-info';
import { ProductFilesInfo } from './files-info';
import { ProductModelsInfo } from './models-info';
import { ProductPackagingInfo } from './packaging-info';
import { ProductSeoInfo } from './seo-info';

const ProductFormTabs = () => {
  const { runAsync } = useRequest(getCategory, {
    manual: true,
  });
  const {
    mainCategory,
    productType,
    variableAttributes = [],
    baseAttributes = [],
    subProducts = [],
    packagingInfo = [],
    step = 1,
  } = useProductValue();
  const previousMainCategory = usePrevious(mainCategory);
  const action = useProductAction();

  React.useEffect(() => {
    if (mainCategory) {
      runAsync({ id: mainCategory.id }).then(({ attributes }) => {
        action({
          type: 'UPDATE_PRODUCT_BASE_ATTRIBUTES',
          payload: {
            baseAttributes: attributes.map(attribute => ({
              ...attribute,
              selectedValues:
                baseAttributes.find(
                  baseAttribute => baseAttribute.id === attribute.id,
                )?.selectedValues ?? [],
            })),
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategory]);

  useUpdateEffect(() => {
    const isModifiedMainCategory =
      mainCategory?.id !== previousMainCategory?.id;

    action({
      type: 'UPDATE_PRODUCT_VARIABLE_ATTRIBUTES',
      payload: {
        variableAttributes: !isModifiedMainCategory ? variableAttributes : [],
      },
    });
    action({
      type: 'UPDATE_SUB_PRODUCTS',
      payload: {
        subProducts: !(isModifiedMainCategory && productType === 'Variable')
          ? subProducts
          : [],
        selectedSubProduct: undefined,
      },
    });
    action({
      type: 'UPDATE_PRODUCT_PACKAGING_INFO',
      payload: {
        packagingInfo: !isModifiedMainCategory ? packagingInfo : [],
        selectedPackage: undefined,
      },
    });
  }, [mainCategory, previousMainCategory]);

  return (
    <Tabs
      destroyInactiveTabPane
      items={[
        {
          key: '1',
          label: 'اطلاعات اولیه',
          children: <ProductBaseInfo />,
        },
        {
          key: '2',
          label: 'مدل‌ها',
          children: <ProductModelsInfo />,
          disabled: step < 2,
        },
        {
          key: '3',
          label: 'مشخصات فنی',
          children: <ProductAttributesInfo />,
          disabled: step < 3,
        },
        {
          key: '4',
          label: 'مستندات محصول',
          children: <ProductFilesInfo />,
          disabled: step < 4,
        },
        {
          key: '5',
          label: 'بسته‌بندی محصول',
          children: <ProductPackagingInfo />,
          disabled: step < 5,
        },
        {
          key: '6',
          label: 'تنظیمات سئو',
          children: <ProductSeoInfo />,
          disabled: step < 6,
        },
      ]}
      className='rounded bg-white lg:basis-[70%] [&>div]:px-6 first-of-type:[&>div]:pt-3 last-of-type:[&>div]:py-3'
      activeKey={step.toString()}
      onChange={activeKey => {
        action({
          type: 'UPDATE_PRODUCT_STEP',
          payload: {
            step: +activeKey as typeof step,
          },
        });
      }}
    />
  );
};

export { ProductFormTabs };
