import * as React from 'react';

import { useBoolean, useGetState, useLockFn, useMemoizedFn } from 'ahooks';
import {
  Button,
  Divider,
  List,
  Modal,
  notification,
  Space,
  TreeSelect,
  Typography,
} from 'antd';

import { MenuOutlinedIcon } from 'components';
import { DND_CSS, dndArrayMove, DndSortableContext, useDndSortable } from 'lib';
import { updateOrderOfCategories } from 'services';
import type { CategoryQueryResult } from 'types';
import {
  findTreeElementByKey,
  generateTreeSelectData,
  normalizeToString,
} from 'utils';

type CategoriesOrderListItemProps = CategoryQueryResult;

const CategoriesOrderListItem = ({
  id,
  title,
}: CategoriesOrderListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useDndSortable({
    id,
  });

  return (
    <List.Item
      ref={setNodeRef}
      className='bg-slate-50'
      style={{
        transform: DND_CSS.Transform.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
      }}
      {...attributes}
      {...listeners}
    >
      <List.Item.Meta avatar={<MenuOutlinedIcon />} title={title} />
    </List.Item>
  );
};

type CategoriesOrderListProps = {
  selectedCategories: CategoryQueryResult[];
  handleSelectedCategories: (categories: CategoryQueryResult[]) => void;
};

const CategoriesOrderList = React.memo<CategoriesOrderListProps>(
  ({
    selectedCategories,
    handleSelectedCategories,
  }: CategoriesOrderListProps) => (
    <DndSortableContext
      dnd={{
        onDragEnd: ({ active, over }) => {
          const activeId = active.id;
          const overId = over?.id;

          if (activeId !== overId) {
            const activeIndex = selectedCategories.findIndex(
              ({ id }) => id === activeId,
            );
            const overIndex = selectedCategories.findIndex(
              ({ id }) => id === overId,
            );
            const sortedSelectedCategories = dndArrayMove(
              selectedCategories,
              activeIndex,
              overIndex,
            );

            handleSelectedCategories(sortedSelectedCategories);
          }
        },
      }}
      sortable={{
        items: selectedCategories.map(({ id }) => id),
        children: (
          <List
            bordered
            size='large'
            className='max-h-[calc(100dvh-25rem)] overflow-y-auto'
            dataSource={selectedCategories}
            renderItem={item => <CategoriesOrderListItem {...item} />}
          />
        ),
      }}
    />
  ),
);
CategoriesOrderList.displayName = 'CategoriesOrderList';

type CategoriesOrderSelectProps = {
  loading: boolean;
  categories: CategoryQueryResult[];
  handleSelectedCategories: (categories: CategoryQueryResult[]) => void;
  handleSelectedCategory: (selectedCategory?: CategoryQueryResult) => void;
};

const CategoriesOrderSelect = React.memo<CategoriesOrderSelectProps>(
  ({
    loading,
    categories,
    handleSelectedCategories,
    handleSelectedCategory,
  }: CategoriesOrderSelectProps) => {
    const categoriesTreeData = generateTreeSelectData(
      categories,
      'id',
      'title',
      'id',
    );

    return (
      <TreeSelect<string>
        showSearch
        className='w-80'
        placeholder='گزینه ای را انتخاب کنید'
        loading={loading}
        treeData={categoriesTreeData}
        filterTreeNode={(inputValue, treeNode) =>
          normalizeToString(treeNode.label)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        }
        onChange={value => {
          const selectedCategory = findTreeElementByKey(
            categories,
            'id',
            value,
          );

          handleSelectedCategory(selectedCategory);

          if (selectedCategory) {
            handleSelectedCategories(selectedCategory.children);
          }
        }}
      />
    );
  },
);
CategoriesOrderSelect.displayName = 'CategoriesOrderSelect';

type CategoriesOrderProps = {
  loading: boolean;
  categories: CategoryQueryResult[];
  handleRefreshData: () => void;
};

const CategoriesOrder = React.memo<CategoriesOrderProps>(
  ({ loading, categories, handleRefreshData }: CategoriesOrderProps) => {
    const [open, { set: setOpen }] = useBoolean(false);
    const [confirmLoading, { set: setConfirmLoading }] = useBoolean(false);
    const [selectedCategories, setSelectedCategories] = useGetState<
      CategoryQueryResult[]
    >([]);
    const [selectedCategory, setSelectedCategory] =
      useGetState<CategoryQueryResult>();

    const handleSelectedCategories = useMemoizedFn(
      (categories: typeof selectedCategories) => {
        setSelectedCategories(categories);
      },
    );

    const handleSelectedCategory = useMemoizedFn(
      (category: typeof selectedCategory) => {
        setSelectedCategory(category);
      },
    );

    const handleOk = useLockFn(async () => {
      if (selectedCategory && selectedCategories.length > 0) {
        setConfirmLoading(true);

        updateOrderOfCategories(
          { parentId: selectedCategory.id },
          { categoriesIds: selectedCategories.map(({ id }) => id) },
        )
          .then(() => {
            handleRefreshData();
          })
          .catch(message => {
            notification.error({ message });
          })
          .finally(() => {
            setOpen(false);
            setConfirmLoading(false);
          });
      }
    });

    return (
      <>
        <Button className='max-lg:basis-full' onClick={() => setOpen(true)}>
          ترتیب دسته‌بندی‌ها
        </Button>

        <Modal
          centered
          width={600}
          title={
            <Typography.Title level={5} className='font-medium'>
              ترتیب دسته‌بندی‌ها
            </Typography.Title>
          }
          okText='ذخیره تغییرات'
          okButtonProps={{
            className: 'px-5',
          }}
          cancelButtonProps={{ style: { display: 'none' } }}
          classNames={{
            content: 'p-0',
            header: 'px-5 pt-4',
            footer: 'px-5 pb-3',
          }}
          open={open}
          confirmLoading={confirmLoading}
          onOk={handleOk}
          onCancel={() => setOpen(false)}
        >
          <Divider className='mt-4' />

          <div className='space-y-4 px-6'>
            <Space>
              <Typography.Text>دسته‌بندی</Typography.Text>
              <CategoriesOrderSelect
                loading={loading}
                categories={categories}
                handleSelectedCategories={handleSelectedCategories}
                handleSelectedCategory={handleSelectedCategory}
              />
            </Space>

            <CategoriesOrderList
              selectedCategories={selectedCategories}
              handleSelectedCategories={handleSelectedCategories}
            />
          </div>

          <Divider className='mb-1.5' />
        </Modal>
      </>
    );
  },
);
CategoriesOrder.displayName = 'CategoriesOrder';

export {
  CategoriesOrder,
  CategoriesOrderList,
  CategoriesOrderListItem,
  CategoriesOrderSelect,
};
