import * as React from 'react';

import { Col, Empty, Form, Input, Row, Select } from 'antd';

import { useProductValue } from 'context';
import { cn, inputRule } from 'utils';

type FieldType = Partial<Record<string, string | string[]>>;

const ProductAttributesInfo = () => {
  const {
    mainCategory,
    otherCategories = [],
    baseAttributes = [],
  } = useProductValue();

  return (
    <Row>
      {baseAttributes.length > 0 ? (
        baseAttributes.map(({ id, name, type, values }, index) => {
          const isText = type === 'Text';
          const isNumber = type === 'Number';
          const isCheckBox = type === 'CheckBox';
          const isSelect = type === 'Select';

          const relatedCategories = otherCategories.filter(
            ({ relatedAttribute }) =>
              relatedAttribute && relatedAttribute.id === id,
          );

          return (
            <Col
              key={id}
              span={24}
              lg={12}
              className={cn(index % 2 === 1 ? 'lg:ps-3' : 'lg:pe-3')}
            >
              <Form.Item<FieldType>
                label={name}
                name={`base-attribute-${id}`}
                rules={
                  isText
                    ? [inputRule.string]
                    : isNumber
                      ? [inputRule.digit]
                      : undefined
                }
              >
                {isText || isNumber ? (
                  <Input placeholder={`${name} محصول را وارد کنید`} />
                ) : isCheckBox || isSelect ? (
                  <Select
                    showSearch
                    allowClear={isSelect}
                    mode={isCheckBox ? 'multiple' : undefined}
                    placeholder={`${name} محصول را انتخاب کنید`}
                    disabled={
                      isSelect &&
                      relatedCategories.some(
                        ({ relatedAttribute }) =>
                          relatedAttribute &&
                          relatedAttribute.selectedValues.length > 0,
                      )
                    }
                    options={values.map(value => ({
                      label: value,
                      value,
                      disabled: relatedCategories.some(({ relatedAttribute }) =>
                        relatedAttribute?.selectedValues.includes(value),
                      ),
                    }))}
                  />
                ) : undefined}
              </Form.Item>
            </Col>
          );
        })
      ) : (
        <Col span={24}>
          <Empty
            className='my-20'
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className='text-zinc-300'>
                {mainCategory
                  ? `دسته بندی ${mainCategory.title} فیلد های متغیر ندارد.`
                  : 'ابتدا دسته‌بندی اصلی را انتخاب کنید.'}
              </span>
            }
          />
        </Col>
      )}
    </Row>
  );
};

export { ProductAttributesInfo };
