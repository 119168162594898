import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { CategoryFormContext } from 'context';
import type { Category as CategoryConfig, ComponentMode } from 'types';

import { CategoryForm, CategoryHeader } from './components';

type CategoryProps = {
  mode: ComponentMode;
};

export default function Category({ mode }: CategoryProps) {
  const data = useLoaderData() as CategoryConfig;

  const step = 1;
  const isUpdatedMode = mode === 'Update';
  const title = isUpdatedMode ? data.title : 'افزودن دسته‌بندی‌ جدید';
  const iconStatus = isUpdatedMode ? Boolean(data.iconId) : false;
  const defaultStatus: typeof data.status = isUpdatedMode
    ? data.status
    : 'Active';
  const defaultTitle = isUpdatedMode ? data.title : '';

  return (
    <>
      <Helmet>
        <title>{`${title} | کاشی لند`}</title>
      </Helmet>

      <CategoryHeader>{title}</CategoryHeader>

      <CategoryFormContext
        mode={mode}
        step={step}
        iconStatus={iconStatus}
        defaultStatus={defaultStatus}
        defaultTitle={defaultTitle}
        {...(isUpdatedMode && data)}
      >
        <CategoryForm />
      </CategoryFormContext>
    </>
  );
}
