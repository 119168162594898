import * as React from 'react';

import { useGetState, useUpdateEffect } from 'ahooks';
import {
  Button,
  Dropdown,
  Flex,
  Form,
  Input,
  notification,
  Space,
  Tag,
  Typography,
} from 'antd';

import {
  CopyOutlinedIcon,
  DeleteOutlinedIcon,
  FileOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import { useAttributeAction, useAttributeValue } from 'context';
import type { AttributeConfigCategory } from 'types';
import { randomId } from 'utils';

type AttributeFormCategoryProps = AttributeConfigCategory & {
  copiedText: string | null;
  handleCopyToClipboard: (text: string) => void;
};

const AttributeFormCategory = ({
  id,
  title,
  values: defaultValues = [],
  copiedText,
  handleCopyToClipboard,
}: AttributeFormCategoryProps) => {
  const [values, setValues] = useGetState(defaultValues);
  const [latestValue, setLatestValue] = useGetState<string>();
  const { categories = [] } = useAttributeValue();
  const action = useAttributeAction();

  useUpdateEffect(() => {
    const updatedCategories = categories.map(category => {
      if (category.id === id) {
        return {
          ...category,
          values,
        };
      }
      return category;
    });

    action({
      type: 'UPDATE_ATTRIBUTE_CATEGORIES',
      payload: {
        categories: updatedCategories,
      },
    });
  }, [values]);

  const addToValues = (value: string) => {
    if (values?.includes(value)) {
      notification.warning({
        message: 'مقدار وارد شده وجود دارد.',
      });
    } else {
      setValues(prevValues => [...prevValues, value]);
      setLatestValue('');
    }
  };

  const removeFromValues = (value: string) => {
    setValues(prevValues =>
      prevValues.filter(prevValue => prevValue !== value),
    );
  };

  return (
    <Space
      direction='vertical'
      size='middle'
      className='w-full rounded bg-white px-6 py-8'
    >
      <Flex justify='space-between' align='center'>
        <Typography.Text className='font-semibold'>{title}</Typography.Text>

        <Dropdown
          menu={{
            items: [
              {
                key: 'copy-category-values',
                label: (
                  <Button
                    type='text'
                    size='small'
                    className='flex items-center'
                    style={{ paddingInline: 0 }}
                    onClick={() => {
                      handleCopyToClipboard(values.join(','));
                    }}
                    disabled={values.length === 0}
                  >
                    <CopyOutlinedIcon />
                    <span className='text-sm'>کپی مقادیر</span>
                  </Button>
                ),
              },
              {
                key: 'paste-category-values',
                label: (
                  <Button
                    type='text'
                    size='small'
                    className='flex items-center'
                    style={{ paddingInline: 0 }}
                    onClick={() => {
                      setValues(prevValues => {
                        if (copiedText) {
                          const newValues = copiedText
                            .split(',')
                            .filter(text => !prevValues.includes(text));

                          return [...prevValues, ...newValues];
                        }

                        return prevValues;
                      });
                    }}
                    disabled={!copiedText}
                  >
                    <FileOutlinedIcon />
                    <span className='text-sm'>جای گذاری</span>
                  </Button>
                ),
              },
              {
                key: 'delete-attribute-category',
                label: (
                  <Button
                    danger
                    type='link'
                    size='small'
                    className='flex items-center'
                    style={{ paddingInline: 0 }}
                    onClick={() => {
                      const filteredCategories = categories.filter(
                        category => category.id !== id,
                      );

                      action({
                        type: 'UPDATE_ATTRIBUTE_CATEGORIES',
                        payload: {
                          categories: filteredCategories,
                        },
                      });
                    }}
                  >
                    <DeleteOutlinedIcon />
                    <span className='text-sm'>حذف دسته‌‌بندی</span>
                  </Button>
                ),
              },
            ],
          }}
          trigger={['click']}
          placement='bottomLeft'
        >
          <Button type='text' size='small' className='px-0'>
            <MoreOutlinedIcon style={{ fontSize: 24 }} />
            <span className='sr-only'>آیکون اکشن‌ها</span>
          </Button>
        </Dropdown>
      </Flex>

      <Form.Item label='اضافه کردن مقدار جدید' wrapperCol={{ lg: 12 }}>
        <Flex gap='small'>
          <Input
            placeholder='مقدار جدید را برای این دسته وارد کنید'
            value={latestValue}
            onChange={event => {
              setLatestValue(event.target.value);
            }}
            onPressEnter={event => {
              if (event.key === 'Enter') {
                event.preventDefault();

                if (latestValue) addToValues(latestValue);
              }
            }}
          />
          <Button
            onClick={() => {
              if (latestValue) {
                addToValues(latestValue);
              }
            }}
          >
            اضافه کردن
          </Button>
        </Flex>
      </Form.Item>

      <Space wrap>
        {values?.map(value => {
          const id = randomId();

          return (
            <Tag
              closeIcon
              key={id}
              bordered={false}
              color='blue'
              className='me-0 p-1 text-sm'
              onClose={e => {
                e.preventDefault();
                removeFromValues(value);
              }}
            >
              {value}
            </Tag>
          );
        })}
      </Space>
    </Space>
  );
};

export { AttributeFormCategory };
