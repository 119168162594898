import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { usePurchaseValue } from 'context';
import { updatePurchase } from 'services';
import { PATHNAME } from 'utils';

import { PurchaseFormAction } from './action';
import { PurchaseFormContent } from './content';

const {
  LOCAL: {
    PURCHASES: { BASE_URL: PURCHASES_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<ReturnType<typeof usePurchaseValue>, 'status'>;

const PurchaseForm = () => {
  const [form] = Form.useForm<FieldType>();
  const { id = '', trackingCode, defaultStatus, mode } = usePurchaseValue();
  const navigate = useNavigate();

  const onFinish = useLockFn(async ({ status = defaultStatus! }: FieldType) => {
    const params: Parameters<typeof updatePurchase>[0] = {
      id,
    };
    const body: Parameters<typeof updatePurchase>[1] = {
      status,
    };

    switch (mode) {
      case 'Update': {
        return updatePurchase(params, body)
          .then(() => {
            notification.success({
              message: `سفارش ${trackingCode} با موفقیت ویرایش شد.`,
            });
            navigate(PURCHASES_BASE_URL);
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      default:
        break;
    }
  });

  return (
    <Form
      labelWrap
      form={form}
      layout='vertical'
      autoComplete='off'
      className='my-3'
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col lg:items-start' gap='large'>
        <PurchaseFormContent />
        <PurchaseFormAction />
      </Flex>
    </Form>
  );
};

export { PurchaseForm };
