import * as React from 'react';

import { useRequest } from 'ahooks';
import { Col, Form, Input, Row, TreeSelect } from 'antd';

import { usePackagingModelAction, usePackagingModelValue } from 'context';
import { getCategories } from 'services';
import type { TreeSelectSource } from 'types';
import { generateTreeSelectData, inputRule, normalizeToString } from 'utils';

type FieldType = Pick<
  ReturnType<typeof usePackagingModelValue>,
  'name' | 'label' | 'categories'
>;

const PackagingModelFormContent = () => {
  const { data: categoriesData, loading: categoriesLoading } = useRequest(
    getCategories,
    {
      cacheKey: 'categories',
    },
  );
  const { name, label, categories = [] } = usePackagingModelValue();
  const action = usePackagingModelAction();

  const totalCategories = categoriesData?.categories ?? [];

  const categoriesTreeData = generateTreeSelectData(
    totalCategories,
    'id',
    'title',
    'id',
  );

  return (
    <Row className='rounded bg-white px-6 py-8 lg:basis-[70%]'>
      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='نام'
          name='name'
          rules={[inputRule.required, inputRule.string]}
          initialValue={name}
        >
          <Input placeholder='نام بسته‌بندی را وارد کنید' />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='برچسب'
          name='label'
          rules={[inputRule.required, inputRule.string]}
          initialValue={label}
        >
          <Input placeholder='برچسب بسته‌بندی را وارد کنید' />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType>
          label='دسته‌بندی‌های اختصاص یافته'
          name='categories'
          initialValue={categories.map(({ id, title }) => ({
            key: id,
            label: title,
            value: id,
          }))}
        >
          <TreeSelect<TreeSelectSource[]>
            multiple
            treeCheckable
            treeCheckStrictly
            placeholder='دسته‌بندی‌های خود را انتخاب کنید'
            loading={categoriesLoading}
            treeData={categoriesTreeData}
            filterTreeNode={(inputValue, treeNode) =>
              normalizeToString(treeNode.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={values => {
              action({
                type: 'UPDATE_PACKAGING_MODEL_CATEGORIES',
                payload: {
                  categories: values.map(({ label, value }) => ({
                    id: normalizeToString(value),
                    slug: normalizeToString(label),
                    subSlug: normalizeToString(label),
                    title: normalizeToString(label),
                    status: 'Active',
                    values: [],
                  })),
                },
              });
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { PackagingModelFormContent };
