import * as React from 'react';

import { Badge, Button, Divider, Form, Select, Typography } from 'antd';

import { usePurchaseValue } from 'context';
import type { PurchaseStatus } from 'types';
import { localizePurchaseStatus } from 'utils';

const purchaseStatuses = [
  'Purchased',
  'Posted',
  'Delivered',
] satisfies PurchaseStatus[];

type FieldType = Pick<ReturnType<typeof usePurchaseValue>, 'status'>;

const PurchaseFormAction = () => {
  const { defaultStatus, mode } = usePurchaseValue();

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='mb-0 text-base font-medium'>
          وضعیت
        </Typography.Title>
      </div>

      <Divider className='mb-8 mt-4' />

      <div className='px-6'>
        <Typography.Paragraph>
          <Badge
            text={`وضعیت فعلی: ${localizePurchaseStatus(defaultStatus).label}`}
            color={localizePurchaseStatus(defaultStatus).color}
          />
        </Typography.Paragraph>

        <Form.Item<FieldType> label='وضعیت جدید' name='status' className='mb-2'>
          <Select
            placeholder='نوع وضعیت را انتخاب کنید'
            options={purchaseStatuses.map(type => ({
              label: localizePurchaseStatus(type).label,
              value: type,
            }))}
          />
        </Form.Item>
      </div>

      <div className='p-6'>
        <Form.Item className='mb-0'>
          <Button block type='primary' htmlType='submit'>
            {mode === 'Create' ? 'افزودن سفارش جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export { PurchaseFormAction };
