export {
  AlignCenterOutlined as AlignCenterOutlinedIcon,
  AlignLeftOutlined as AlignLeftOutlinedIcon,
  AlignRightOutlined as AlignRightOutlinedIcon,
  ApartmentOutlined as ApartmentOutlinedIcon,
  AppstoreOutlined as AppstoreOutlinedIcon,
  BellOutlined as BellOutlinedIcon,
  BoldOutlined as BoldOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  CloseCircleOutlined as CloseCircleOutlinedIcon,
  CommentOutlined as CommentOutlinedIcon,
  CopyOutlined as CopyOutlinedIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DeploymentUnitOutlined as DeploymentUnitOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  EyeOutlined as EyeOutlinedIcon,
  FileImageOutlined as FileImageOutlinedIcon,
  FileOutlined as FileOutlinedIcon,
  FileSearchOutlined as FileSearchOutlinedIcon,
  FolderOpenOutlined as FolderOpenOutlinedIcon,
  HighlightOutlined as HighlightOutlinedIcon,
  ItalicOutlined as ItalicOutlinedIcon,
  LinkOutlined as LinkOutlinedIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  MenuFoldOutlined as MenuFoldOutlinedIcon,
  MenuOutlined as MenuOutlinedIcon,
  MenuUnfoldOutlined as MenuUnfoldOutlinedIcon,
  MobileOutlined as MobileOutlinedIcon,
  MoreOutlined as MoreOutlinedIcon,
  NodeIndexOutlined as NodeIndexOutlinedIcon,
  OrderedListOutlined as OrderedListOutlinedIcon,
  PieChartOutlined as PieChartOutlinedIcon,
  PlusOutlined as PlusOutlinedIcon,
  RedoOutlined as RedoOutlinedIcon,
  RightOutlined as RightOutlinedIcon,
  SettingOutlined as SettingOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  ShoppingOutlined as ShoppingOutlinedIcon,
  StrikethroughOutlined as StrikethroughOutlinedIcon,
  TableOutlined as TableOutlinedIcon,
  TagsOutlined as TagsOutlinedIcon,
  TeamOutlined as TeamOutlinedIcon,
  UnderlineOutlined as UnderlineOutlinedIcon,
  UndoOutlined as UndoOutlinedIcon,
  UngroupOutlined as UngroupOutlinedIcon,
  UnorderedListOutlined as UnorderedListOutlinedIcon,
  UploadOutlined as UploadOutlinedIcon,
  UpOutlined as UpOutlinedIcon,
  UserOutlined as UserOutlinedIcon,
  YoutubeOutlined as YoutubeOutlinedIcon,
} from '@ant-design/icons';
