import * as React from 'react';

import {
  Badge,
  Button,
  Dropdown,
  Flex,
  Modal,
  notification,
  Table,
  TreeSelect,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  AntdRouterLink,
  CheckCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import { deleteCategory } from 'services';
import type { CategoryQueryResult } from 'types';
import {
  generateTableDataSource,
  generateTreeSelectData,
  localizeCategoryStatus,
  normalizeToString,
  PATHNAME,
} from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    CATEGORIES: { UPDATE_URL: CATEGORIES_UPDATE_URL },
  },
} = PATHNAME;

type CategoriesTableProps = {
  loading: boolean;
  categories: CategoryQueryResult[];
  handleRefreshData: () => void;
};

const CategoriesTable = React.memo<CategoriesTableProps>(
  ({ loading, categories, handleRefreshData }: CategoriesTableProps) => {
    const [modal, contextHolder] = Modal.useModal();
    const navigate = useNavigate();

    const handleDeleteCategory = async (id: string) => {
      deleteCategory({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const categoriesDataSource = generateTableDataSource(categories, 'id');
    const categoriesTreeData = generateTreeSelectData(
      categories,
      'id',
      'title',
      'id',
    );

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: 900, y: 700 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'title',
            key: 'title',
            width: '30%',
            ellipsis: true,
            filterDropdown: () => (
              <div
                style={{ background: 'var(--ant-table-header-bg)', padding: 8 }}
              >
                <TreeSelect<string>
                  showSearch
                  className='w-80'
                  placeholder='دسته‌بندی مورد نظر را انتخاب کنید'
                  loading={loading}
                  treeData={categoriesTreeData}
                  filterTreeNode={(inputValue, treeNode) =>
                    normalizeToString(treeNode.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    navigate(
                      `${CATEGORIES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${value}${DEFAULT_SLUG_SEPARATOR}`,
                    );
                  }}
                />
              </div>
            ),
            render: (_, { id, title }) => (
              <AntdRouterLink
                to={`${CATEGORIES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
              >
                {title}
              </AntdRouterLink>
            ),
          },
          {
            title: 'پیوند یکتا',
            dataIndex: 'slug',
            key: 'slug',
            ellipsis: true,
            render: (_, { slug }) => <bdi>{slug}</bdi>,
          },
          {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            ellipsis: true,
            render: (_, { status }) => (
              <Flex justify='center'>
                <Badge
                  count={localizeCategoryStatus(status).label}
                  color={localizeCategoryStatus(status).color}
                />
              </Flex>
            ),
          },
          {
            title: 'مرتبط با فیلد',
            dataIndex: 'relatedAttribute',
            key: 'relatedAttribute',
            width: 110,
            ellipsis: true,
            render: (_, { relatedAttribute }) => (
              <Flex justify='center'>
                {relatedAttribute ? (
                  <CheckCircleOutlinedIcon className='text-green-500' />
                ) : (
                  <CloseCircleOutlinedIcon className='text-red-500' />
                )}
              </Flex>
            ),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, title }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-category',
                        label: (
                          <AntdRouterLink
                            to={`${CATEGORIES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>
                              ویرایش دسته‌بندی‌
                            </span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-category',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='flex items-center'
                              style={{ paddingInline: 0, marginBlock: 2 }}
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف دسته‌بندی‌ "${title}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteCategory(id);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span className='text-sm'>حذف دسته‌بندی‌</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='py-3.5'>
                    <MoreOutlinedIcon style={{ fontSize: 20 }} />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={categoriesDataSource}
        pagination={false}
      />
    );
  },
);
CategoriesTable.displayName = 'CategoriesTable';

export { CategoriesTable };
