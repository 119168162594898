import * as React from 'react';

import { Tabs } from 'antd';

import { useBrandAction, useBrandValue } from 'context';

import { BrandBaseInfo } from './base-info';
import { BrandSeoInfo } from './seo-info';

const BrandFormTabs = () => {
  const { step = 1 } = useBrandValue();
  const action = useBrandAction();

  return (
    <Tabs
      destroyInactiveTabPane
      items={[
        {
          key: '1',
          label: 'اطلاعات اولیه',
          children: <BrandBaseInfo />,
        },
        {
          key: '2',
          label: 'تنظیمات سئو',
          children: <BrandSeoInfo />,
          disabled: step < 2,
        },
      ]}
      className='rounded bg-white lg:basis-[70%] [&>div]:px-6 first-of-type:[&>div]:pt-3 last-of-type:[&>div]:py-3'
      activeKey={step.toString()}
      onChange={activeKey => {
        action({
          type: 'UPDATE_BRAND_STEP',
          payload: {
            step: +activeKey as typeof step,
          },
        });
      }}
    />
  );
};

export { BrandFormTabs };
