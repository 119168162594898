import * as React from 'react';

import { useUpdateEffect } from 'ahooks';
import { Badge, Button, Dropdown, Flex, Select, Table } from 'antd';

import {
  AntdRouterLink,
  CheckCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
  EditOutlinedIcon,
  MoreOutlinedIcon,
} from 'components';
import type { PurchaseStatus, PurchaseSummary } from 'types';
import {
  adjustPageNumber,
  dateTimeFormat,
  generateTableDataSource,
  localizePurchaseStatus,
  normalizeToString,
  numberFormat,
  PATHNAME,
  UNIT,
} from 'utils';

const { PRICE } = UNIT;
const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    PURCHASES: { UPDATE_URL: PURCHASES_UPDATE_URL },
  },
} = PATHNAME;

const purchaseStatuses = [
  'Purchased',
  'Posted',
  'Delivered',
] satisfies PurchaseStatus[];

type PurchasesTableProps = {
  loading: boolean;
  total: number;
  purchases: PurchaseSummary[];
  pageSize: number;
  pageNumber: number;
  handleStatuses: (statuses: PurchaseStatus[]) => void;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
};

const PurchasesTable = React.memo<PurchasesTableProps>(
  ({
    loading,
    total,
    purchases,
    pageSize,
    pageNumber,
    handleStatuses,
    handlePageSize,
    handlePageNumber,
  }: PurchasesTableProps) => {
    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const purchasesDataSource = generateTableDataSource(purchases, 'id');

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: 800, y: 630 }}
        columns={[
          {
            title: 'شناسه',
            dataIndex: 'trackingCode',
            key: 'trackingCode',
            width: '30%',
            ellipsis: true,
            render: (_, { id, trackingCode }) => (
              <AntdRouterLink
                ellipsis
                to={`${PURCHASES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
              >
                {trackingCode}
              </AntdRouterLink>
            ),
          },
          {
            title: 'قیمت',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            ellipsis: true,
            render: (_, { totalPrice }) =>
              numberFormat(totalPrice, undefined, ` ${PRICE}`),
          },
          {
            title: 'تاریخ ساخت',
            dataIndex: 'createdDate',
            key: 'createdDate',
            ellipsis: true,
            render: (_, { createdDate }) =>
              dateTimeFormat(new Date(createdDate)),
          },
          {
            title: 'تاریخ آخرین ویرایش',
            dataIndex: 'lastModifiedDate',
            key: 'lastModifiedDate',
            ellipsis: true,
            render: (_, { lastModifiedDate }) =>
              dateTimeFormat(new Date(lastModifiedDate)),
          },
          {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            width: 110,
            ellipsis: true,
            filterDropdown: () => (
              <div className='bg-[var(--ant-table-header-bg)] p-2'>
                <Select<PurchaseStatus[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='وضعیت مورد نظر را انتخاب کنید'
                  options={purchaseStatuses.map(type => ({
                    label: localizePurchaseStatus(type).label,
                    value: type,
                  }))}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleStatuses(value);
                  }}
                />
              </div>
            ),
            render: (_, { status }) => (
              <Flex justify='center'>
                <Badge
                  count={localizePurchaseStatus(status).label}
                  color={localizePurchaseStatus(status).color}
                />
              </Flex>
            ),
          },
          {
            title: 'فاکتور کاغذی',
            dataIndex: 'paperInvoice',
            key: 'paperInvoice',
            width: 120,
            ellipsis: true,
            render: (_, { paperInvoice }) => (
              <Flex justify='center'>
                {paperInvoice ? (
                  <CheckCircleOutlinedIcon className='text-green-500' />
                ) : (
                  <CloseCircleOutlinedIcon className='text-red-500' />
                )}
              </Flex>
            ),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-purchase',
                        label: (
                          <AntdRouterLink
                            to={`${PURCHASES_UPDATE_URL}${DEFAULT_SLUG_SEPARATOR}${id}${DEFAULT_SLUG_SEPARATOR}`}
                            className='inline-block py-1 text-inherit'
                          >
                            <EditOutlinedIcon />
                            <span className='ms-2 text-sm'>ویرایش سفارش</span>
                          </AntdRouterLink>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='pb-[18px] pt-3.5'>
                    <MoreOutlinedIcon className='text-xl' />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={purchasesDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
PurchasesTable.displayName = 'PurchasesTable';

export { PurchasesTable };
