import * as React from 'react';

import { useLockFn, useMemoizedFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useCategoryAction, useCategoryValue } from 'context';
import { createCategory, updateCategory } from 'services';
import { replaceExtraSeparator } from 'utils';
import { PATHNAME } from 'utils';

import { CategoryFormAction } from './action';
import { CategoryFormTabs } from './tabs';

const {
  LOCAL: {
    CATEGORIES: { BASE_URL: CATEGORIES_BASE_URL },
  },
} = PATHNAME;

type FieldType = Pick<ReturnType<typeof useCategoryValue>, 'status'> &
  Partial<Record<'seoTitle' | 'seoDescription', string>>;

const CategoryForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    title = '',
    parent,
    relatedAttribute,
    subSlug,
    iconId,
    description,
    defaultTitle,
    defaultStatus,
    mode,
    step,
  } = useCategoryValue();
  const action = useCategoryAction();
  const navigate = useNavigate();

  const formatedSubSlug = replaceExtraSeparator(subSlug ?? title);

  const handleUpdateCategoryBaseInfo = useMemoizedFn(() => {
    action({
      type: 'UPDATE_CATEGORY_STEP',
      payload: {
        step: 2,
      },
    });
  });

  const handleUpdateCategorySeoInfo = useLockFn(
    async ({
      seoTitle = '',
      seoDescription = '',
      status = defaultStatus!,
    }: FieldType) => {
      const params: Parameters<typeof updateCategory>[0] = {
        id,
      };
      const body: Parameters<typeof updateCategory>[1] = {
        title,
        iconId,
        parentId: parent?.id,
        relatedAttribute: relatedAttribute && {
          id: relatedAttribute.id,
          selectedValues: relatedAttribute.selectedValues,
        },
        subSlug: formatedSubSlug,
        description,
        seo: {
          title: seoTitle,
          description: seoDescription,
        },
        status,
      };

      switch (mode) {
        case 'Create': {
          return createCategory(body)
            .then(() => {
              notification.success({
                message: `دسته‌بندی‌ ${title} با موفقیت ساخته شد.`,
              });
              navigate(CATEGORIES_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        case 'Update': {
          return updateCategory(params, body)
            .then(() => {
              notification.success({
                message: `دسته‌بندی‌ ${defaultTitle} با موفقیت ویرایش شد.`,
              });
              navigate(CATEGORIES_BASE_URL);
            })
            .catch(message => {
              notification.error({ message });
            });
        }
        default:
          break;
      }
    },
  );

  const onFinish = useLockFn(
    async ({ seoTitle, seoDescription, status }: FieldType) => {
      switch (step) {
        case 1: {
          return handleUpdateCategoryBaseInfo();
        }
        case 2: {
          return handleUpdateCategorySeoInfo({
            seoTitle,
            seoDescription,
            status,
          });
        }
        default:
          break;
      }
    },
  );

  return (
    <Form
      labelWrap
      form={form}
      layout='vertical'
      autoComplete='off'
      className='my-3'
      fields={[
        {
          name: 'title',
          value: title,
        },
        {
          name: 'parent',
          value: parent && {
            label: parent.title,
            value: parent.id,
          },
        },
        {
          name: 'relatedAttribute',
          value: parent &&
            relatedAttribute && {
              label: relatedAttribute.name,
              value: relatedAttribute.id,
            },
        },
        {
          name: 'relatedAttributeValue',
          value:
            parent && relatedAttribute && relatedAttribute.selectedValues.at(0),
        },
        {
          name: 'subSlug',
          value: formatedSubSlug,
        },
      ]}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col xl:items-start' gap='large'>
        <CategoryFormTabs />
        <CategoryFormAction />
      </Flex>
    </Form>
  );
};

export { CategoryForm };
