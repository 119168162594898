import * as React from 'react';

import { UserActionContext, UserValueContext } from './context';
import { useUserAction, useUserValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const UserFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <UserValueContext.Provider value={state}>
      <UserActionContext.Provider value={dispatch}>
        {children}
      </UserActionContext.Provider>
    </UserValueContext.Provider>
  );
};

export { UserFormContext, useUserAction, useUserValue };
